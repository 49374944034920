import React from 'react';

import PriceCell from '@/components/PriceCell';
import { updatePricingChannelVariantMutation } from '@/queries/pricing.queries';

type Props = {
  id: number;
  price?: string;
};

const PricingProductVariantPrice: React.FC<Props> = ({ id, price = '0' }) => {
  const updatePricingChannelVariantMutator = updatePricingChannelVariantMutation();
  const onChangePrice = (newPrice: number) => {
    updatePricingChannelVariantMutator.mutate({
      id,
      data: {
        price: String(newPrice),
      },
    });
  };

  return (
    <PriceCell
      value={Number(price)}
      onChange={onChangePrice}
      error={!!updatePricingChannelVariantMutator.error}
    />
  );
};

export default PricingProductVariantPrice;
