import { Form, Input, message, Modal, Radio } from 'antd';
import React from 'react';

import useT from '@/hooks/useT';
import { createAttributeProducerMutation } from '@/queries/attributes.queries';
import { Producer } from '@/types/Api';
import { TyreClass } from '@/types/Tyre.types';
import { formatError } from '@/utils/error.utils';

type Props = {
  visible?: boolean;
  setVisible: (visible: boolean) => void;
};

const AddProducerModal: React.FC<Props> = ({ visible = false, setVisible }) => {
  const [form] = Form.useForm();
  const t = useT();
  const createAttributeProducerMutator = createAttributeProducerMutation();

  const saveProducer = async (values: Producer) => {
    try {
      await createAttributeProducerMutator.mutateAsync(values);
      message.success(t('addProducerModal.successMessage'));
      setVisible(false);
    } catch (err) {
      message.error(formatError(err));
    }
  };

  const handleOk = async () => {
    const values = await form.validateFields();
    saveProducer(values);
  };

  const handleCancel = () => setVisible(false);

  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      confirmLoading={createAttributeProducerMutator.isLoading}
      onCancel={handleCancel}
      okText={t('button.save')}
      cancelText={t('button.cancel')}
    >
      <Form form={form} layout="vertical" name="basic" autoComplete="off">
        <Form.Item
          label={t('addProducerModal.nameLabel')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('addProducerModal.tyreClassLabel')}
          name="product_type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={TyreClass.premium}>{TyreClass.premium}</Radio.Button>
            <Radio.Button value={TyreClass.middle}>{TyreClass.middle}</Radio.Button>
            <Radio.Button value={TyreClass.economic}>{TyreClass.economic}</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddProducerModal;
