import React, { useEffect } from 'react';

import CustomTable from '@/components/CustomTable';
import useTable from '@/hooks/useTable';
import { getTermsQuery } from '@/queries/terms.queries';

import useTermsInvoicesColumns from './useTermsInvoicesColumns';

type Props = {
  source?: string;
  termId?: number;
  setTermId: (termId: number | undefined) => void;
};

const TermsInvoicesTable: React.FC<Props> = ({ source, termId, setTermId }) => {
  const columns = useTermsInvoicesColumns({
    termId,
    setTermId,
  });
  const { setQuery, params, pagination } = useTable({
    source,
  });

  const { data, isLoading } = getTermsQuery(params);

  useEffect(() => {
    setQuery({ source });
  }, [setQuery, source]);

  return (
    <CustomTable columns={columns} data={data} isLoading={isLoading} pagination={pagination} />
  );
};

export default TermsInvoicesTable;
