import { Col, Radio, Row, Select, Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import AllegroVariantsTable from '@/components/AllegroVariantsTable/AllegroVariantsTable';
import EshopTable from '@/components/EshopTable/EshopTable';
import ProducersSizesTable from '@/components/ProducersSizesTable/ProducersSizesTable';
import useT from '@/hooks/useT';
import { getProducersQuery } from '@/queries/attributes.queries';
import { getTyreClassesQuery } from '@/queries/attributesTyre.queries';
import {
  getWarehouseOrderProducersSummaryQuery,
  getWarehouseOrderSizeSummaryQuery,
  getWarehouseOrdersQuery,
} from '@/queries/warehouse.queries';
import { TyreSeason } from '@/types/Tyre.types';

import PricingOrdersProductsBySizeTable from './PricingOrdersProductsBySizeTable/PricingOrdersProductsBySizeTable';

const PricingOrders: React.FC = () => {
  const { search } = useLocation();
  const urlParams = React.useMemo(() => new URLSearchParams(search), [search]);
  const t = useT();

  const [producer, setProducer] = useState<string | null>(urlParams.get('producer') || null);
  const [warehouseOrderId, setWarehouseOrderId] = useState<number | null>(null);
  const [allegroProducer, setAllegroProducer] = useState<string | null>(null);
  const [size, setSize] = useState<string | null>(urlParams.get('size') || null);
  const [selectedTyreClasses, setSelectedTyreClasses] = useState<string[]>([]);
  const [summaryProducers, setSummaryProducers] = useState<string[]>([]);
  const [productProducers, setProductProducers] = useState<string[]>([]);
  const [season, setSeason] = useState<TyreSeason>(
    (urlParams.get('season') as TyreSeason) || TyreSeason.summer,
  );

  const tyreClasses = getTyreClassesQuery();
  const producers = getProducersQuery();

  const producersSummary = getWarehouseOrderProducersSummaryQuery(warehouseOrderId || 0, {
    season,
  });

  const warehouseOrders = getWarehouseOrdersQuery();
  const warehouseOrdersData = warehouseOrders.data || [];

  const sizesSummary = getWarehouseOrderSizeSummaryQuery(warehouseOrderId || 0, {
    producer: producer as string,
    season,
  });

  const producersValuesData = producersSummary?.data || [];
  const sizesData = useMemo(() => sizesSummary.data || [], [sizesSummary.data]);

  const changeProducer = (newProducer: string | null) => {
    setProducer(newProducer);
  };

  const changeSize = (newSize: string | null) => {
    setSize(newSize);
  };

  const changeSeason = (newSeason: TyreSeason) => {
    setSeason(newSeason);
  };

  useEffect(() => setAllegroProducer(producer), [producer]);

  useEffect(() => {
    if (sizesData.length && !sizesData.find((sizeValue) => sizeValue.size === size)) {
      setSize(null);
      setProducer(null);
    }
  }, [sizesData, size]);

  return (
    <>
      <Helmet>
        <title>{t('title.pricing.orders')}</title>
      </Helmet>
      <Space>
        <Select
          showSearch
          value={warehouseOrderId}
          onChange={setWarehouseOrderId}
          placeholder={'Zamówienia'}
          style={{ width: 200 }}
          optionFilterProp="name"
          filterOption={(input, option) =>
            option?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {warehouseOrdersData.map((option) => (
            <Select.Option key={option.id} value={option.id} name={option.name}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
        <Select
          showSearch
          value={size}
          onChange={setSize}
          placeholder={'Rozmiar'}
          style={{ width: 200 }}
          optionFilterProp="name"
          filterOption={(input, option) =>
            option?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {sizesData.map((sizesValue) => (
            <Select.Option key={sizesValue.size} value={sizesValue.size} name={sizesValue.size}>
              {sizesValue.size}
            </Select.Option>
          ))}
        </Select>
        <Select
          showSearch
          value={producer}
          onChange={changeProducer}
          style={{ width: 200 }}
          placeholder={'Producent'}
          optionFilterProp="name"
          disabled={!size}
          filterOption={(input, option) =>
            option?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {producersValuesData.map((producersValue) => (
            <Select.Option
              key={producersValue.producer}
              value={producersValue.producer}
              name={producersValue.producer}
            >
              {producersValue.producer}
            </Select.Option>
          ))}
        </Select>

        <Radio.Group onChange={(e) => changeSeason(e.target.value)} value={season}>
          <Radio value={TyreSeason.summer}>
            <FormattedMessage id="tyreSeason.summer" />
          </Radio>
          <Radio value={TyreSeason.winter}>
            <FormattedMessage id="tyreSeason.winter" />
          </Radio>
          <Radio value={TyreSeason.allSeason}>
            <FormattedMessage id="tyreSeason.allSeason" />
          </Radio>
        </Radio.Group>

        <Select
          mode="multiple"
          allowClear
          showSearch
          value={selectedTyreClasses}
          onChange={setSelectedTyreClasses}
          style={{ width: 400 }}
          placeholder={'Klasa'}
          optionFilterProp="name"
        >
          {tyreClasses.data?.map((tyreClass) => (
            <Select.Option key={tyreClass.id} value={tyreClass.name} name={tyreClass.name}>
              {tyreClass.name}
            </Select.Option>
          ))}
        </Select>

        <Select
          mode="multiple"
          allowClear
          showSearch
          value={summaryProducers}
          onChange={setSummaryProducers}
          style={{ width: 400 }}
          placeholder={'producers-summary'}
          optionFilterProp="name"
        >
          {producers.data?.map((item) => (
            <Select.Option key={item.id} value={item.name} name={item.name}>
              {item.name}
            </Select.Option>
          ))}
        </Select>

        <Select
          mode="multiple"
          allowClear
          showSearch
          value={productProducers}
          onChange={setProductProducers}
          style={{ width: 400 }}
          placeholder={'product-producers'}
          optionFilterProp="name"
        >
          {producers.data?.map((item) => (
            <Select.Option key={item.id} value={item.name} name={item.name}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Space>
      <Row>
        <Col span={3}>
          {warehouseOrderId && (
            <ProducersSizesTable
              firstLevel="size"
              warehouseOrderId={warehouseOrderId}
              season={season}
              producer={producer}
              summaryProducers={summaryProducers}
              size={size}
              changeProducer={changeProducer}
              changeSize={changeSize}
            />
          )}
        </Col>

        <Col span={12}>
          {size && warehouseOrderId && (
            <PricingOrdersProductsBySizeTable
              orderId={warehouseOrderId}
              season={season}
              producer={producer}
              producers={productProducers}
              size={size}
              tyreClasses={selectedTyreClasses}
              onClickName={(p: string) => setAllegroProducer(p)}
            />
          )}
        </Col>

        <Col span={9}>
          {season && allegroProducer && size && (
            <>
              <span>{allegroProducer}</span>
              <EshopTable season={season} producer={allegroProducer} size={size} />
              <AllegroVariantsTable season={season} producer={allegroProducer} size={size} />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
export default PricingOrders;
