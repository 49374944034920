import { useIntl } from 'react-intl';

type ReturnFunction = (id: string, values?: Record<string, string>) => string;

const useT = (): ReturnFunction => {
  const intl = useIntl();
  const t = (id: string, values = {}) => intl.formatMessage({ id }, values);
  return t;
};

export default useT;
