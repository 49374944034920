import { LinkOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import fontColorContrast from 'font-color-contrast';

import CopyCell from '@/components/CopyCell';
import { SupplierOrdering } from '@/types/Api';
import { getAnalysisPageUrl } from '@/utils/navigation.utils';

import Comment from './Comment';
import Difference from './Difference';
import OrderedQuantity from './OrderedQuantity';
interface Props {
  orderId: number;
  onClickName: (producer: string) => void;
}

const getStyles = (background: string | undefined) => {
  return {
    background,
    color: background ? fontColorContrast(background) : undefined,
  };
};

const usePricingOrdersProductsBySizeColumns = (props: Props) => {
  return [
    {
      title: <LinkOutlined />,
      dataIndex: 'link',
      key: 'link',
      render: (tread: string, row: SupplierOrdering) => {
        const { variant_attributes: vAttr, product_attributes: pAttr } = row;
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: (
            <a
              href={getAnalysisPageUrl({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                season: pAttr?.season || vAttr?.season,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                producer: pAttr?.producer || vAttr?.producer,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                size: pAttr?.size || vAttr?.size,
              })}
              target="_blank"
            >
              <LinkOutlined />
            </a>
          ),
        };
      },
    },
    {
      title: 'EAN',
      dataIndex: 'ean',
      key: 'ean',
      render: (ean: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: <CopyCell text={ean} maxLength={100} />,
        };
      },
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: (code: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: <CopyCell text={code} maxLength={13} />,
        };
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 180,
      render: (name: string, row: SupplierOrdering) => {
        const { tread, producer } = row.product_attributes || {};
        const text = tread && producer ? [producer, tread].join(' ') : name;
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: (
            <Tooltip title={text}>
              <span className="dots" style={{ width: 180 }}>
                {producer ? (
                  <a onClick={() => props.onClickName(producer)} style={{ color: 'inherit' }}>
                    {text}
                  </a>
                ) : (
                  text
                )}
              </span>
            </Tooltip>
          ),
        };
      },
    },
    {
      title: 'LI',
      dataIndex: ['product_attributes', 'load_index'],
      key: 'load_index',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'SI',
      dataIndex: ['product_attributes', 'speed_index'],
      key: 'speed_index',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'L1',
      dataIndex: ['product_attributes', 'label_fuel'],
      key: 'label_fuel',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'L2',
      dataIndex: ['product_attributes', 'label_break'],
      key: 'label_break',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'L3',
      dataIndex: ['product_attributes', 'label_sound_decibels'],
      key: 'label_sound_decibels',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value?.split(' ').join(''),
        };
      },
    },
    {
      title: 'L4',
      dataIndex: ['product_attributes', 'label_sound_level'],
      key: 'label_sound_level',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'XL',
      dataIndex: ['product_attributes', 'xl'],
      key: 'xl',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'FR',
      dataIndex: ['product_attributes', 'fr'],
      key: 'fr',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'RF',
      dataIndex: ['product_attributes', 'run_flat'],
      key: 'run_flat',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'H',
      dataIndex: ['product_attributes', 'homologation'],
      key: 'homologation',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'SS',
      dataIndex: ['product_attributes', 'selfseal_technology'],
      key: 'selfseal_technology',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'NCS',
      dataIndex: ['product_attributes', 'noice_cancelling_system'],
      key: 'noice_cancelling_system',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'Time',
      dataIndex: ['product_attributes', 'delivery_time'],
      key: 'delivery_time',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'stan_n',
      dataIndex: 'noweopony_quantity',
      key: 'noweopony_quantity',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'stan_p',
      dataIndex: 'producer_quantity',
      key: 'producer_quantity',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'hist',
      dataIndex: 'history',
      key: 'history',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'pre',
      dataIndex: 'preorder',
      key: 'preorder',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'diff',
      dataIndex: 'difference',
      key: 'difference',
      render: (value: string, row: SupplierOrdering) => {
        return <Difference value={row.difference} />;
      },
    },
    {
      title: 'zam',
      dataIndex: 'ordered_quantity',
      key: 'ordered_quantity',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: (
            <OrderedQuantity
              ean={row.ean}
              orderId={props.orderId}
              quantity={row.ordered_quantity}
            />
          ),
        };
      },
    },
    {
      title: 'curr',
      dataIndex: 'current',
      key: 'current',
      sorter: (a: SupplierOrdering, b: SupplierOrdering) =>
        (a.current ? Number(a.current) : 0) - (b.current ? Number(b.current) : 0),
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value || '-------',
        };
      },
    },
    {
      title: 'all',
      dataIndex: 'sales_all',
      key: 'sales_all',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: '1_7',
      dataIndex: 'sales_sty_lip',
      key: 'sales_sty_lip',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: '2_8',
      dataIndex: 'sales_lut_sie',
      key: 'sales_lut_sie',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: '3_9',
      dataIndex: 'sales_mar_wrz',
      key: 'sales_mar_wrz',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: '4_10',
      dataIndex: 'sales_kwi_paz',
      key: 'sales_kwi_paz',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: '5_11',
      dataIndex: 'sales_maj_lis',
      key: 'sales_maj_lis',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: '6_12',
      dataIndex: 'sales_cze_gru',
      key: 'sales_cze_gru',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'comment',
      dataIndex: 'comment',
      key: 'comment',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: <Comment ean={row.ean} orderId={props.orderId} comment={row.comment || ''} />,
        };
      },
    },
    {
      title: 'from',
      dataIndex: 'production_from',
      key: 'production_from',
      render: (value: string, row: SupplierOrdering) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
  ];
};

export default usePricingOrdersProductsBySizeColumns;
