import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import Login from '@/screens/Login';
const AuthNavigation: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Login />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};

export default AuthNavigation;
