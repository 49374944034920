import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ApiInterface, PatchedCreateSupplierOrdering } from '@/types/Api';
import { QueryKeys } from '@/types/QueryKeys.types';
import { API } from '@/utils/request.utils';

export function getWarehouseOrdersQuery() {
  return useQuery([QueryKeys.warehouseOrders], () => API.api.warehouseOrdersList(), {
    select: (data) => data.data,
    keepPreviousData: true,
  });
}

export function getWarehouseOrderSizeSummaryQuery(
  orderId: number,
  query: ApiInterface.WarehouseOrdersSizeSummaryList.RequestQuery,
  enabled = true,
) {
  return useQuery(
    [QueryKeys.warehouseOrderSizes, orderId, query],
    () => API.api.warehouseOrdersSizeSummaryList(orderId, query),
    {
      select: (data) => data.data,
      keepPreviousData: true,
      enabled,
    },
  );
}

export function getWarehouseOrderProducersSummaryQuery(
  orderId: number,
  query: ApiInterface.WarehouseOrdersProducerSummaryList.RequestQuery,
  enabled = true,
) {
  return useQuery(
    [QueryKeys.warehouseOrderProducers, orderId, query],
    () => API.api.warehouseOrdersProducerSummaryList(orderId, query),
    {
      select: (data) => data.data,
      keepPreviousData: true,
      enabled,
      retry: 0,
    },
  );
}

export function getWarehouseOrdersProductsQuery(
  orderId: number,
  query: ApiInterface.WarehouseOrdersProductsList.RequestQuery,
) {
  return useQuery(
    [QueryKeys.warehouseOrderProductsBySize, query],
    () => API.api.warehouseOrdersProductsList(orderId, query),
    {
      select: (data) => data.data,
      enabled: Boolean(query.season && query.size),
      keepPreviousData: true,
    },
  );
}

interface UpdateData {
  ean: string;
  orderId: number;
  data: PatchedCreateSupplierOrdering;
}

export const updateWarehouseOrderProductMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ ean, orderId, data }: UpdateData) =>
      API.api.warehouseOrdersProductsPartialUpdate(ean, orderId, data),
    {
      onSuccess: (data, variables: UpdateData) => {
        queryClient.invalidateQueries([QueryKeys.warehouseOrderProductsBySize]);
        if (variables.data.quantity !== undefined) {
          queryClient.invalidateQueries([QueryKeys.warehouseOrderSizes, variables.orderId]);
          queryClient.invalidateQueries([QueryKeys.warehouseOrderProducers, variables.orderId]);
        }
      },
    },
  );
};
