export const getAvailabilityLevel = (availability: number | undefined) => {
  if (availability === undefined) {
    return;
  }
  if (availability < 4) {
    return 1;
  }
  if (availability < 8) {
    return 2;
  }
  return;
};
