import { Select, Input } from 'antd';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { useDebounce } from 'use-debounce/lib';

import useT from '@/hooks/useT';

import style from './OrdersSales.style.module.less';
import OrdersSalesTable from './OrdersSalesTable';

enum FilterValues {
  checked = 'checked',
  unchecked = 'unchecked',
  watched = 'watched',
}

const OrdersSales: React.FC = () => {
  const t = useT();
  const [checkedValue, setCheckedValue] = useState(FilterValues.unchecked);

  const [name, setName] = useState('');
  const [debouncedName] = useDebounce(name, 300);
  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const filters = {
    is_watched: checkedValue === FilterValues.watched || undefined,
    is_checked:
      checkedValue === FilterValues.watched ? undefined : checkedValue === FilterValues.checked,
  };

  return (
    <>
      <Helmet>
        <title>{t('title.pricing.currentProfit')}</title>
      </Helmet>
      <div className={style.header}>
        <h1 className={style.header__title}>
          <FormattedMessage id="ordersSales.title" />
        </h1>
        <div className={style.header__search}>
          <Input
            allowClear
            placeholder={t('ordersSales.searchPlaceholder')}
            onChange={onChangeName}
            value={name}
          />
        </div>
        <div className={style.header__select}>
          <Select
            value={checkedValue}
            onChange={setCheckedValue}
            className={style.header__selectContent}
          >
            <Select.Option value={FilterValues.unchecked}>
              <FormattedMessage id="ordersSales.filterBy.unchecked" />
            </Select.Option>
            <Select.Option value={FilterValues.checked}>
              <FormattedMessage id="ordersSales.filterBy.checked" />
            </Select.Option>
            <Select.Option value={FilterValues.watched}>
              <FormattedMessage id="ordersSales.filterBy.watched" />
            </Select.Option>
          </Select>
        </div>
      </div>
      <OrdersSalesTable filters={filters} name={debouncedName} />
    </>
  );
};
export default OrdersSales;
