import { Row, Spin } from 'antd';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { getMeQuery } from '@/queries/auth.queries';

import AppNavigation from './app.navigation';
import AuthNavigation from './auth.navigation';

const RootNavigation: React.FC = () => {
  const me = getMeQuery();
  const isLogged = !!me.data && !me.error;
  if (me.isLoading) {
    return (
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Spin size="large" />
      </Row>
    );
  }
  return (
    <BrowserRouter>
      {!isLogged && <AuthNavigation />}
      {isLogged && <AppNavigation />}
    </BrowserRouter>
  );
};

export default RootNavigation;
