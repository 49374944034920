import { Button, Input, message, Space, Tooltip } from 'antd';
import { FormattedDate, FormattedMessage } from 'react-intl';

import useT from '@/hooks/useT';
import {
  deleteAttributeMapMutation,
  hardDeleteAttributeMapMutation,
} from '@/queries/attributesMap.queries';
import { AttributeMap } from '@/types/Api';
import { AttributeMappingTableType } from '@/types/Mapping.types';
import { formatError } from '@/utils/error.utils';

import { AttributeMapFilters } from './AttributesMapTable';

export const productsTableColumns = [];

const useAttributesMapColumns = ({
  tableType,
  openMapModal,
  openMapToEmptyModal,
  filters,
  setFilters,
}: {
  tableType: AttributeMappingTableType;
  openMapModal: (v: AttributeMap) => void;
  openMapToEmptyModal: (v: AttributeMap) => void;
  filters: AttributeMapFilters;
  setFilters: React.Dispatch<React.SetStateAction<AttributeMapFilters>>;
}) => {
  const t = useT();
  const deleteMutation = deleteAttributeMapMutation();
  const hardDeleteMutation = hardDeleteAttributeMapMutation();

  const deleteAttributeMap = async (id: number) => {
    try {
      await deleteMutation.mutateAsync(id);
      message.success(t('attributesMapping.deleteSuccess'));
    } catch (err) {
      message.error(formatError(err));
    }
  };

  const hardDeleteAttributeMap = async (id: number) => {
    try {
      await hardDeleteMutation.mutateAsync(id);
      message.success(t('attributesMapping.deleteSuccess'));
    } catch (err) {
      message.error(formatError(err));
    }
  };

  const renderActions = (record: AttributeMap) => {
    if (tableType === AttributeMappingTableType.TO_MAP) {
      return (
        <Space size="middle">
          <Button size="small" type="primary" onClick={() => openMapToEmptyModal(record)}>
            <FormattedMessage id="button.mapAsEmpty"></FormattedMessage>
          </Button>
          <Button size="small" type="primary" onClick={() => openMapModal(record)}>
            <FormattedMessage id="button.map" />
          </Button>
        </Space>
      );
    }
    if (tableType === AttributeMappingTableType.MAPPED) {
      return (
        <Space size="middle">
          <Button size="small" type="primary" onClick={() => openMapModal(record)}>
            <FormattedMessage id="button.map" />
          </Button>
          <Button size="small" onClick={() => hardDeleteAttributeMap(record.id as number)}>
            <FormattedMessage id="button.deleteFromDb"></FormattedMessage>
          </Button>
        </Space>
      );
    }
    return (
      <Button size="small" onClick={() => hardDeleteAttributeMap(record.id as number)}>
        <FormattedMessage id="button.deleteFromDb"></FormattedMessage>
      </Button>
    );
  };

  return [
    ...(tableType === AttributeMappingTableType.TO_MAP
      ? [
          {
            title: t('attributesMapTable.softDeleteButton'),
            key: 'softDelete',
            width: '10%',
            render: (text: string, record: AttributeMap) => (
              <Button size="small" onClick={() => deleteAttributeMap(record.id as number)}>
                <FormattedMessage id="button.softDelete"></FormattedMessage>
              </Button>
            ),
          },
        ]
      : []),
    {
      title: () => (
        <Space>
          <span>{t('attributesMapTable.sourceLabel')}</span>
          <Input
            value={filters.source}
            onChange={(event) =>
              setFilters((f) => ({
                ...f,
                source: event.target.value,
              }))
            }
          />
        </Space>
      ),
      dataIndex: 'source',
      key: 'source',
      width: '15%',
    },
    {
      title: t('attributesMapTable.descriptionLabel'),
      dataIndex: 'description',
      key: 'description',
      render: (text: string) => (
        <Tooltip placement="bottom" color="orange" title={text}>
          <div className="cut-text">{text}</div>
        </Tooltip>
      ),
    },
    {
      title: () => (
        <Space>
          <span>{t('attributesMapTable.attributeNameLabel')}</span>
          <Input
            value={filters.attribute}
            onChange={(event) =>
              setFilters((f) => ({
                ...f,
                attribute: event.target.value,
              }))
            }
          />
        </Space>
      ),
      dataIndex: ['attribute', 'name'],
      key: 'attributeName',
      width: '20%',
    },
    {
      title: () => (
        <Space>
          <span>{t('attributesMapTable.nameLabel')}</span>
          <Input
            value={filters.name}
            onChange={(event) =>
              setFilters((f) => ({
                ...f,
                name: event.target.value,
              }))
            }
          />
        </Space>
      ),
      dataIndex: 'name',
      key: 'name',
      width: '20%',
    },
    {
      title: () => (
        <Space>
          <span>{t('attributesMapTable.attributeValueLabel')}</span>
          <Input
            value={filters.attribute_value}
            onChange={(event) =>
              setFilters((f) => ({
                ...f,
                attribute_value: event.target.value,
              }))
            }
          />
        </Space>
      ),
      dataIndex: 'attribute_value',
      key: 'attributeValue',
      width: '15%',
    },
    {
      title: t('attributesMapTable.updatedAtLabel'),
      dataIndex: 'updated_at',
      key: 'updatedAt',
      width: '5%',
      render: (text: string) => <FormattedDate value={text} />,
    },
    {
      title: t('actions'),
      key: 'actions',
      width: '15%',
      render: (text: string, record: AttributeMap) => renderActions(record),
    },
  ];
};

export default useAttributesMapColumns;
