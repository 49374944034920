import CheckableTag from 'antd/lib/tag/CheckableTag';
import React from 'react';

import { getSourcesQuery } from '@/queries/core.queries';

type Props = {
  value?: string;
  onChange: (value: string | undefined) => void;
};

const SelectSource: React.FC<Props> = ({ onChange, value }) => {
  const { sources } = getSourcesQuery();

  // TODO [Backend] id should be required
  const onChangeWrapper = (active: boolean, name: string) => {
    if (value === name) {
      onChange(undefined);
    } else {
      onChange(name);
    }
  };
  return (
    <>
      {sources.map((source) => (
        <CheckableTag
          key={source.name}
          checked={value === source.name}
          onChange={(active) => onChangeWrapper(active, source.name)}
        >
          {source.name}
        </CheckableTag>
      ))}
    </>
  );
};
export default SelectSource;
