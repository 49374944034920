import cx from 'classnames';
import React from 'react';

import CustomTable from '@/components/CustomTable';
import useTable from '@/hooks/useTable';
import { getPricingAllegroVariantsQuery } from '@/queries/pricing.queries';
import { AllegroVariant } from '@/types/Api';

import styles from './AllegroVariantsTable.style.module.less';
import useAllegroVariantsColumns from './useAllegroVariantsColumns';
type Props = {
  season: string;
  size: string;
  producer: string;
};

const getRowClassName = (record: AllegroVariant) => {
  return cx({
    [styles.promoted]: record.allegro_promoted === '1',
    [styles.intyre]: record.seller === 'INTYRE_PL',
  });
};
const AllegroVariantsTable: React.FC<Props> = ({ season, producer, size }) => {
  const allegroVariantsColumns = useAllegroVariantsColumns();
  const { pagination, params } = useTable({
    limit: 150,
  });
  const allegroVariants = getPricingAllegroVariantsQuery({ season, producer, size, ...params });

  return (
    <CustomTable
      small
      data={allegroVariants.data}
      columns={allegroVariantsColumns}
      isLoading={allegroVariants.isLoading || allegroVariants.isFetching}
      scroll={{ y: window.innerHeight - 200 }}
      pagination={pagination}
      tableLayout="auto"
      rowClassName={getRowClassName}
    />
  );
};
export default AllegroVariantsTable;
