import React, { useEffect } from 'react';

import CustomTable from '../CustomTable';
import useTable from '@/hooks/useTable';
import { getTyreSizesQuery } from '@/queries/attributesTyre.query';

import useTyreSizesColumns from './useTyreSizesColumns';

type Props = {
  searchQuery?: string;
};

const SizesTable: React.FC<Props> = ({ searchQuery = '' }) => {
  const columns = useTyreSizesColumns();
  const { params, setQuery, pagination } = useTable({
    size: '',
    limit: 20,
  });
  const { data, isLoading } = getTyreSizesQuery(params);

  useEffect(() => {
    setQuery({ size: searchQuery });
  }, [searchQuery, setQuery]);
  return (
    <CustomTable columns={columns} data={data} isLoading={isLoading} pagination={pagination} />
  );
};

export default SizesTable;
