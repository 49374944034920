import React from 'react';

import { updateWarehouseOrderProductMutation } from '@/queries/warehouse.queries';

import OrderedQuantityCell from './OrderedQuantityCell';

type Props = {
  ean: string;
  orderId: number;
  quantity?: number;
};

const OrderedQuantity: React.FC<Props> = ({ ean, orderId, quantity }) => {
  const updateWarehouseOrderProductMutator = updateWarehouseOrderProductMutation();
  const onChangeQuantity = (newQuantity?: number) => {
    updateWarehouseOrderProductMutator.mutate({
      ean,
      orderId,
      data: {
        quantity: newQuantity,
      },
    });
  };

  return (
    <OrderedQuantityCell
      value={quantity}
      onChange={onChangeQuantity}
      error={!!updateWarehouseOrderProductMutator.error}
    />
  );
};

export default OrderedQuantity;
