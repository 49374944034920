import { useQuery } from 'react-query';

import { API } from '../utils/request.utils';
import { ApiInterface } from '@/types/Api';
import { QueryKeys } from '@/types/QueryKeys.types';

export function getTermsQuery(params: ApiInterface.TermsList.RequestQuery = {}) {
  return useQuery([QueryKeys.terms, params], () => API.api.termsList(params), {
    select: (data) => data.data,
  });
}
