import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ApiInterface, PatchedAttributeMap } from '@/types/Api';
import { QueryKeys } from '@/types/QueryKeys.types';
import { API } from '@/utils/request.utils';

export const getAttributesMapQuery = (query: ApiInterface.AttributesMapList.RequestQuery = {}) => {
  return useQuery([QueryKeys.attributesMap, query], () => API.api.attributesMapList(query), {
    select: (data) => data.data,
    keepPreviousData: true,
  });
};

export const patchAttributeMapMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, data }: { id: number; data: PatchedAttributeMap }) =>
      API.api.attributesMapPartialUpdate(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.attributesMap);
      },
    },
  );
};

export const deleteAttributeMapMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => API.api.attributesMapDestroy(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.attributesMap);
    },
  });
};

export const hardDeleteAttributeMapMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => API.api.attributesMapHardDeleteDestroy(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.attributesMap);
    },
  });
};
