import { Form, InputNumber, message, Modal, Space, Typography } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useT from '@/hooks/useT';
import { updateInvoicesPurchaseLineMutation } from '@/queries/invoices.queries';
import { PatchedPurchaseInvoiceLine, PurchaseInvoiceLine } from '@/types/Api';
import { formatError } from '@/utils/error.utils';
type Props = {
  updatedLine: { line: PurchaseInvoiceLine; invoiceId: number };
  close: () => void;
};

const UpdateInvoicePurchaseLineModal: React.FC<Props> = ({ close, updatedLine }) => {
  const t = useT();
  const [form] = Form.useForm();
  const updateInvoicesPurchaseLineMutator = updateInvoicesPurchaseLineMutation();
  const saveTread = async (values: PatchedPurchaseInvoiceLine) => {
    const { line, invoiceId } = updatedLine;
    try {
      await updateInvoicesPurchaseLineMutator.mutateAsync({
        lineId: line.id,
        invoiceId,
        data: values,
      });
      message.success(t('updateInvoicePurchaseLineModal.successMessage'));
      close();
    } catch (err) {
      message.error(formatError(err));
    }
  };

  const handleOk = async () => {
    const values = await form.validateFields();
    saveTread(values);
  };
  const handleCancel = () => close();

  return (
    <Modal
      visible
      onOk={handleOk}
      confirmLoading={updateInvoicesPurchaseLineMutator.isLoading}
      onCancel={handleCancel}
      okText={t('button.save')}
      cancelText={t('button.cancel')}
    >
      <div>
        <Space>
          <Typography.Text type="secondary">
            <FormattedMessage id="updateInvoicePurchaseLineModal.sku" />
          </Typography.Text>
          <Typography.Text strong>{updatedLine?.line.sku}</Typography.Text>
        </Space>
      </div>

      <div>
        <Space>
          <Typography.Text type="secondary">
            <FormattedMessage id="updateInvoicePurchaseLineModal.ean" />
          </Typography.Text>
          <Typography.Text strong>{updatedLine?.line.ean}</Typography.Text>
        </Space>
      </div>

      <div>
        <Space>
          <Typography.Text type="secondary">
            <FormattedMessage id="updateInvoicePurchaseLineModal.name" />
          </Typography.Text>
          <Typography.Text strong>{updatedLine?.line.name}</Typography.Text>
        </Space>
      </div>

      <div>
        <Space>
          <Typography.Text type="secondary">
            <FormattedMessage id="updateInvoicePurchaseLineModal.quantity" />
          </Typography.Text>
          <Typography.Text strong>{updatedLine?.line.quantity}</Typography.Text>
        </Space>
      </div>

      <div>
        <Space>
          <Typography.Text type="secondary">
            <FormattedMessage id="updateInvoicePurchaseLineModal.unitNettoPrice" />
          </Typography.Text>
          <Typography.Text strong>{updatedLine?.line.unit_netto_price}</Typography.Text>
        </Space>
      </div>
      <Form
        form={form}
        layout="vertical"
        name="basic"
        autoComplete="off"
        initialValues={{
          list_price: updatedLine?.line.list_price,
          invoice_price: updatedLine?.line.invoice_price,
          net_price: updatedLine?.line.net_price,
        }}
      >
        <Form.Item
          label={t('updateInvoicePurchaseLineModal.listPriceLabel')}
          name="list_price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            step={0.01}
            min={0}
            addonAfter={'pln'}
            parser={(v) => {
              return v ? Number(v?.replaceAll(',', '.')) : Number.NaN;
            }}
          />
        </Form.Item>
        <Form.Item
          label={t('updateInvoicePurchaseLineModal.invoicePriceLabel')}
          name="invoice_price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={0}
            addonAfter={'pln'}
            parser={(v) => {
              return v ? Number(v?.replaceAll(',', '.')) : Number.NaN;
            }}
          />
        </Form.Item>
        <Form.Item
          label={t('updateInvoicePurchaseLineModal.netPriceLabel')}
          name="net_price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={0}
            addonAfter={'pln'}
            parser={(v) => {
              return v ? Number(v?.replaceAll(',', '.')) : Number.NaN;
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateInvoicePurchaseLineModal;
