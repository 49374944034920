import { Col, Radio, Row, Select, Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import AllegroVariantsTable from '@/components/AllegroVariantsTable/AllegroVariantsTable';
import EshopTable from '@/components/EshopTable/EshopTable';
import ProducersSizesTable from '@/components/ProducersSizesTable/ProducersSizesTable';
import useT from '@/hooks/useT';
import {
  getPricingProducersSummaryQuery,
  getPricingSizesSummaryQuery,
} from '@/queries/pricing.queries';
import { TyreSeason } from '@/types/Tyre.types';

import PricingProductsTable from './PricingProductsTable/PricingProductsTable';

const Analysis: React.FC = () => {
  const { search } = useLocation();
  const urlParams = React.useMemo(() => new URLSearchParams(search), [search]);
  const t = useT();

  const [producer, setProducer] = useState<string | null>(urlParams.get('producer') || null);
  const [size, setSize] = useState<string | null>(urlParams.get('size') || null);
  const [season, setSeason] = useState<TyreSeason>(
    (urlParams.get('season') as TyreSeason) || TyreSeason.summer,
  );

  const producersSummary = getPricingProducersSummaryQuery({
    season,
  });
  const sizesSummary = getPricingSizesSummaryQuery({
    producer: producer as string,
    season,
  });
  const producersValuesData = producersSummary?.data || [];
  const sizesValuesData = useMemo(() => sizesSummary.data || [], [sizesSummary.data]);

  const changeProducer = (newProducer: string | null) => {
    setProducer(newProducer);
  };

  const changeSize = (newSize: string | null) => {
    setSize(newSize);
  };

  const changeSeason = (newSeason: TyreSeason) => {
    setSeason(newSeason);
  };

  useEffect(() => {
    if (sizesSummary.isSuccess && !sizesValuesData.find((sizeValue) => sizeValue.size === size)) {
      setSize(null);
    }
  }, [sizesValuesData, size, sizesSummary.isSuccess]);

  return (
    <>
      <Helmet>
        <title>{t('title.pricing.analysis')}</title>
      </Helmet>
      <Space>
        <Select
          showSearch
          value={producer}
          onChange={changeProducer}
          style={{ width: 200 }}
          placeholder={'Producent'}
          optionFilterProp="name"
          filterOption={(input, option) =>
            option?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {producersValuesData.map((producersValue) => (
            <Select.Option
              key={producersValue.producer}
              value={producersValue.producer}
              name={producersValue.producer}
            >
              {producersValue.producer}
            </Select.Option>
          ))}
        </Select>
        <Select
          showSearch
          value={size}
          onChange={setSize}
          placeholder={'Rozmiar'}
          style={{ width: 200 }}
          optionFilterProp="name"
          disabled={!producer}
          filterOption={(input, option) =>
            option?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {sizesValuesData.map((sizesValue) => (
            <Select.Option key={sizesValue.size} value={sizesValue.size} name={sizesValue.size}>
              {sizesValue.size}
            </Select.Option>
          ))}
        </Select>

        <Radio.Group onChange={(e) => changeSeason(e.target.value)} value={season}>
          <Radio value={TyreSeason.summer}>
            <FormattedMessage id="tyreSeason.summer" />
          </Radio>
          <Radio value={TyreSeason.winter}>
            <FormattedMessage id="tyreSeason.winter" />
          </Radio>
          <Radio value={TyreSeason.allSeason}>
            <FormattedMessage id="tyreSeason.allSeason" />
          </Radio>
        </Radio.Group>
      </Space>
      <Row>
        <Col span={3}>
          <ProducersSizesTable
            firstLevel="producer"
            season={season}
            producer={producer}
            size={size}
            changeProducer={changeProducer}
            changeSize={changeSize}
          />
        </Col>

        <Col span={9}>
          {season && producer && size && (
            <>
              <EshopTable season={season} producer={producer} size={size} />
              <AllegroVariantsTable season={season} producer={producer} size={size} />
            </>
          )}
        </Col>

        <Col span={12}>
          {season && producer && size && (
            <PricingProductsTable season={season} producer={producer} size={size} />
          )}
        </Col>
      </Row>
    </>
  );
};
export default Analysis;
