import { Checkbox, Col, InputNumber, Radio, Row, Select, Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import AllegroVariantsTable from '@/components/AllegroVariantsTable/AllegroVariantsTable';
import EshopTable from '@/components/EshopTable/EshopTable';
import ProducersSizesTable from '@/components/ProducersSizesTable/ProducersSizesTable';
import useT from '@/hooks/useT';
import { getTyreClassesQuery } from '@/queries/attributesTyre.queries';
import { getYearsQuery } from '@/queries/core.queries';
import {
  getPricingProducersSummaryQuery,
  getPricingSizesSummaryQuery,
} from '@/queries/pricing.queries';
import { TyreSeason } from '@/types/Tyre.types';

import PricingProductsBySizeTable from './PricingProductsBySizeTable/PricingProductsBySizeTable';

const AnalysisSize: React.FC = () => {
  const { search } = useLocation();
  const urlParams = React.useMemo(() => new URLSearchParams(search), [search]);
  const t = useT();

  const [onlyOurs, setOnlyOurs] = useState(false);
  const [producer, setProducer] = useState<string | null>(urlParams.get('producer') || null);
  const [allegroProducer, setAllegroProducer] = useState<string | null>(null);
  const [size, setSize] = useState<string | null>(urlParams.get('size') || null);
  const [selectedTyreClasses, setSelectedTyreClasses] = useState<string[]>([]);
  const [selectedTyreYears, setSelectedTyreYears] = useState<string[]>([]);
  const [quantity, setQuantity] = useState<number | undefined>();
  const [season, setSeason] = useState<TyreSeason>(
    (urlParams.get('season') as TyreSeason) || TyreSeason.summer,
  );

  const tyreClasses = getTyreClassesQuery();
  const tyreYears = getYearsQuery();
  const producersSummary = getPricingProducersSummaryQuery({
    season,
  });

  const sizesSummary = getPricingSizesSummaryQuery({
    producer: producer as string,
    season,
  });
  const producersValuesData = producersSummary?.data || [];
  const sizesValuesData = useMemo(() => sizesSummary.data || [], [sizesSummary.data]);

  const changeProducer = (newProducer: string | null) => {
    setProducer(newProducer);
  };

  const changeSize = (newSize: string | null) => {
    setSize(newSize);
  };

  const changeSeason = (newSeason: TyreSeason) => {
    setSeason(newSeason);
  };

  useEffect(() => setAllegroProducer(producer), [producer]);

  useEffect(() => {
    if (sizesValuesData.length && !sizesValuesData.find((sizeValue) => sizeValue.size === size)) {
      setSize(null);
      setProducer(null);
    }
  }, [sizesValuesData, size]);

  return (
    <>
      <Helmet>
        <title>{t('title.pricing.analysisSize')}</title>
      </Helmet>
      <Space>
        <Select
          showSearch
          value={size}
          onChange={setSize}
          placeholder={'Rozmiar'}
          style={{ width: 200 }}
          optionFilterProp="name"
          filterOption={(input, option) =>
            option?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {sizesValuesData.map((sizesValue) => (
            <Select.Option key={sizesValue.size} value={sizesValue.size} name={sizesValue.size}>
              {sizesValue.size}
            </Select.Option>
          ))}
        </Select>
        <Select
          showSearch
          value={producer}
          onChange={changeProducer}
          style={{ width: 200 }}
          placeholder={'Producent'}
          optionFilterProp="name"
          disabled={!size}
          filterOption={(input, option) =>
            option?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {producersValuesData.map((producersValue) => (
            <Select.Option
              key={producersValue.producer}
              value={producersValue.producer}
              name={producersValue.producer}
            >
              {producersValue.producer}
            </Select.Option>
          ))}
        </Select>

        <Radio.Group onChange={(e) => changeSeason(e.target.value)} value={season}>
          <Radio value={TyreSeason.summer}>
            <FormattedMessage id="tyreSeason.summer" />
          </Radio>
          <Radio value={TyreSeason.winter}>
            <FormattedMessage id="tyreSeason.winter" />
          </Radio>
          <Radio value={TyreSeason.allSeason}>
            <FormattedMessage id="tyreSeason.allSeason" />
          </Radio>
        </Radio.Group>

        <Select
          mode="multiple"
          allowClear
          showSearch
          value={selectedTyreClasses}
          onChange={setSelectedTyreClasses}
          style={{ width: 400 }}
          placeholder={'Klasa'}
          optionFilterProp="name"
        >
          {tyreClasses.data?.map((tyreClass) => (
            <Select.Option key={tyreClass.id} value={tyreClass.name} name={tyreClass.name}>
              {tyreClass.name}
            </Select.Option>
          ))}
        </Select>
        <Select
          mode="multiple"
          allowClear
          showSearch
          value={selectedTyreYears}
          onChange={setSelectedTyreYears}
          style={{ width: 250 }}
          placeholder={'Rok'}
          optionFilterProp="name"
        >
          {tyreYears.data?.map((tyreYear) => (
            <Select.Option key={tyreYear.id} value={tyreYear.name} name={tyreYear.name}>
              {tyreYear.name}
            </Select.Option>
          ))}
        </Select>
        <InputNumber step={1} min={0} value={quantity} onChange={setQuantity} placeholder="Qty" />
        <Checkbox value={onlyOurs} onChange={(v) => setOnlyOurs(v.target.checked)}>
          <FormattedMessage id="analysisSize.filter.onlyOurs" />
        </Checkbox>
      </Space>
      <Row>
        <Col span={3}>
          <ProducersSizesTable
            firstLevel="size"
            season={season}
            producer={producer}
            size={size}
            changeProducer={changeProducer}
            changeSize={changeSize}
          />
        </Col>

        <Col span={12}>
          {size && (
            <PricingProductsBySizeTable
              season={season}
              producer={producer}
              size={size}
              tyreClasses={selectedTyreClasses}
              tyreYears={selectedTyreYears}
              onlyOurs={onlyOurs}
              quantity={quantity}
              onClickName={(p: string) => setAllegroProducer(p)}
            />
          )}
        </Col>

        <Col span={9}>
          {season && allegroProducer && size && (
            <>
              <span>{allegroProducer}</span>
              <EshopTable season={season} producer={allegroProducer} size={size} />
              <AllegroVariantsTable season={season} producer={allegroProducer} size={size} />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
export default AnalysisSize;
