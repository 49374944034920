import React from 'react';

import styles from './ProfitCell.style.module.less';

export const getProfitLevel = (profit: string) => {
  const profitAsNumber = Number(profit);
  if (profitAsNumber <= 5) {
    return 1;
  }
  if (profitAsNumber <= 10) {
    return 2;
  }
  if (profitAsNumber <= 15) {
    return 3;
  }
  return 4;
};

const ProfitCell: React.FC<{ profit: string }> = ({ profit }) => {
  if (!profit) {
    return null;
  }
  return <div className={styles[`profitLevel_${getProfitLevel(profit)}`]}>{profit}</div>;
};

export default ProfitCell;
