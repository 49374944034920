import { Button } from 'antd';

import CopyCell from '@/components/CopyCell';
import { PurchaseInvoiceLine } from '@/types/Api';

interface Props {
  assign: (invoiceId: number, lineId: number) => void;
  openCancelLineConfirmModal: (invoiceId: number, lineId: number) => void;
  assignZero: (invoiceId: number, lineId: number) => void;
  setUpdatedLine: (updatedLine: { invoiceId: number; line: PurchaseInvoiceLine } | null) => void;
  setAvgCalculate: (invoiceId: number, lineId: number, avgCalculatePrice: boolean) => void;
  invoiceId: number;
  termId?: number;
  showButtons: boolean;
}

const useTermsInvoicePurchaseLinesColumns = (props: Props) => {
  const {
    assign,
    assignZero,
    invoiceId,
    termId,
    setUpdatedLine,
    showButtons,
    setAvgCalculate,
    openCancelLineConfirmModal,
  } = props;

  return [
    ...(showButtons
      ? [
          {
            title: 'Oznacz',
            key: 'checkButton',
            width: '5%',
            render: (text: string, line: PurchaseInvoiceLine) => (
              <Button
                disabled={!termId}
                size="small"
                type="primary"
                onClick={() => assign(invoiceId, line.id)}
              >
                Oznacz
              </Button>
            ),
          },
        ]
      : [
          {
            title: 'Anuluj',
            key: 'cancelButton',
            width: '5%',
            render: (text: string, line: PurchaseInvoiceLine) => (
              <Button
                size="small"
                type="primary"
                onClick={() => openCancelLineConfirmModal(invoiceId, line.id)}
              >
                Anuluj
              </Button>
            ),
          },
        ]),
    {
      title: 'Season',
      key: 'seasson',
      dataIndex: ['product', 'attributes', 'season'],
      width: '5%',
    },
    {
      title: 'Year',
      key: 'productionYear',
      dataIndex: 'production_year',
      width: '5%',
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: '30%',
    },
    {
      title: 'quantity',
      key: 'quantity',
      dataIndex: 'quantity',
      width: '5%',
    },

    {
      title: 'unit netto price',
      key: 'unit_netto_price',
      dataIndex: 'unit_netto_price',
      width: '5%',
      align: 'right' as const,
    },
    {
      title: 'invoice price',
      key: 'invoice_price',
      dataIndex: 'invoice_price',
      width: '5%',
      align: 'right' as const,
    },
    {
      title: 'net price',
      key: 'net_price',
      dataIndex: 'net_price',
      width: '5%',
      align: 'right' as const,
    },
    {
      title: 'list price',
      key: 'list_price',
      dataIndex: 'list_price',
      width: '5%',
      align: 'right' as const,
    },
    {
      title: 'sku',
      key: 'sku',
      dataIndex: 'sku',
      width: '5%',
      align: 'right' as const,
    },
    {
      title: 'code',
      key: 'code',
      dataIndex: ['product', 'code'],
      width: '5%',
      align: 'right' as const,
      render: (code: string) => <CopyCell text={code} maxLength={20} />,
    },
    {
      title: 'ean',
      key: 'ean',
      dataIndex: ['product', 'ean'],
      width: '5%',
      align: 'right' as const,
      render: (ean: string) => <CopyCell text={ean} maxLength={20} />,
    },
    {
      title: 'term id',
      key: 'term_id',
      dataIndex: 'term',
      width: '5%',
      align: 'right' as const,
    },
    ...(showButtons
      ? [
          {
            title: 'Wpisz ręcznie',
            key: 'popupButton',
            width: '5%',
            render: (text: string, line: PurchaseInvoiceLine) => (
              <Button
                size="small"
                type="primary"
                onClick={() => setUpdatedLine({ invoiceId, line })}
              >
                Wpisz
              </Button>
            ),
          },
          {
            title: 'Zerowe',
            key: 'zeroButton',
            width: '5%',
            render: (text: string, line: PurchaseInvoiceLine) => (
              <Button size="small" type="primary" onClick={() => assignZero(invoiceId, line.id)}>
                Zerowe
              </Button>
            ),
          },
        ]
      : []),
    {
      title: 'Calc AvgP',
      key: 'calculateAvgPrice',
      dataIndex: 'calculate_avg_price',
      width: '5%',
      render: (isCurrent: boolean) => <span>{isCurrent ? '✅' : '🚫'}</span>,
    },
    {
      title: 'Nie przeliczaj',
      key: 'toggleAvgCalculateButton',
      width: '5%',
      render: (text: string, line: PurchaseInvoiceLine) => (
        <Button
          size="small"
          type="primary"
          onClick={() => setAvgCalculate(invoiceId, line.id, !line.calculate_avg_price)}
        >
          Nie przeliczaj
        </Button>
      ),
    },
  ];
};

export default useTermsInvoicePurchaseLinesColumns;
