/* eslint-disable @typescript-eslint/naming-convention */
import { Button, Form, Input, InputNumber, message, Modal, Select, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import useT from '@/hooks/useT';
import {
  createPricingChannelVariantStrategyMutation,
  deletePricingChannelVariantStrategyMutation,
  getPricingChannelVariantStrategyQuery,
  updatePricingChannelVariantStrategyMutation,
} from '@/queries/pricing.queries';
import {
  ChannelVariantPricingStrategy,
  PriceFightModeEnum,
  PricingProductVariant,
  ProductPricing,
  StrategyEnum,
} from '@/types/Api';
import { formatError } from '@/utils/error.utils';

type Props = {
  productWithVariant: {
    product: ProductPricing;
    variant: PricingProductVariant;
  } | null;
  close: () => void;
};

type Strategy = `${StrategyEnum}`;
type PriceFightMode = `${PriceFightModeEnum}`;

const strategies: Strategy[] = [
  'profits',
  'manual',
  'price_fight',
  'allegro_price',
  'profits_allegro',
];

const priceFightModes: PriceFightMode[] = ['ean', 'producer', 'tread'];

const PricingProductVariantModal: React.FC<Props> = ({ productWithVariant = null, close }) => {
  if (!productWithVariant) {
    return null;
  }

  const { product, variant } = productWithVariant;
  //TODO
  const channelVariantStrategyId = variant.channel_variant_strategy?.id || 0;
  const channelVariantId = variant.id;
  const pricingChannelVariantStrategy = getPricingChannelVariantStrategyQuery(
    channelVariantId,
    channelVariantStrategyId,
  );

  const [form] = Form.useForm();
  const strategyValue = Form.useWatch<Strategy>('strategy', form);
  const t = useT();

  const createPricingChannelVariantStrategyMutator = createPricingChannelVariantStrategyMutation();
  const updatePricingChannelVariantStrategyMutator = updatePricingChannelVariantStrategyMutation();
  const deletePricingChannelVariantStrategyMutator = deletePricingChannelVariantStrategyMutation();

  const isSaveLoading =
    createPricingChannelVariantStrategyMutator.isLoading ||
    updatePricingChannelVariantStrategyMutator.isLoading;
  const savePricingChannelVariantStrategy = async (data: ChannelVariantPricingStrategy) => {
    try {
      if (channelVariantStrategyId) {
        await updatePricingChannelVariantStrategyMutator.mutateAsync({
          channelVariantId,
          id: channelVariantStrategyId,
          data,
        });
      } else {
        await createPricingChannelVariantStrategyMutator.mutateAsync({
          channelVariantId,
          data,
        });
      }
      message.success(t('pricingProductVariantModal.successMessage'));
      close();
    } catch (err) {
      message.error(formatError(err));
    }
  };

  const handleSave = async () => {
    const values = await form.validateFields();
    savePricingChannelVariantStrategy(values);
  };
  const handleRemove = async () => {
    try {
      await deletePricingChannelVariantStrategyMutator.mutateAsync({
        channelVariantId,
        id: channelVariantStrategyId,
      });
      message.success(t('pricingProductVariantModal.successMessage'));
      close();
    } catch (err) {
      message.error(formatError(err));
    }
  };

  useEffect(() => {
    if (!pricingChannelVariantStrategy.data) {
      return;
    }
    const {
      strategy,
      name,
      description,
      allegro_price_allowance,
      price_fight_allowance,
      price_fight_position,
      price_fight_profit_difference,
      price_fight_mode,
    } = pricingChannelVariantStrategy.data;
    form.setFieldsValue({
      strategy,
      name,
      description,
      allegro_price_allowance,
      price_fight_allowance,
      price_fight_position,
      price_fight_profit_difference,
      price_fight_mode,
    });
  }, [pricingChannelVariantStrategy.data, form]);

  return (
    <Modal
      visible={!!productWithVariant}
      onCancel={close}
      footer={[
        <Button key="back" onClick={close}>
          {t('button.cancel')}
        </Button>,
        channelVariantStrategyId ? (
          <Button
            key="delete"
            type="primary"
            danger
            loading={deletePricingChannelVariantStrategyMutator.isLoading}
            onClick={handleRemove}
          >
            {t('button.delete')}
          </Button>
        ) : null,
        <Button key="save" type="primary" onClick={handleSave} loading={isSaveLoading}>
          {t('button.save')}
        </Button>,
      ].filter(Boolean)}
    >
      <div>
        <Space>
          <Typography.Text type="secondary">
            <FormattedMessage id="pricingProductVariantModal.skuInfo" />
          </Typography.Text>
          <Typography.Text strong>{variant.sku}</Typography.Text>
        </Space>
      </div>

      <div>
        <Space>
          <Typography.Text type="secondary">
            <FormattedMessage id="pricingProductVariantModal.eanInfo" />
          </Typography.Text>
          <Typography.Text strong>{product.ean}</Typography.Text>
        </Space>
      </div>

      <div>
        <Space>
          <Typography.Text type="secondary">
            <FormattedMessage id="pricingProductVariantModal.codeInfo" />
          </Typography.Text>
          <Typography.Text strong>{product.code}</Typography.Text>
        </Space>
      </div>

      <div>
        <Space>
          <Typography.Text type="secondary">
            <FormattedMessage id="pricingProductVariantModal.nameInfo" />
          </Typography.Text>
          <Typography.Text strong>{product.name}</Typography.Text>
        </Space>
      </div>

      <div>
        <Space>
          <Typography.Text type="secondary">
            <FormattedMessage id="pricingProductVariantModal.channelInfo" />
          </Typography.Text>
          <Typography.Text strong>{variant.channel?.name}</Typography.Text>
        </Space>
      </div>

      <div>
        <Space>
          <Typography.Text type="secondary">
            <FormattedMessage id="pricingProductVariantModal.sourceInfo" />
          </Typography.Text>
          <Typography.Text strong>{variant.source?.name}</Typography.Text>
        </Space>
      </div>
      <div>
        <Space>
          <Typography.Text type="secondary">
            <FormattedMessage id="pricingProductVariantModal.attributesProductionYearInfo" />
          </Typography.Text>
          <Typography.Text strong>{variant.attributes.production_year}</Typography.Text>
        </Space>
      </div>
      <div>
        <Space>
          <Typography.Text type="secondary">
            <FormattedMessage id="pricingProductVariantModal.priceInfo" />
          </Typography.Text>
          <Typography.Text strong>{variant.price}</Typography.Text>
        </Space>
      </div>
      <Form
        form={form}
        layout="vertical"
        name="basic"
        autoComplete="off"
        initialValues={{
          name: 'Custom',
          allegro_price_allowance: 0,
          allegro_price_quantity_division: 4,
          price_fight_allowance: 0,
          price_fight_position: 1,
          price_fight_profit_difference: 0,
          price_fight_mode: PriceFightModeEnum.Ean,
        }}
      >
        <Form.Item
          name="strategy"
          label={t('pricingProductVariantModal.strategy')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select<Strategy>>
            {strategies.map((strategy) => (
              <Select.Option key={strategy} value={strategy}>
                {strategy}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('pricingProductVariantModal.name')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('pricingProductVariantModal.description')} name="description">
          <Input />
        </Form.Item>

        {(strategyValue === 'allegro_price' || strategyValue === 'profits_allegro') && (
          <>
            <Form.Item
              label={t('pricingProductVariantModal.allegroPriceQuantityDivision')}
              name="allegro_price_quantity_division"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                step={0.01}
                parser={(v) => {
                  return v ? Number(v?.replaceAll(',', '.')) : Number.NaN;
                }}
              />
            </Form.Item>
            <Form.Item
              label={t('pricingProductVariantModal.allegroPriceAllowance')}
              name="allegro_price_allowance"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                step={0.01}
                parser={(v) => {
                  return v ? Number(v?.replaceAll(',', '.')) : Number.NaN;
                }}
              />
            </Form.Item>
          </>
        )}

        {strategyValue === 'price_fight' && (
          <>
            <Form.Item
              label={t('pricingProductVariantModal.priceFightPosition')}
              name="price_fight_position"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                step={1}
                min={1}
                parser={(v) => {
                  return v ? Number(v?.replaceAll(',', '.')) : Number.NaN;
                }}
              />
            </Form.Item>
            <Form.Item
              label={t('pricingProductVariantModal.priceFightAllowance')}
              name="price_fight_allowance"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                step={0.01}
                parser={(v) => {
                  return v ? Number(v?.replaceAll(',', '.')) : Number.NaN;
                }}
              />
            </Form.Item>
            <Form.Item
              label={t('pricingProductVariantModal.priceFightProfitDifference')}
              name="price_fight_profit_difference"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                step={0.01}
                parser={(v) => {
                  return v ? Number(v?.replaceAll(',', '.')) : Number.NaN;
                }}
              />
            </Form.Item>
            <Form.Item
              label={t('pricingProductVariantModal.priceFightMode')}
              name="price_fight_mode"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select<PriceFightMode>>
                {priceFightModes.map((priceFightMode) => (
                  <Select.Option key={priceFightMode} value={priceFightMode}>
                    {priceFightMode}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default PricingProductVariantModal;
