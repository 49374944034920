import { useMutation, useQuery, useQueryClient } from 'react-query';

import { API } from '../utils/request.utils';
import { ApiInterface, PatchedPurchaseInvoiceLine } from '@/types/Api';
import { QueryKeys } from '@/types/QueryKeys.types';

export function getInvoicesPurchaseQuery(
  params: ApiInterface.InvoicesPurchaseList.RequestQuery = {},
) {
  return useQuery(
    [QueryKeys.invoicesPurchase, params],
    () => API.api.invoicesPurchaseList(params),
    {
      select: (data) => data.data,
      keepPreviousData: true,
    },
  );
}

export const createInvoicesPurchaseBulkAssignMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { id: number; termId: number }) =>
      API.api.invoicesPurchaseBulkAssignPricesCreate(data.id, { term_id: data.termId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.invoicesPurchase]);
      },
    },
  );
};

export const createInvoicesPurchaseLineAssignMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { invoiceId: number; lineId: number; termId: number }) =>
      API.api.invoicesPurchaseLinesAssignPricesCreate(data.lineId, data.invoiceId, {
        term_id: data.termId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.invoicesPurchase]);
      },
    },
  );
};

export const createInvoicesPurchaseBulkAssignZeroMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { id: number }) => API.api.invoicesPurchaseBulkAssignTermZeroPricesCreate(data.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.invoicesPurchase]);
      },
    },
  );
};

export const createInvoicesPurchaseLineAssignZeroMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { invoiceId: number; lineId: number }) =>
      API.api.invoicesPurchaseLinesAssignTermZeroPricesCreate(data.lineId, data.invoiceId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.invoicesPurchase]);
      },
    },
  );
};

export const createInvoicesPurchaseCalculateAveragePricesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { id: number }) => API.api.invoicesPurchaseCalculateAveragePricesCreate(data.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.invoicesPurchase]);
      },
    },
  );
};

export const setInvoicesPurchaseAsWaitingMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { id: number }) => API.api.invoicesPurchasePartialUpdate(data.id, { is_waiting: true }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.invoicesPurchase]);
      },
    },
  );
};

export const updateInvoicesPurchaseLineMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { invoiceId: number; lineId: number; data: PatchedPurchaseInvoiceLine }) =>
      API.api.invoicesPurchaseLinesPartialUpdate(data.lineId, data.invoiceId, data.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.invoicesPurchase]);
      },
    },
  );
};

export const cancelInvoicesPurchaseLineMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { invoiceId: number; lineId: number }) =>
      API.api.invoicesPurchaseLinesCancelLineCreate(data.lineId, data.invoiceId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.invoicesPurchase]);
      },
    },
  );
};
