// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */
import update from 'immutability-helper';
import React, { useCallback, useRef } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useHistory } from 'react-router-dom';

import CustomTable from '../CustomTable';
import {
  getProducersQuery,
  postReorderAttributeProducerMutation,
} from '@/queries/attributes.queries';
import { Routes } from '@/types/Routes.types';
import { getRoutePath } from '@/utils/path.utils';
import useProducersColumns from './useProducersColumns';

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

const NoDraggableBodyRow = ({ className, style, ...restProps }) => {
  return <tr className={className} style={style} {...restProps} />;
};

type Props = {
  searchQuery?: string;
};

const ProducersTable: React.FC<Props> = ({ searchQuery = '' }) => {
  const columns = useProducersColumns();
  const history = useHistory();
  const tyreProducers = getProducersQuery();
  const postReorderAttributeProducerMutator = postReorderAttributeProducerMutation();
  const { data = [] } = tyreProducers;

  const components = {
    body: {
      row: searchQuery.length === 0 ? DraggableBodyRow : NoDraggableBodyRow,
    },
  };

  const sortedData = data.sort((a, b) => a.sort_order - b.sort_order);
  const filteredData = sortedData.filter((producer) =>
    producer.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = sortedData[dragIndex];
      const newData = update(sortedData, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      }).map((item, index) => ({
        ...item,
        sort_order: index,
      }));
      postReorderAttributeProducerMutator.mutate(newData);
    },
    [sortedData, postReorderAttributeProducerMutator],
  );
  return (
    <DndProvider backend={HTML5Backend}>
      <CustomTable
        isLoading={tyreProducers.isLoading}
        columns={columns}
        data={{
          results: filteredData,
        }}
        components={components}
        canDrop={searchQuery.length === 0}
        onRow={(record, index) => ({
          index,
          moveRow,
          onClick: () =>
            history.push(
              getRoutePath(Routes.attributesProducerTreads, {
                producerId: record.id,
              }),
            ),
        })}
      />
    </DndProvider>
  );
};

export default ProducersTable;
