import { useState, useCallback } from 'react';

type BaseParams = {
  limit?: number;
  offset: number;
  current: number;
};

const DEFAULT_LIMIT = 10;

const useTable = function <T>(initParams: T = {} as T) {
  const [params, setParams] = useState<T & BaseParams>({
    limit: DEFAULT_LIMIT,
    offset: 0,
    current: 1,
    ...initParams,
  });
  const { current, ...restParams } = params;

  const onChangePagination = (pagination: { current?: number; pageSize?: number }) => {
    const { pageSize: limit = DEFAULT_LIMIT, current: currentPage = 1 } = pagination;
    const offset = (currentPage - 1) * limit;
    setParams((p) => ({
      ...p,
      limit,
      offset,
      current: Math.floor(offset / limit) + 1,
    }));
  };
  const setQuery = useCallback(
    (query: Record<string, string | number | boolean | undefined>) => {
      setParams((p) => ({
        ...p,
        offset: 0,
        current: 1,
        ...query,
      }));
    },
    [setParams],
  );
  return {
    params: restParams,
    setQuery,
    pagination: {
      onChangePagination,
      pageSize: params.limit,
      current: params.current,
    },
  };
};

export default useTable;
