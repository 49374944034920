import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Empty, message, Modal, Pagination, Space, Spin } from 'antd';
import Input from 'antd/lib/input/Input';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce/lib';

import UpdateInvoicePurchaseLineModal from '@/components/UpdateInvoicePurchaseLineModal';
import useT from '@/hooks/useT';
import useTable from '@/hooks/useTable';
import {
  cancelInvoicesPurchaseLineMutation,
  createInvoicesPurchaseCalculateAveragePricesMutation,
  getInvoicesPurchaseQuery,
  updateInvoicesPurchaseLineMutation,
} from '@/queries/invoices.queries';
import {
  createInvoicesPurchaseBulkAssignMutation,
  createInvoicesPurchaseBulkAssignZeroMutation,
  setInvoicesPurchaseAsWaitingMutation,
  createInvoicesPurchaseLineAssignMutation,
  createInvoicesPurchaseLineAssignZeroMutation,
} from '@/queries/invoices.queries';
import { PurchaseInvoiceLine } from '@/types/Api';
import { InvoicePurchaseStatus } from '@/types/Invoice.types';
import { formatError } from '@/utils/error.utils';

import TermsInvoicePurchase from './TermsInvoicePurchase';
const invoicePurchaseStatusToQueryMap: Record<
  InvoicePurchaseStatus,
  { is_checked: boolean; is_waiting?: boolean }
> = {
  checked: {
    is_checked: true,
    is_waiting: undefined,
  },
  toCheck: {
    is_checked: false,
    is_waiting: false,
  },
  paused: {
    is_checked: false,
    is_waiting: true,
  },
};

type Props = {
  status: InvoicePurchaseStatus;
  termId?: number;
};

const TermsInvoicePurchases: React.FC<Props> = ({ status, termId }) => {
  const t = useT();
  const [updatedLine, setUpdatedLine] = useState<{
    invoiceId: number;
    line: PurchaseInvoiceLine;
  } | null>(null);
  const { setQuery, params, pagination } = useTable({
    ...invoicePurchaseStatusToQueryMap[status],
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const createInvoicesPurchaseBulkAssignMutator = createInvoicesPurchaseBulkAssignMutation();
  const createInvoicesPurchaseBulkAssignTermZeroMutator =
    createInvoicesPurchaseBulkAssignZeroMutation();
  const setInvoicesPurchaseAsWaitingMutator = setInvoicesPurchaseAsWaitingMutation();
  const createInvoicesPurchaseLineAssignMutator = createInvoicesPurchaseLineAssignMutation();
  const createInvoicesPurchaseLineAssignZeroMutator =
    createInvoicesPurchaseLineAssignZeroMutation();
  const createInvoicesPurchaseCalculateAveragePricesMutator =
    createInvoicesPurchaseCalculateAveragePricesMutation();
  const cancelInvoicesPurchaseLineMutator = cancelInvoicesPurchaseLineMutation();
  const updateInvoicesPurchaseLineMutator = updateInvoicesPurchaseLineMutation();

  const { data, isLoading, isFetching } = getInvoicesPurchaseQuery(params);

  const bulkAssign = async (id: number) => {
    try {
      if (!termId) {
        return;
      }
      await createInvoicesPurchaseBulkAssignMutator.mutateAsync({ id, termId });
    } catch (err) {
      message.error(formatError(err));
    }
  };

  const bulkAssignZero = async (id: number) => {
    try {
      await createInvoicesPurchaseBulkAssignTermZeroMutator.mutateAsync({ id });
    } catch (err) {
      message.error(formatError(err));
    }
  };

  const assign = async (invoiceId: number, lineId: number) => {
    try {
      if (!termId) {
        return;
      }
      await createInvoicesPurchaseLineAssignMutator.mutateAsync({ invoiceId, lineId, termId });
    } catch (err) {
      message.error(formatError(err));
    }
  };

  const assignZero = async (invoiceId: number, lineId: number) => {
    try {
      await createInvoicesPurchaseLineAssignZeroMutator.mutateAsync({ invoiceId, lineId });
    } catch (err) {
      message.error(formatError(err));
    }
  };

  const pause = async (id: number) => {
    try {
      await setInvoicesPurchaseAsWaitingMutator.mutateAsync({ id });
    } catch (err) {
      message.error(formatError(err));
    }
  };

  const save = async (id: number) => {
    try {
      await createInvoicesPurchaseCalculateAveragePricesMutator.mutateAsync({ id });
    } catch (err) {
      message.error(formatError(err));
    }
  };

  const cancel = async (invoiceId: number, lineId: number) => {
    try {
      await cancelInvoicesPurchaseLineMutator.mutateAsync({ invoiceId, lineId });
    } catch (err) {
      message.error(formatError(err));
    }
  };

  const setAvgCalculate = async (invoiceId: number, lineId: number, avgCalculatePrice: boolean) => {
    try {
      await updateInvoicesPurchaseLineMutator.mutateAsync({
        invoiceId,
        lineId,
        data: { calculate_avg_price: avgCalculatePrice },
      });
    } catch (err) {
      message.error(formatError(err));
    }
  };

  const openSaveConfirmModal = (id: number) => {
    Modal.confirm({
      title: t('invoicePurchaseSaveConfirmModal.header'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        return save(id);
      },
    });
  };

  const openCancelLineConfirmModal = (invoiceId: number, lineId: number) => {
    Modal.confirm({
      title: t('invoicePurchaseLineCancelConfirmModal.header'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        return cancel(invoiceId, lineId);
      },
    });
  };

  useEffect(() => {
    setQuery({ ...invoicePurchaseStatusToQueryMap[status], search: debouncedSearchQuery });
  }, [setQuery, status, debouncedSearchQuery]);

  const results = data?.results || [];

  return (
    <div>
      <Input
        placeholder={t('invoicePurchases.searchPlaceholder')}
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value)}
        allowClear
      />
      <div>
        <Spin spinning={isLoading || isFetching}>
          {results.map((result) => (
            <TermsInvoicePurchase
              {...result}
              termId={termId}
              bulkAssign={bulkAssign}
              bulkAssignZero={bulkAssignZero}
              pause={pause}
              assign={assign}
              assignZero={assignZero}
              openSaveConfirmModal={openSaveConfirmModal}
              openCancelLineConfirmModal={openCancelLineConfirmModal}
              setAvgCalculate={setAvgCalculate}
              showButtons={status !== 'checked'}
              setUpdatedLine={setUpdatedLine}
            />
          ))}
        </Spin>
        {data?.results?.length === 0 && <Empty />}
      </div>
      <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
        <Pagination
          size="small"
          total={data?.count}
          pageSize={pagination.pageSize}
          current={pagination.current}
          onChange={(current, pageSize) => pagination.onChangePagination({ current, pageSize })}
        />
      </Space>
      {!!updatedLine && (
        <UpdateInvoicePurchaseLineModal
          close={() => setUpdatedLine(null)}
          updatedLine={updatedLine}
        />
      )}
    </div>
  );
};

export default TermsInvoicePurchases;
