import React, { useEffect } from 'react';

import CustomTable from '@/components/CustomTable';
import useTable from '@/hooks/useTable';
import { getDiscountLevels } from '@/queries/discountLevels.queries';
import { DiscountLevel } from '@/types/Api';

import useDiscountLevelColumns from './useDiscountLevelColumns';

type Props = {
  source?: string;
  setDiscountLevel: (discountLevel: DiscountLevel) => void;
  editDiscountLevel: (discountLevel: DiscountLevel) => void;
  discountLevelId?: number;
};

const TermsDiscountLevelsList: React.FC<Props> = ({
  source,
  setDiscountLevel,
  discountLevelId,
  editDiscountLevel,
}) => {
  const columns = useDiscountLevelColumns({
    selectItem: setDiscountLevel,
    selectedItemId: discountLevelId,
    editItem: editDiscountLevel,
  });
  const { setQuery, params, pagination } = useTable({
    source,
  });

  const { data, isLoading } = getDiscountLevels(params);

  useEffect(() => {
    setQuery({ source });
  }, [setQuery, source]);

  return (
    <CustomTable columns={columns} data={data} isLoading={isLoading} pagination={pagination} />
  );
};

export default TermsDiscountLevelsList;
