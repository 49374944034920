import { useMutation, useQuery, useQueryClient } from 'react-query';

import { API } from '../utils/request.utils';
import {
  ApiInterface,
  ChannelVariantPricingStrategy,
  PatchedChannelVariantPricingStrategy,
  PatchedPricingChannelVariant,
  ProfitCalculator,
} from '@/types/Api';
import { QueryKeys } from '@/types/QueryKeys.types';

export function getPricingProducersSummaryQuery(
  query: ApiInterface.PricingProducersSummaryList.RequestQuery,
  enabled = true,
) {
  return useQuery(
    [QueryKeys.pricingProducersSummary, query],
    () => API.api.pricingProducersSummaryList(query),
    {
      select: (data) => data.data,
      enabled,
    },
  );
}

export function getPricingSizesSummaryQuery(
  query: ApiInterface.PricingSizesSummaryList.RequestQuery,
  enabled = true,
) {
  return useQuery(
    [QueryKeys.pricingSizesSummary, query],
    () => API.api.pricingSizesSummaryList(query),
    {
      select: (data) => data.data,
      enabled,
    },
  );
}

export function getPricingAllegroVariantsQuery(
  query: ApiInterface.PricingAllegroVariantsList.RequestQuery,
) {
  return useQuery(
    [QueryKeys.pricingAllegroVariants, query],
    () => API.api.pricingAllegroVariantsList(query),
    {
      select: (data) => data.data,
      enabled: Boolean(query.producer && query.season && query.size),
      keepPreviousData: true,
    },
  );
}

export function getPricingShopVariantsQuery(
  query: ApiInterface.PricingShopVariantsList.RequestQuery,
) {
  return useQuery(
    [QueryKeys.pricingShopVariants, query],
    () => API.api.pricingShopVariantsList(query),
    {
      select: (data) => data.data,
      enabled: Boolean(query.producer && query.season && query.size),
      keepPreviousData: true,
    },
  );
}

export function getPricingProductsQuery(query: ApiInterface.PricingProductsList.RequestQuery) {
  return useQuery([QueryKeys.pricingProducts, query], () => API.api.pricingProductsList(query), {
    select: (data) => data.data,
    enabled: Boolean(query.producer && query.season && query.size),
    keepPreviousData: true,
  });
}

export function getPricingProductsBySizeQuery(
  query: ApiInterface.PricingProductsBySizeRetrieve.RequestQuery,
) {
  return useQuery(
    [QueryKeys.pricingProductsBySize, query],
    () => API.api.pricingProductsBySizeRetrieve(query),
    {
      select: (data) => data.data,
      enabled: Boolean(query.season && query.size),
      keepPreviousData: true,
    },
  );
}

export const calculateProfitMutation = () => {
  return useMutation((data: ProfitCalculator) => API.api.pricingCalculateProfitCreate(data));
};

export function getPricingChannelVariantStrategyQuery(
  channelVariantId: ApiInterface.PricingChannelVariantsStrategiesRetrieve.RequestParams['channelVariantId'],
  id: ApiInterface.PricingChannelVariantsStrategiesRetrieve.RequestParams['id'],
) {
  return useQuery(
    [QueryKeys.pricingChannelVariantStrategy, channelVariantId, id],
    () => API.api.pricingChannelVariantsStrategiesRetrieve(channelVariantId, id),
    {
      select: (data) => data.data,
      enabled: !!id && !!channelVariantId,
    },
  );
}

export const createPricingChannelVariantStrategyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      channelVariantId,
      data,
    }: {
      channelVariantId: number;
      data: ChannelVariantPricingStrategy;
    }) => API.api.pricingChannelVariantsStrategiesCreate(channelVariantId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.pricingProducts]);
      },
    },
  );
};

export const deletePricingChannelVariantStrategyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, channelVariantId }: { id: number; channelVariantId: number }) =>
      API.api.pricingChannelVariantsStrategiesDestroy(channelVariantId, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.pricingProducts]);
      },
    },
  );
};

export const updatePricingChannelVariantStrategyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      id,
      channelVariantId,
      data,
    }: {
      id: number;
      channelVariantId: number;
      data: PatchedChannelVariantPricingStrategy;
    }) => API.api.pricingChannelVariantsStrategiesPartialUpdate(channelVariantId, id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.pricingProducts]);
      },
    },
  );
};

export const updatePricingChannelVariantMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, data }: { id: number; data: PatchedPricingChannelVariant }) =>
      API.api.pricingChannelVariantsPartialUpdate(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.pricingProducts]);
      },
    },
  );
};
