import { Button, Input, Space } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce/lib';

import AddProducerTreadModal from '@/components/AddProducerTreadModal';
import TreadTable from '@/components/TreadTable';
import useT from '@/hooks/useT';

type Params = {
  producerId: string;
};

const AttributeProducerTreads: React.FC = () => {
  const t = useT();
  const { producerId } = useParams<Params>();
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 200);
  const [addProducerTreadVisible, setAddProducerTreadVisible] = useState(false);
  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Button type="primary" onClick={() => setAddProducerTreadVisible(true)}>
          <FormattedMessage id="tyreProducerTreads.addTreadButton" />
        </Button>
        <Input
          placeholder={t('tyreProducerTreads.searchPlaceholder')}
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
        <TreadTable searchQuery={debouncedSearchQuery} />
      </Space>
      <AddProducerTreadModal
        visible={addProducerTreadVisible}
        setVisible={setAddProducerTreadVisible}
        producerId={Number(producerId)}
      />
    </>
  );
};

export default AttributeProducerTreads;
