import { Typography } from 'antd';
import { useMemo } from 'react';

import useT from '@/hooks/useT';

const useProducersSummaryColumns = (showOrdered = false) => {
  const t = useT();

  return useMemo(
    () => [
      {
        title: t('producersSummaryTable.producerLabel'),
        dataIndex: 'producer_and_size',
        key: 'producer_and_size',
        width: 150,
        render: (producerAndSize: string) => (
          <Typography.Text ellipsis={true}>{producerAndSize}</Typography.Text>
        ),
      },
      ...(showOrdered
        ? [
            {
              title: t('producersSummaryTable.ordered'),
              dataIndex: 'ordered',
              key: 'ordered',
              align: 'right' as const,
              render: (ordered: number) => (
                <span
                  style={{
                    color: ordered > 0 ? '#ffc107' : 'initial',
                    backgroundColor: ordered > 0 ? 'black' : 'initial',
                  }}
                >
                  {ordered}
                </span>
              ),
            },
          ]
        : []),
      {
        title: t('producersSummaryTable.salesPreviousYearLabel'),
        dataIndex: 'sales_previous_year',
        key: 'sales_previous_year',
        align: 'right' as const,
      },
      {
        title: t('producersSummaryTable.salesCurrentYear'),
        dataIndex: 'sales_current_year',
        key: 'sales_current_year',
        align: 'right' as const,
      },
      {
        title: t('producersSummaryTable.quantityLabel'),
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'right' as const,
      },

      {
        title: t('producersSummaryTable.popularityLabel'),
        dataIndex: 'popularity',
        key: 'popularity',
        align: 'right' as const,
      },
      {
        title: t('producersSummaryTable.popularityIntyreLabel'),
        dataIndex: 'popularity_intyre',
        key: 'popularity_intyre',
        align: 'right' as const,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};

export default useProducersSummaryColumns;
