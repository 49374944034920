export enum Routes {
  login = '/login',

  attributesMapping = '/attributes-mapping',
  attributesProducers = '/attributes-producers',
  attributesProducerTreads = '/attributes-producers/:producerId/treads',
  attributesSizes = '/attributes-sizes',

  terms = '/terms',
  termsDiscountLevels = '/terms-discount-levels',
  termsPriceLists = '/terms-price-lists',
  termsInvoices = '/terms-invoices',

  priceLevels = '/price-levels',
  priceChannels = '/price-channels',

  pricingMerchantPanel = '/pricing-merchant-panel',
  pricingAnalysis = '/pricing-analysis',
  pricingAnalysisSize = '/pricing-analysis-size',
  pricingCurrentProfit = '/pricing-current-profit',
  pricingOrders = '/pricing-orders',
}
