import { Form, Input, message, Modal, Radio } from 'antd';
import React from 'react';

import useT from '@/hooks/useT';
import { createTyreProducerTreadMutation } from '@/queries/attributesTyre.query';
import { Tread } from '@/types/Api';
import { TyreSeason, TyreType } from '@/types/Tyre.types';
import { formatError } from '@/utils/error.utils';
type Props = {
  visible?: boolean;
  setVisible: (visible: boolean) => void;
  producerId: number;
};

const AddProducerTreadModal: React.FC<Props> = ({ visible = false, setVisible, producerId }) => {
  const t = useT();
  const [form] = Form.useForm();
  const createTyreProducerTreadMutator = createTyreProducerTreadMutation();

  const saveTread = async (values: Tread) => {
    try {
      await createTyreProducerTreadMutator.mutateAsync({
        producerId,
        data: values,
      });
      message.success(t('addProducerTreadModal.successMessage'));
      setVisible(false);
    } catch (err) {
      message.error(formatError(err));
    }
  };

  const handleOk = async () => {
    const values = await form.validateFields();
    saveTread(values);
  };

  const handleCancel = () => setVisible(false);
  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      confirmLoading={createTyreProducerTreadMutator.isLoading}
      onCancel={handleCancel}
      okText={t('button.save')}
      cancelText={t('button.cancel')}
    >
      <Form form={form} layout="vertical" name="basic" autoComplete="off">
        <Form.Item
          label={t('addProducerTreadModal.nameLabel')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('addProducerTreadModal.seasonLabel')}
          name="season"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={TyreSeason.winter}>{TyreSeason.winter}</Radio.Button>
            <Radio.Button value={TyreSeason.summer}>{TyreSeason.summer}</Radio.Button>
            <Radio.Button value={TyreSeason.allSeason}>{TyreSeason.allSeason}</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label={t('addProducerTreadModal.tyreTypeLabel')}
          name="tyre_type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={TyreType.car}>{TyreType.car}</Radio.Button>
            <Radio.Button value={TyreType.truck}>{TyreType.truck}</Radio.Button>
            <Radio.Button value={TyreType.fourWheel}>{TyreType.fourWheel}</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddProducerTreadModal;
