import cx from 'classnames';
import React, { useCallback } from 'react';

import CustomTable from '@/components/CustomTable';
import {
  getPricingProducersSummaryQuery,
  getPricingSizesSummaryQuery,
} from '@/queries/pricing.queries';
import {
  getWarehouseOrderProducersSummaryQuery,
  getWarehouseOrderSizeSummaryQuery,
} from '@/queries/warehouse.queries';
import { OrderSummary, ProducerPricing, SizePricing } from '@/types/Api';
import { TyreSeason } from '@/types/Tyre.types';

import styles from './ProducersSizesTable.style.module.less';
import useProducersSizesColumns from './useProducersSizesColumns';

type LevelAttribute = 'size' | 'producer';

type Props = {
  season: TyreSeason;
  producer: string | null;
  size: string | null;
  changeProducer: (producer: string | null) => void;
  changeSize: (size: string | null) => void;
  firstLevel: LevelAttribute;
  warehouseOrderId?: number;
  summaryProducers?: string[];
};

export type ProducersSizesRow = ReturnType<typeof mapData>[0];

const mapProducer = (producerPricing: ProducerPricing | OrderSummary) => ({
  type: 'producer',
  id: producerPricing.producer,
  producer_and_size: producerPricing.producer,
  ordered: 'ordered' in producerPricing ? producerPricing.ordered : 0,
  sales_previous_year: producerPricing.sales_previous_year,
  sales_current_year: producerPricing.sales_current_year,
  quantity: producerPricing.quantity,
  popularity: producerPricing.popularity,
  popularity_intyre: producerPricing.popularity_intyre,
});

const mapSize = (sizePricing: SizePricing | OrderSummary) => ({
  type: 'size',
  id: sizePricing.size,
  producer_and_size: sizePricing.size,
  ordered: 'ordered' in sizePricing ? sizePricing.ordered : 0,
  sales_previous_year: sizePricing.sales_previous_year,
  sales_current_year: sizePricing.sales_current_year,
  quantity: sizePricing.quantity,
  popularity: sizePricing.popularity,
  popularity_intyre: sizePricing.popularity_intyre,
});

const mapData = ({
  producers = [],
  sizes = [],
  activeValue,
  firstLevelAttribute,
}: {
  producers: (ProducerPricing | OrderSummary)[];
  sizes: (SizePricing | OrderSummary)[];
  activeValue: string | null;
  firstLevelAttribute: LevelAttribute;
}) => {
  if (firstLevelAttribute === 'producer') {
    return producers.map((producer) => {
      const item = {
        isChild: false,
        ...mapProducer(producer),
        children:
          producer.producer === activeValue
            ? sizes.map((size) => ({
                isChild: true,
                ...mapSize(size),
              }))
            : [],
      };
      return item;
    });
  }
  if (firstLevelAttribute === 'size') {
    return sizes.map((size) => {
      const item = {
        isChild: false,
        ...mapSize(size),
        children:
          size.size === activeValue
            ? producers.map((producer) => ({
                isChild: true,
                ...mapProducer(producer),
              }))
            : [],
      };
      return item;
    });
  }
  return [];
};

const useSizes = (data: {
  orderId?: number;
  season: TyreSeason;
  producer: string | null;
  firstLevelIsProducer: boolean;
  summaryProducers?: string[];
}) => {
  const { orderId, season, producer, firstLevelIsProducer, summaryProducers } = data;
  const query = {
    season,
    producer: firstLevelIsProducer ? (producer as string) : undefined,
    producers: summaryProducers,
  };
  const sizes = getPricingSizesSummaryQuery(query, !orderId);
  const ordersSizes = getWarehouseOrderSizeSummaryQuery(orderId || 0, query, !!orderId);
  return orderId ? ordersSizes : sizes;
};

const useProducers = (data: {
  orderId?: number;
  season: TyreSeason;
  size: string | null;
  firstLevelIsProducer: boolean;
}) => {
  const { orderId, season, size, firstLevelIsProducer } = data;
  const query = {
    season,
    size: firstLevelIsProducer ? undefined : size || undefined,
  };
  const producers = getPricingProducersSummaryQuery(query, !orderId);
  const ordersProducers = getWarehouseOrderProducersSummaryQuery(orderId || 0, query, !!orderId);

  return orderId ? ordersProducers : producers;
};

const ProducersSizesTable: React.FC<Props> = ({
  season,
  producer,
  size,
  warehouseOrderId,
  changeProducer,
  changeSize,
  firstLevel,
  summaryProducers,
}) => {
  const firstLevelIsProducer = firstLevel === 'producer';
  const activeValue = firstLevelIsProducer ? producer : size;
  const activeChildValue = firstLevelIsProducer ? size : producer;

  const producers = useProducers({
    orderId: warehouseOrderId,
    season,
    size,
    firstLevelIsProducer,
  });
  const sizes = useSizes({
    orderId: warehouseOrderId,
    summaryProducers,
    season,
    producer,
    firstLevelIsProducer,
  });
  const sizesData = sizes.data || [];
  const producersData = producers.data || [];

  const producersSummaryColumns = useProducersSizesColumns(!!warehouseOrderId);

  const onClickRow = (row: ProducersSizesRow) => {
    const value = row.producer_and_size || null;
    if (row.isChild) {
      const newValue = activeChildValue === value ? null : value;
      return firstLevelIsProducer ? changeSize(newValue) : changeProducer(newValue);
    }
    if (value === producer) {
      return changeProducer(null);
    }
    if (value === size) {
      return changeSize(null);
    }

    if (firstLevelIsProducer) {
      changeProducer(value);
    } else {
      changeSize(value);
    }
  };

  const getRowClassName = useCallback(
    (record: ProducersSizesRow) => {
      return cx({
        [styles.child]: record.isChild,
        [styles.child_expanded]: record.isChild && record.producer_and_size === activeChildValue,
      });
    },
    [activeChildValue],
  );
  return (
    <CustomTable
      expandable={{
        expandedRowKeys: activeValue ? [activeValue] : [],
        expandIconColumnIndex: -1,
      }}
      small
      data={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        results: mapData({
          producers: producersData,
          sizes: sizesData,
          activeValue,
          firstLevelAttribute: firstLevel,
        }),
      }}
      columns={producersSummaryColumns}
      isLoading={producers.isLoading || sizes.isLoading || sizes.isFetching}
      onRow={(record) => ({
        onClick: () => onClickRow(record),
      })}
      rowClassName={getRowClassName}
      scroll={{ y: window.innerHeight - 100 }}
      tableLayout="auto"
    />
  );
};
export default ProducersSizesTable;
