import { ConfigProvider } from 'antd';
import plPL from 'antd/lib/locale/pl_PL';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';

import EmptyState from '@/components/EmptyState';
import RootNavigation from '@/navigation/root.navigation';
import messagesInPolish from '@/translations/pl.json';
import '@/styles/main.less';
import 'antd/dist/antd.less';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <IntlProvider messages={messagesInPolish} locale="pl" defaultLocale="pl">
          <ConfigProvider renderEmpty={() => <EmptyState />} locale={plPL}>
            <RootNavigation />
          </ConfigProvider>
        </IntlProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
};

export default App;
