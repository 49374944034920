import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Tread, ApiInterface } from '@/types/Api';
import { QueryKeys } from '@/types/QueryKeys.types';
import { API } from '@/utils/request.utils';

export const getTyreProducerTreadsQuery = (
  producerId: number,
  query: ApiInterface.AttributesTyreProducersTreadsList.RequestQuery,
) => {
  return useQuery(
    [
      QueryKeys.tyreProducerTreads,
      {
        producerId,
        ...query,
      },
    ],
    () => API.api.attributesTyreProducersTreadsList(producerId, query),
    {
      select: (data) => data.data,
    },
  );
};

export function getTyreSizesQuery(query: ApiInterface.AttributesTyreSizesList.RequestQuery = {}) {
  return useQuery([QueryKeys.tyreSizes, query], () => API.api.attributesTyreSizesList(query), {
    select: (data) => data.data,
  });
}

export const createTyreProducerTreadMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ producerId, data }: { producerId: number; data: Tread }) =>
      API.api.attributesTyreProducersTreadsCreate(producerId, data, {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.tyreProducerTreads);
      },
    },
  );
};

export const createTyreSizeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(API.api.attributesTyreSizesCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.tyreSizes);
    },
  });
};
