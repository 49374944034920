import { Form, Input, Button, Row, Col, message } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import useT from '@/hooks/useT';
import { loginMutation } from '@/queries/auth.queries';
import { AuthToken } from '@/types/Api';

type FormData = {
  username: string;
  password: string;
};

const Login: React.FC = () => {
  const loginMutator = loginMutation();
  const t = useT();
  const onFinish = async (values: FormData) => {
    try {
      //TODO remove as AuthToken
      await loginMutator.mutateAsync(values as AuthToken);
    } catch (err) {
      message.error(t('loginScreen.loginError'));
    }
  };

  return (
    <>
      <Helmet>
        <title>Noweopony.pl - login</title>
      </Helmet>
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Col xl={8}>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label={t('loginScreen.usernameLabel')}
              name="username"
              rules={[{ required: true, message: t('loginScreen.usernameError') }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('loginScreen.passwordLabel')}
              name="password"
              rules={[{ required: true, message: t('loginScreen.passwordError') }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                {t('loginScreen.loginButton')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default Login;
