import { Affix, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import useT from '@/hooks/useT';
import { Routes } from '@/types/Routes.types';

const { SubMenu } = Menu;

interface Props {
  collapsed: boolean;
}

const AppLayoutMenu: React.FC<Props> = ({ collapsed }) => {
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const location = useLocation();
  const t = useT();

  useEffect(() => {
    if (!collapsed) {
      setOpenKeys(['attributes', 'terms', 'prices', 'pricing']);
    }
  }, [collapsed]);
  return (
    <Affix>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[location.pathname]}
        openKeys={openKeys}
        onOpenChange={setOpenKeys}
      >
        <SubMenu key="attributes" title={t('title.attributes')}>
          <Menu.Item key={Routes.attributesMapping}>
            <Link to={Routes.attributesMapping}>
              <FormattedMessage id="title.attributes.mapping" />
            </Link>
          </Menu.Item>
          <Menu.Item key={Routes.attributesProducers}>
            <Link to={Routes.attributesProducers}>
              <FormattedMessage id="title.attributes.producers" />
            </Link>
          </Menu.Item>
          <Menu.Item key={Routes.attributesSizes}>
            <Link to={Routes.attributesSizes}>
              <FormattedMessage id="title.attributes.sizes" />
            </Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu key="terms" title={t('title.terms')}>
          <Menu.Item key={Routes.terms}>
            <Link to={Routes.terms}>
              <FormattedMessage id="title.terms.terms" />
            </Link>
          </Menu.Item>
          <Menu.Item key={Routes.termsDiscountLevels}>
            <Link to={Routes.termsDiscountLevels}>
              <FormattedMessage id="title.terms.discountLevels" />
            </Link>
          </Menu.Item>
          <Menu.Item key={Routes.termsPriceLists}>
            <Link to={Routes.termsPriceLists}>
              <FormattedMessage id="title.terms.priceLists" />
            </Link>
          </Menu.Item>
          <Menu.Item key={Routes.termsInvoices}>
            <Link to={Routes.termsInvoices}>
              <FormattedMessage id="title.terms.invoices" />
            </Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu key="prices" title={t('title.prices')}>
          <Menu.Item key={Routes.priceLevels}>
            <Link to={Routes.priceLevels}>
              <FormattedMessage id="title.prices.priceLevels" />
            </Link>
          </Menu.Item>
          <Menu.Item key={Routes.priceChannels}>
            <Link to={Routes.priceChannels}>
              <FormattedMessage id="title.prices.channels" />
            </Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu key="pricing" title={t('title.pricing')}>
          <Menu.Item key={Routes.pricingMerchantPanel}>
            <Link to={Routes.pricingMerchantPanel}>
              <FormattedMessage id="title.pricing.merchantPanel" />
            </Link>
          </Menu.Item>
          <Menu.Item key={Routes.pricingAnalysis}>
            <Link to={Routes.pricingAnalysis}>
              <FormattedMessage id="title.pricing.analysis" />
            </Link>
          </Menu.Item>
          <Menu.Item key={Routes.pricingAnalysisSize}>
            <Link to={Routes.pricingAnalysisSize}>
              <FormattedMessage id="title.pricing.analysisSize" />
            </Link>
          </Menu.Item>
          <Menu.Item key={Routes.pricingOrders}>
            <Link to={Routes.pricingOrders}>
              <FormattedMessage id="title.pricing.orders" />
            </Link>
          </Menu.Item>
          <Menu.Item key={Routes.pricingCurrentProfit}>
            <Link to={Routes.pricingCurrentProfit}>
              <FormattedMessage id="title.pricing.currentProfit" />
            </Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Affix>
  );
};

export default AppLayoutMenu;
