import { message, Modal, Typography } from 'antd';
import React from 'react';

import { FONT_SIZE } from '@/constants/sizes.constatns';
import useT from '@/hooks/useT';
import { patchAttributeMapMutation } from '@/queries/attributesMap.queries';
import { AttributeMap } from '@/types/Api';
import { formatError } from '@/utils/error.utils';
const { Text } = Typography;
type Props = {
  attribute: AttributeMap | null;
  close: () => void;
};

const MapToEmptyAttributeValueModal: React.FC<Props> = ({ attribute, close }) => {
  const t = useT();

  const updateAttributeMapMutator = patchAttributeMapMutation();

  const updateAttributeMap = async () => {
    try {
      await updateAttributeMapMutator.mutateAsync({
        id: attribute?.id as number,
        data: {
          is_blank: true,
        },
      });
      message.success(t('attributesMapping.successMessage', { value: attribute?.name || '' }));
      close();
    } catch (err) {
      message.error(formatError(err));
    }
  };

  return (
    <Modal
      visible={!!attribute}
      onCancel={close}
      onOk={() => updateAttributeMap()}
      okText={t('button.yes')}
      cancelText={t('button.no')}
      title={t('attributesMappingToBlankModal.header')}
    >
      <div>
        <Text style={{ fontSize: FONT_SIZE.l }}>{t('attributesMappingToBlankModal.text')}</Text>
      </div>
    </Modal>
  );
};

export default MapToEmptyAttributeValueModal;
