import QS from 'query-string';

import { REACT_APP_API_URL } from '@/constants/config.constants';
import { getTokenCookie } from '@/services/cookie.service';
import { Api } from '@/types/Api';

const API = new Api({
  baseURL: REACT_APP_API_URL,
  paramsSerializer: (params) => QS.stringify(params),
});
export const getAuthHeader = (token: string): string => `Token ${token}`;

API.instance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response),
);

API.instance.interceptors.request.use(
  (config) => {
    const token = getTokenCookie();
    const newConfig = { ...config };
    if (config.url?.split('/').includes('login') && config.method === 'post') {
      return newConfig;
    }
    if (token) {
      newConfig.headers.Authorization = getAuthHeader(token);
    }
    return newConfig;
  },
  (error) => Promise.reject(error.response.data),
);

export { API };

// === REQUEST INTERCEPTOR ===

// === RESPONSE INTERCEPTOR ===
// https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f

// let isRefreshing = false;
// let failedQueue = [];

// const processQueue = (error, token = null) => {
//   failedQueue.forEach((prom) => {
//     if (error) {
//       prom.reject(error);
//     } else {
//       prom.resolve(token);
//     }
//   });

//   failedQueue = [];
// };

// axiosApiInstance.interceptors.response.use(
//   (response) => response.data,
//   (error) => {
//     const isRefreshTokenRequest = error?.config?.url === '/refresh-token';
//     const originalRequest = error.config;
//     if (error.response?.status === 401 && !isRefreshTokenRequest && !originalRequest._retry) {
//       if (isRefreshing) {
//         return new Promise((resolve, reject) => failedQueue.push({ resolve, reject }))
//           .then((token) => {
//             originalRequest.headers.Authorization = getAuthHeader(token);
//             return axiosApiInstance(originalRequest);
//           })
//           .catch((err) => Promise.reject(err));
//       }

//       originalRequest._retry = true;
//       isRefreshing = true;
//       return new Promise((resolve, reject) => {
//         axios
//           .post(
//             `${PUBLIC_API_URL}/auth/refresh-token`,
//             {},
//             {
//               withCredentials: true,
//             }
//           )
//           .then(({ data }) => {
//             const { token } = data;
//             setAccessToken(token);
//             originalRequest.headers.Authorization = getAuthHeader(token);
//             processQueue(null, token);
//             resolve(axiosApiInstance(originalRequest));
//           })
//           .catch((err) => {
//             processQueue(err, null);
//             reject(err);
//           })
//           .then(() => {
//             isRefreshing = false;
//           });
//       });
//     }
//     return Promise.reject(error.response);
//   }
// );
