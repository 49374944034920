import React from 'react';

import { updateWarehouseOrderProductMutation } from '@/queries/warehouse.queries';

import CommentCell from './CommentCell';

type Props = {
  ean: string;
  orderId: number;
  comment: string;
};

const Comment: React.FC<Props> = ({ ean, orderId, comment }) => {
  const updateWarehouseOrderProductMutator = updateWarehouseOrderProductMutation();
  const onChangeComment = async (newComment: string) => {
    await updateWarehouseOrderProductMutator.mutate({
      ean,
      orderId,
      data: {
        comment: newComment,
      },
    });
  };

  return (
    <CommentCell
      value={comment}
      onChange={onChangeComment}
      error={!!updateWarehouseOrderProductMutator.error}
    />
  );
};

export default Comment;
