import React from 'react';

import CustomTable from '@/components/CustomTable';
import useTable from '@/hooks/useTable';
import { getPricingShopVariantsQuery } from '@/queries/pricing.queries';

import useEshopColumns from './useEshopColumns';
type Props = {
  season: string;
  size: string;
  producer: string;
};

const EshopTable: React.FC<Props> = ({ season, producer, size }) => {
  const eshopColumns = useEshopColumns();
  const { pagination, params } = useTable({
    limit: 20,
  });
  const shopVariants = getPricingShopVariantsQuery({ season, producer, size, ...params });

  return (
    <CustomTable
      small
      data={shopVariants.data}
      columns={eshopColumns}
      isLoading={shopVariants.isLoading || shopVariants.isFetching}
      scroll={{ y: 1000 }}
      pagination={pagination}
      tableLayout="auto"
    />
  );
};
export default EshopTable;
