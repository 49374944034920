import { Checkbox, InputNumber, Select } from 'antd';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDebounce } from 'use-debounce/lib';

import useT from '@/hooks/useT';
import { getChannelsQuery } from '@/queries/core.queries';
import { calculateProfitMutation } from '@/queries/pricing.queries';

import style from './Calculator.style.module.less';

const Calculator: React.FC = () => {
  const t = useT();
  const [channelId, setChannelId] = useState<number | null>(null);
  const [netPrice, setNetPrice] = useState<number | undefined>();
  const [price, setPrice] = useState<number | undefined>();
  const [promoted, setPromoted] = useState(false);
  const [debouncedNetPrice] = useDebounce(netPrice, 300);
  const [debouncedPrice] = useDebounce(price, 300);

  const calculateProfitMutator = calculateProfitMutation();
  const channels = getChannelsQuery();
  useEffect(() => {
    if (channels.data?.length && channels.data?.length > 0) {
      setChannelId(channels.data[0].id);
    }
  }, [channels.data]);

  const calculateProfit = async () => {
    if (!channelId) {
      return;
    }
    calculateProfitMutator.mutate({
      channel_id: channelId,
      net_price: debouncedNetPrice ? String(debouncedNetPrice) : undefined,
      price: debouncedPrice ? String(debouncedPrice) : undefined,
      promoted,
    });
  };

  useEffect(() => {
    calculateProfit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId, debouncedNetPrice, debouncedPrice, promoted]);

  return (
    <div
      className={cx({
        [style.calculator]: true,
        [style.calculatorError]: calculateProfitMutator.isError,
      })}
    >
      <Select value={channelId} onChange={setChannelId} placement="topLeft">
        {channels.data?.map((c) => (
          <Select.Option value={c.id}>{c.name}</Select.Option>
        ))}
      </Select>
      <InputNumber
        placeholder={t('calculator.netPriceLabel')}
        value={netPrice}
        onChange={setNetPrice}
        className={style.input}
        step={1}
        min={0}
        precision={2}
        parser={(v) => {
          return v ? Number(v?.replaceAll(',', '.')) : Number.NaN;
        }}
      />
      <InputNumber
        placeholder={t('calculator.priceLabel')}
        value={price}
        onChange={setPrice}
        className={style.input}
        step={1}
        min={0}
        precision={2}
        parser={(v) => {
          return v ? Number(v?.replaceAll(',', '.')) : Number.NaN;
        }}
      />
      <div>
        <Checkbox onChange={(e) => setPromoted(e.target.checked)} value={promoted}>
          <span className={style.white}>
            <FormattedMessage id="calculator.promoted" />
          </span>
        </Checkbox>
      </div>
      <div className={style.summary}>
        <div className={style.white}>
          <FormattedMessage id="calculator.profit" />
          <strong>{calculateProfitMutator.data?.data.unit_profit}</strong>
        </div>
        <div className={style.white}>
          <FormattedMessage id="calculator.provision" />
          <strong>{calculateProfitMutator.data?.data.unit_provision}</strong>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
