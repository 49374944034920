import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';

import AttributesMapTable from '@/components/AttributesMapTable';
import useT from '@/hooks/useT';
import { AttributeMappingTableType } from '@/types/Mapping.types';

const AttributesMapping: React.FC = () => {
  const t = useT();
  return (
    <>
      <Helmet>
        <title>{t('title.attributes.mapping')}</title>
      </Helmet>
      <div>
        <h2>
          <FormattedMessage id="attributesMapping.toMapHeader" />
        </h2>
        <AttributesMapTable tableType={AttributeMappingTableType.TO_MAP} />

        <h2>
          <FormattedMessage id="attributesMapping.mappedHeader" />
        </h2>
        <AttributesMapTable tableType={AttributeMappingTableType.MAPPED} />
        <h2>
          <FormattedMessage id="attributesMapping.mappedAsEmptyHeader" />
        </h2>
        <AttributesMapTable tableType={AttributeMappingTableType.MAPPED_AS_EMPTY} />
        <h2>
          <FormattedMessage id="attributesMapping.softDeletedHeader" />
        </h2>
        <AttributesMapTable tableType={AttributeMappingTableType.SOFT_DELETED} />
      </div>
    </>
  );
};
export default AttributesMapping;
