import { Button, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';

import SelectSource from '@/components/SelectSource';
import useModal from '@/hooks/useModal';
import useT from '@/hooks/useT';
import { Discount, DiscountLevel } from '@/types/Api';

import TermsDiscountAddEditTermModal from './TermsDiscountAddEditTermModal';
import TermsDiscountLevelDiscountsList from './TermsDiscountLevelDiscountsList';
import TermsDiscountLevelsAddEditModal from './TermsDiscountLevelsAddEditModal';
import TermsDiscountLevelsList from './TermsDiscountLevelsList';
const { Text } = Typography;

const TermsDiscountLevels: React.FC = () => {
  const addEditDiscountLevelModal = useModal();
  const addEditCopyDiscountTermModal = useModal();
  const t = useT();
  const [selectedSource, setSelectedSource] = useState<string | undefined>(undefined);
  const [discountLevel, setDiscountLevel] = useState<DiscountLevel | null>(null);
  const [editedDiscountLevel, setEditedDiscountLevel] = useState<DiscountLevel | null>(null);
  const [discount, setDiscount] = useState<(Discount & { id?: number }) | null>(null);
  const [shouldEditDiscount, setShouldEditDiscount] = useState<boolean>(false);

  // Discount Modal
  const openAddEditCopyDiscountModal = (d: Discount, shouldEdit = false) => {
    setShouldEditDiscount(shouldEdit);
    setDiscount(d);
    addEditCopyDiscountTermModal.open();
  };

  const closeEditCopyDiscountModal = () => {
    setShouldEditDiscount(false);
    setDiscount(null);
    addEditCopyDiscountTermModal.close();
  };

  // Discount Level Modal
  const openEditDiscountLevelModal = (d: DiscountLevel) => {
    setEditedDiscountLevel(d);
    addEditDiscountLevelModal.open();
  };

  const closeEditDiscountLevelModal = () => {
    setEditedDiscountLevel(null);
    addEditDiscountLevelModal.close();
  };

  useEffect(() => {
    setDiscountLevel(null);
  }, [selectedSource]);

  return (
    <>
      <Helmet>
        <title>{t('title.terms.discountLevels')}</title>
      </Helmet>
      <Space direction="vertical" style={{ width: '100%' }} size={20}>
        <SelectSource value={selectedSource} onChange={setSelectedSource} />
        <Button type="primary" onClick={addEditDiscountLevelModal.open}>
          <FormattedMessage id="termsDiscountLevel.addDiscountLevel" />
        </Button>
        <TermsDiscountLevelsList
          source={selectedSource}
          setDiscountLevel={setDiscountLevel}
          editDiscountLevel={openEditDiscountLevelModal}
          discountLevelId={discountLevel?.id}
        />

        {discountLevel?.id && (
          <>
            <Space>
              <Button type="primary" onClick={addEditCopyDiscountTermModal.open}>
                <FormattedMessage id="termsDiscountLevel.addDiscountTerm" />
              </Button>
              <Text>
                {discountLevel.name}
                <Text type="secondary">{` (${discountLevel.source}) `}</Text>
              </Text>
            </Space>
            <TermsDiscountLevelDiscountsList
              discountLevelId={discountLevel.id}
              openAddEditCopyDiscountModal={openAddEditCopyDiscountModal}
            />
          </>
        )}
      </Space>
      {addEditDiscountLevelModal.visible && (
        <TermsDiscountLevelsAddEditModal
          {...addEditDiscountLevelModal}
          close={closeEditDiscountLevelModal}
          discountLevel={editedDiscountLevel}
        />
      )}
      {addEditCopyDiscountTermModal.visible && (
        <TermsDiscountAddEditTermModal
          {...addEditCopyDiscountTermModal}
          close={closeEditCopyDiscountModal}
          discountLevelId={discountLevel?.id as number}
          discount={discount}
          edit={shouldEditDiscount}
        />
      )}
    </>
  );
};
export default TermsDiscountLevels;
