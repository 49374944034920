import cx from 'classnames';
import React, { useState } from 'react';

import CustomTable from '@/components/CustomTable';
import useTable from '@/hooks/useTable';
import { getPricingProductsQuery } from '@/queries/pricing.queries';
import { PricingProductVariant, ProductPricing } from '@/types/Api';

import styles from './PricingProductsTable.style.module.less';
import PricingProductVariantModal from './PricingProductVariantModal';
import usePricingProductsColumns from './usePricingProductsColumns';

type Props = {
  season: string;
  size: string;
  producer: string;
};

export type ProductPricingParentRow = ReturnType<typeof mapPricingProductsData>[0];
export type ProductPricingVariantRow = ProductPricingParentRow['children'][0];
export type ProductPricingRow = ProductPricingParentRow | ProductPricingVariantRow;

const mapPricingProductsData = (products: ProductPricing[] = []) =>
  products.map((product) => {
    const attributes = product.attributes;
    return {
      type: 'PARENT' as const,
      id: product.id,
      producer: attributes.producer,
      size: attributes.size,
      tread: attributes.tread,
      loadIndex: attributes.load_index,
      speedIndex: attributes.speed_index,
      labelFuel: attributes.label_fuel,
      l2: attributes.label_break,
      l3: attributes.label_sound_decibels,
      producer_qty_and_qty: product.producer_quantity,
      preorder_and_net_price: product.preorder,
      current_and_price: product.current,
      ean_and_strategy: product.ean,
      salesAll: product.sales_all,
      sales_30_60: product.sales_30_60,
      sales_14_30: product.sales_14_30,
      sales_7_14: product.sales_7_14,
      sales_3_7: product.sales_3_7,
      sales_0_3: product.sales_0_3,
      channel_name: product.code,
      link: product.link,
      seller: null,
      raw: product,
      children: product.variants.map((variant) => {
        const variantAttributes = variant.attributes;
        const channelVariantStrategyId = variant.channel_variant_strategy
          ? variant.channel_variant_strategy.id
          : '';
        return {
          type: 'VARIANT' as const,
          id: variant.id,
          seller: variant.source?.name ? variant.source.name : variant?.seller,
          productionYear: variantAttributes.production_year,
          producer_qty_and_qty: variant.quantity,
          reservation: variant.reservation,
          availability: variant.availability,
          l2: channelVariantStrategyId,
          channel_variant_strategy: channelVariantStrategyId,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          l3: variant.strategy_id,
          strategy: variant.strategy,
          salesAll: variant.sales_all,
          sales_30_60: variant.sales_30_60,
          sales_14_30: variant.sales_14_30,
          sales_7_14: variant.sales_7_14,
          sales_3_7: variant.sales_3_7,
          sales_0_3: variant.sales_0_3,
          preorder_and_net_price: variant.net_price,
          current_and_price: variant.price,
          channel_name: variant.channel?.name,
          unit_profit: variant.unit_profit,
          ean_and_strategy: Array.from(new Set([variant.strategy, variant.used_strategy]))
            .filter(Boolean)
            .join('/'),
          sku: variant.sku,
          raw: variant,
          productRaw: product,
        };
      }),
    };
  });

const getRowClassName = (record: ProductPricingRow) => {
  return cx({
    [styles.parent]: record.type === 'PARENT',
    [styles.noweopony]: record.seller === 'noweopony',
    [styles.noweopony_channel_variant]: record.seller === 'noweopony' && !!record.channel_name,
  });
};

const PricingProductsTable: React.FC<Props> = ({ season, producer, size }) => {
  const [productWithVariant, setProductWithVariant] = useState<{
    product: ProductPricing;
    variant: PricingProductVariant;
  } | null>(null);

  const { pagination, params } = useTable({
    limit: 100,
  });

  const openProductVariantModal = (product: ProductPricing, variant: PricingProductVariant) =>
    setProductWithVariant({
      product,
      variant,
    });
  const closeProductVariantModal = () => setProductWithVariant(null);

  const products = getPricingProductsQuery({ season, producer, size, ...params });
  const results = mapPricingProductsData(products.data?.results);
  const productsColumns = usePricingProductsColumns({
    openProductVariantModal,
  });

  const resultsIds = results?.map((r) => r.id);

  return (
    <>
      <CustomTable
        small
        expandable={{
          expandedRowKeys: resultsIds,
          expandIconColumnIndex: -1,
        }}
        data={{
          ...products.data,
          results: results,
        }}
        columns={productsColumns}
        isLoading={products.isLoading || products.isFetching}
        scroll={{ y: 1000 }}
        pagination={pagination}
        rowClassName={getRowClassName}
        tableLayout="auto"
      />
      <PricingProductVariantModal
        close={closeProductVariantModal}
        productWithVariant={productWithVariant}
      />
    </>
  );
};
export default PricingProductsTable;
