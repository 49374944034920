import useT from '@/hooks/useT';

export const productsTableColumns = [];

const useProducersColumns = () => {
  const t = useT();
  return [
    {
      title: t('tyreProducersTable.nameLabel'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('tyreProducersTable.classLabel'),
      dataIndex: 'tyre_class',
      key: 'tyre_class',
    },
    {
      title: t('tyreProducersTable.warrantyLabel'),
      dataIndex: 'warranty',
      key: 'warranty',
    },
  ];
};

export default useProducersColumns;
