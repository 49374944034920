import { Button } from 'antd';
import { useMemo } from 'react';
import { FormattedDate } from 'react-intl';

import { Term } from '@/types/Api';

type Props = {
  termId?: number;
  setTermId: (termId: number | undefined) => void;
};
const useTermsInvoicesColumns = ({ setTermId, termId }: Props) => {
  const columns = useMemo(
    () => [
      {
        title: 'Szczegóły',
        key: 'details',
        render: (text: string, record: Term) => (
          <Button
            size="small"
            type="primary"
            onClick={() => setTermId(termId !== record.id ? record.id : undefined)}
            danger={record.id === termId}
          >
            Szczegóły
          </Button>
        ),
      },
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Source',
        dataIndex: ['source', 'name'],
        key: 'source',
      },
      {
        title: 'Nazwa',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Opis',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Rok',
        dataIndex: ['year', 'name'],
        key: 'year',
      },
      {
        title: 'Is Preorder',
        dataIndex: 'is_preorder',
        key: 'is_preorder',

        render: (isPreorder: boolean) => <span>{isPreorder ? '✅' : '🚫'}</span>,
      },
      {
        title: 'Is Current',
        dataIndex: 'is_current',
        key: 'is_current',
        render: (isCurrent: boolean) => <span>{isCurrent ? '✅' : '🚫'}</span>,
      },
      {
        title: 'Updated',
        dataIndex: 'updated_at',
        key: 'updatedAt',
        render: (text: string) => <FormattedDate value={text} />,
      },
      {
        title: 'Created',
        dataIndex: 'created_at',
        key: 'createdAt',
        render: (text: string) => <FormattedDate value={text} />,
      },
    ],
    [termId, setTermId],
  );
  return columns;
};

export default useTermsInvoicesColumns;
