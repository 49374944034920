import React from 'react';

import CustomTable from '@/components/CustomTable';
import { getDiscountLevelsDiscounts } from '@/queries/discountLevels.queries';
import { Discount } from '@/types/Api';
import { TyreSeason } from '@/types/Tyre.types';

import useDiscountLevelConditionsColumns from './useDiscountLevelDiscountsColumns';

type Props = {
  discountLevelId: number;
  openAddEditCopyDiscountModal: (discount: Discount, shouldEdit?: boolean) => void;
};

const tyreSeasonToRowClass = {
  [TyreSeason.allSeason]: 'discountTable__allYearRow',
  [TyreSeason.summer]: 'discountTable__summerRow',
  [TyreSeason.winter]: 'discountTable__winterRow',
};

const getRowClassName = (record: Discount) => {
  const seasonCondition = record.conditions?.find((condition) => condition.attribute === 'season');
  if (seasonCondition && seasonCondition.attribute_value) {
    return tyreSeasonToRowClass[seasonCondition.attribute_value as TyreSeason] || '';
  }
  return '';
};

const TermsDiscountLevelDiscountsList: React.FC<Props> = ({
  discountLevelId,
  openAddEditCopyDiscountModal,
}) => {
  const columns = useDiscountLevelConditionsColumns(openAddEditCopyDiscountModal, discountLevelId);

  const { data, isLoading } = getDiscountLevelsDiscounts(discountLevelId);

  return (
    <CustomTable
      columns={columns}
      data={{
        results: data,
      }}
      rowClassName={getRowClassName}
      isLoading={isLoading}
    />
  );
};

export default TermsDiscountLevelDiscountsList;
