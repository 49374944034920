import { Checkbox, Form, Input, message, Modal } from 'antd';
import React from 'react';

import useT from '@/hooks/useT';
import { createTyreSizeMutation } from '@/queries/attributesTyre.query';
import { Size } from '@/types/Api';
import { formatError } from '@/utils/error.utils';

type Props = {
  visible?: boolean;
  setVisible: (visible: boolean) => void;
};

const AddSizeModal: React.FC<Props> = ({ visible = false, setVisible }) => {
  const t = useT();
  const [form] = Form.useForm();
  const createTyreSizeMutator = createTyreSizeMutation();

  const saveSize = async (values: Size) => {
    try {
      await createTyreSizeMutator.mutateAsync(values);
      message.success(t('addSizeModal.successMessage'));
      setVisible(false);
    } catch (err) {
      message.error(formatError(err));
    }
  };

  const handleOk = async () => {
    const values = await form.validateFields();
    saveSize(values);
  };

  const handleCancel = () => setVisible(false);
  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      confirmLoading={createTyreSizeMutator.isLoading}
      onCancel={handleCancel}
      okText={t('button.save')}
      cancelText={t('button.cancel')}
    >
      <Form form={form} layout="vertical" name="basic" autoComplete="off">
        <Form.Item
          label={t('addSizeModal.sizeLabel')}
          name="size"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('addSizeModal.widthLabel')}
          name="width"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('addSizeModal.profileLabel')}
          name="profile"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('addSizeModal.rimLabel')}
          name="rim"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('addSizeModal.loadIndexLabel')}
          name="load_index"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={t('addSizeModal.xlLabel')} name="xl" valuePropName="checked">
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddSizeModal;
