import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, message, Modal, Radio, Row } from 'antd';
import React, { useState } from 'react';

import useT from '@/hooks/useT';
import { getAttributeQuery } from '@/queries/attributes.queries';
import {
  createDiscountLevelDiscountMutation,
  editDiscountLevelDiscountMutation,
} from '@/queries/discountLevels.queries';
import { Discount, DiscountTypeEnum } from '@/types/Api';
import { formatError } from '@/utils/error.utils';

import TermsDiscountAddTermConditionRow from './TermsDiscountAddTermConditionRow';

type Props = {
  close: () => void;
  discountLevelId: number;
  discount: (Discount & { id?: number }) | null;
  edit?: boolean;
};

const getTitleId = (discount?: Discount | null) => {
  if (discount?.id) {
    return 'addEditDiscountTermModal.titleEdit';
  }
  if (discount) {
    return 'addEditDiscountTermModal.titleCopy';
  }
  return 'addEditDiscountTermModal.titleAdd';
};

const TermsDiscountAddEditTermModal: React.FC<Props> = ({
  close,
  discountLevelId,
  discount,
  edit = false,
}) => {
  const titleId = getTitleId(discount);
  const t = useT();
  const [discountType, setDiscountType] = useState(discount?.discount_type || null);
  const [form] = Form.useForm();
  const { data: attributes = [] } = getAttributeQuery();

  const createDiscountLevelDiscountMutator = createDiscountLevelDiscountMutation();
  const editDiscountLevelDiscountMutator = editDiscountLevelDiscountMutation();

  const saveDiscountLevel = async () => {
    const values = await form.validateFields();
    const action = edit ? editDiscountLevelDiscountMutator : createDiscountLevelDiscountMutator;

    try {
      await action.mutateAsync({
        discount: {
          ...values,
          value: Number(values.value),
          conditions: values.conditions || [],
        },
        discountId: discount?.id as number,
        discountLevelId,
      });
      message.success(t('addEditDiscountTermModal.successMessage'));
      close();
    } catch (err) {
      message.error(formatError(err));
    }
  };
  const resetAttributeValue = (index: number) => {
    const conditions = form.getFieldValue('conditions');
    conditions[index].attribute_value_id = undefined;
    form.setFieldsValue({
      conditions,
    });
  };

  return (
    <Modal
      title={t(titleId)}
      visible
      onOk={saveDiscountLevel}
      confirmLoading={createDiscountLevelDiscountMutator.isLoading}
      onCancel={close}
      okText={t('button.save')}
      cancelText={t('button.cancel')}
    >
      <Form
        form={form}
        layout="vertical"
        name="basic"
        autoComplete="off"
        initialValues={{
          name: discount?.name,
          description: discount?.description,
          discount_type: discount?.discount_type,
          value: discount?.value,
          is_aftersale: discount?.is_aftersale,
          conditions: discount?.conditions
            ? discount.conditions.map((d) => ({
                attribute_id: d.attribute_id,
                attribute_value_id: d.attribute_value_id,
              }))
            : [],
        }}
      >
        {/* Name */}
        <Form.Item
          label={t('addEditDiscountTermModal.nameLabel')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        {/* Description */}
        <Form.Item label={t('addEditDiscountTermModal.descriptionLabel')} name="description">
          <Input />
        </Form.Item>

        {/* DiscountType */}
        <Row gutter={16}>
          <Col>
            <Form.Item
              name="discount_type"
              label={t('addEditDiscountTermModal.discountTypeLabel')}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group buttonStyle="solid" onChange={(e) => setDiscountType(e.target.value)}>
                <Radio.Button value="pln">PLN</Radio.Button>
                <Radio.Button value="percent">﹪</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label={t('addEditDiscountTermModal.discountValueLabel')}
              name="value"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                addonAfter={(() => {
                  if (discountType === DiscountTypeEnum.Percent) {
                    return '%';
                  }
                  if (discountType === DiscountTypeEnum.Pln) {
                    return 'pln';
                  }
                  return '';
                })()}
                step={discountType === DiscountTypeEnum.Percent ? 0.01 : undefined}
                max={discountType === DiscountTypeEnum.Percent ? 1 : undefined}
                parser={(v) => {
                  return v ? Number(v?.replaceAll(',', '.')) : Number.NaN;
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Presale */}
        <Form.Item
          name="is_aftersale"
          label={t('addEditDiscountTermModal.preSaleLabel')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={false}>{t('preSale.no')}</Radio.Button>
            <Radio.Button value={true}>{t('preSale.yes')}</Radio.Button>
          </Radio.Group>
        </Form.Item>

        {/* Conditions */}
        <Form.Item label={t('addEditDiscountTermModal.conditionsLabel')}>
          <Form.List
            name="conditions"
            rules={[
              {
                validator: async (
                  _,
                  conditions: {
                    attribute_id: number;
                    attribute_value_id: number;
                  }[],
                ) => {
                  const attributesIds = conditions
                    .map((c) => c?.attribute_id)
                    .filter((v) => v !== undefined);
                  if (new Set(attributesIds).size !== attributesIds.length) {
                    return Promise.reject(
                      new Error(t('addEditDiscountTermModal.conditionsDuplicatedError')),
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <TermsDiscountAddTermConditionRow
                    key={field.key}
                    field={field}
                    remove={remove}
                    attributes={attributes}
                    reset={resetAttributeValue}
                    initAttributeId={
                      discount?.conditions ? discount.conditions[index]?.attribute_id : undefined
                    }
                  />
                ))}
                <Form.Item>
                  <Form.ErrorList errors={errors} />
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    {t('addEditDiscountTermModal.conditionsAdd')}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TermsDiscountAddEditTermModal;
