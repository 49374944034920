import { Form, Input, message, Modal, Radio, Select } from 'antd';
import React from 'react';

import useT from '@/hooks/useT';
import { getSourcesQuery, getYearsQuery } from '@/queries/core.queries';
import {
  createDiscountLevelMutation,
  editDiscountLevelMutation,
} from '@/queries/discountLevels.queries';
import { DiscountLevel } from '@/types/Api';
import { formatError } from '@/utils/error.utils';

type Props = {
  close: () => void;
  discountLevel: DiscountLevel | null;
};

const TermsDiscountLevelsAddEditModal: React.FC<Props> = ({ close, discountLevel }) => {
  const t = useT();
  const [form] = Form.useForm();
  const { sources } = getSourcesQuery();
  const { data: years } = getYearsQuery();
  const createDiscountLevelMutator = createDiscountLevelMutation();
  const editDiscountLevelMutator = editDiscountLevelMutation();

  const saveDiscountLevel = async () => {
    const values = await form.validateFields();
    const action = discountLevel ? editDiscountLevelMutator : createDiscountLevelMutator;
    try {
      await action.mutateAsync({ ...values, id: discountLevel?.id });
      message.success(t('addDiscountLevelModal.successMessage'));
      close();
    } catch (err) {
      message.error(formatError(err));
    }
  };
  return (
    <Modal
      visible
      onOk={saveDiscountLevel}
      confirmLoading={createDiscountLevelMutator.isLoading}
      onCancel={close}
      okText={t('button.save')}
      cancelText={t('button.cancel')}
    >
      <Form
        form={form}
        layout="vertical"
        name="basic"
        autoComplete="off"
        initialValues={{
          name: discountLevel?.name,
          description: discountLevel?.description,
          source: discountLevel?.source,
          year: discountLevel?.year,
        }}
      >
        {/* Name */}
        <Form.Item
          label={t('addDiscountLevelModal.nameLabel')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        {/* Description */}
        <Form.Item label={t('addDiscountLevelModal.descriptionLabel')} name="description">
          <Input />
        </Form.Item>

        {/* Source */}
        <Form.Item
          name="source"
          label={t('addDiscountLevelModal.sourceLabel')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            {sources.map((source) => (
              <Select.Option key={source.name} value={source.name}>
                {source.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {/* Year */}
        <Form.Item
          label={t('addDiscountLevelModal.yearLabel')}
          name="year"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group buttonStyle="solid">
            {(years || []).reverse().map((year) => (
              <Radio.Button key={year.id} value={year.name}>
                {year.name}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TermsDiscountLevelsAddEditModal;
