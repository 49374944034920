export enum QueryKeys {
  me = 'me',
  producers = 'producers',
  tyreProducerTreads = 'tyreProducerTreads',
  tyreSizes = 'tyreSizes',
  attributes = 'attributes',
  attributesValues = 'attributesValues',
  attributesMap = 'attributesMap',
  sources = 'sources',
  years = 'years',
  channels = 'channels',
  discountLevels = 'discountLevels',
  discountLevelsDiscounts = 'discountLevelsDiscounts',
  ordersSales = 'ordersSales',
  pricingProducersSummary = 'pricingProducersSummary',
  pricingSizesSummary = 'pricingSizesSummary',
  pricingAllegroVariants = 'pricingAllegroVariants',
  pricingProducts = 'pricingProducts',
  terms = 'terms',
  invoicesPurchase = 'invoicesPurchase',
  pricingChannelVariantStrategy = 'pricingChannelVariantStrategy',
  pricingShopVariants = 'pricingShopVariants',
  tyreClasses = 'tyreClasses',
  pricingProductsBySize = 'pricingProductsBySize',
  warehouseOrderSizes = 'warehouseOrderSizes',
  warehouseOrderProducers = 'warehouseOrderProducers',
  warehouseOrder = 'warehouseOrder',
  warehouseOrders = 'warehouseOrders',
  warehouseOrderProductsBySize = 'warehouseOrderProductsBySize',
}
