import { InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';

import styles from './PriceCell.style.module.less';
const PriceCell: React.FC<{ value: number; onChange: (v: number) => void; error?: boolean }> = ({
  value,
  onChange,
  error = false,
}) => {
  const [localValue, setLocalValue] = useState(value);
  const onClickOk = () => onChange(localValue);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const getStatus = () => {
    if (error) {
      return 'error';
    }
    if (localValue !== value) {
      return 'warning';
    }
  };

  return (
    <div className={styles.wrapper}>
      <InputNumber
        value={localValue}
        controls={false}
        onChange={setLocalValue}
        precision={2}
        status={getStatus()}
        parser={(v) => {
          return v ? Number(v?.replaceAll(',', '.')) : Number.NaN;
        }}
      />
      <button className={styles.button} onClick={onClickOk}>
        OK
      </button>
    </div>
  );
};

export default PriceCell;
