import { Table, TableProps } from 'antd';
import cx from 'classnames';
import React from 'react';

import styles from './CustomTable.module.less';

type Props<T> = TableProps<T> & {
  isLoading?: boolean;
  small?: boolean;
  data?: {
    results?: T[];
    count?: number;
  };
  pagination?: {
    pageSize?: number;
    current: number;
    onChangePagination: (pagination: { pageSize?: number; current?: number }) => void;
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
const CustomTable = <T extends object>({
  columns = [],
  data,
  isLoading = false,
  pagination,
  small = false,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/ban-types

Props<T>) => {
  const { results, count } = data || { results: [], count: 0 };
  return (
    <Table
      className={cx(styles.table, { [styles.table_small]: small })}
      size="small"
      rowKey={rest.rowKey || 'id'}
      loading={isLoading}
      columns={columns}
      dataSource={results}
      bordered
      scroll={{ x: true }}
      pagination={
        pagination
          ? {
              total: count,
              pageSize: pagination.pageSize,
              current: pagination.current,
            }
          : false
      }
      onChange={pagination?.onChangePagination}
      {...rest}
    />
  );
};

export default CustomTable;
