import { LinkOutlined } from '@ant-design/icons';
import { useMemo } from 'react';

import ProfitCell from '@/components/ProfitCell';

const useEshopColumns = () => {
  return useMemo(
    () => [
      {
        title: 'Bieżnik',
        dataIndex: ['product', 'attributes', 'tread'],
        key: 'tread',
      },
      {
        title: 'LI',
        dataIndex: ['product', 'attributes', 'load_index'],
        key: 'load_index',
        width: 25,
      },
      {
        title: 'SI',
        dataIndex: ['product', 'attributes', 'speed_index'],
        key: 'speed_index',
        width: 25,
      },
      {
        title: 'L1',
        dataIndex: ['product', 'attributes', 'label_fuel'],
        key: 'label_fuel',
        width: 25,
      },
      {
        title: 'L2',
        dataIndex: ['product', 'attributes', 'label_break'],
        key: 'label_break',
        width: 25,
      },
      {
        title: 'L3',
        dataIndex: ['product', 'attributes', 'label_sound_decibels'],
        key: 'label_sound_decibels',
        width: 50,
      },
      {
        title: 'Seller',
        dataIndex: 'seller',
        key: 'seller',
        width: 100,
        ellipsis: true,
      },
      {
        title: 'Qty',
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'right' as const,
      },
      {
        title: 'Dot',
        dataIndex: ['attributes', 'production_year'],
        key: 'production_year',
        align: 'right' as const,
      },
      {
        title: 'Pre',
        dataIndex: 'net_price',
        key: 'net_price',
        align: 'right' as const,
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        align: 'right' as const,
      },
      {
        title: 'Profit',
        dataIndex: 'unit_profit',
        key: 'unit_profit',
        align: 'right' as const,
        render: (profit: string) => <ProfitCell profit={profit} />,
      },
      {
        title: <LinkOutlined />,
        dataIndex: ['attributes', 'link'],
        key: 'link',
        render: (link: string) =>
          link ? (
            <a href={link} target="_blank">
              <LinkOutlined />
            </a>
          ) : null,
      },
    ],
    [],
  );
};

export default useEshopColumns;
