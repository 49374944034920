import React, { useEffect, useState } from 'react';

import styles from './CommentCell.style.module.less';

const OrderedQuantityCell: React.FC<{
  value: string;
  onChange: (v: string) => void;
  error?: boolean;
}> = ({ value, onChange }) => {
  const [localValue, setLocalValue] = useState(value);
  const onBlur = () => {
    onChange(localValue);
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <div className={styles.wrapper}>
      <input value={localValue} onChange={(e) => setLocalValue(e.target.value)} onBlur={onBlur} />
    </div>
  );
};

export default OrderedQuantityCell;
