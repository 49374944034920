import cx from 'classnames';
import React, { useMemo } from 'react';
import { VList } from 'virtuallist-antd';

import CustomTable from '@/components/CustomTable';
import { getWarehouseOrdersProductsQuery } from '@/queries/warehouse.queries';
import { ProductPricingSize } from '@/types/Api';

import styles from './PricingOrdersProductsBySizeTable.style.module.less';
import usePricingOrdersProductsBySizeColumns from './usePricingOrdersProductsBySizeColumns';
type Props = {
  orderId: number;
  season: string;
  size: string;
  producer: string | null;
  tyreClasses: string[];
  producers: string[];
  onClickName: (producer: string) => void;
};

const getRowClassName = (record: ProductPricingSize) => {
  return cx({
    [styles.noweopony]: record.seller === 'noweopony',
  });
};

const PricingOrdersProductsBySizeTable: React.FC<Props> = ({
  orderId,
  season,
  producer,
  size,
  tyreClasses,
  producers,
  onClickName,
}) => {
  const products = getWarehouseOrdersProductsQuery(orderId, {
    season,
    producer: producer || undefined,
    size,
    tyre_class: tyreClasses,
    producers,
  });
  const productsColumns = usePricingOrdersProductsBySizeColumns({
    onClickName,
    orderId,
  });
  const height = window.innerHeight - 100;
  const vc1 = useMemo(() => {
    return VList({
      height,
    });
  }, [height]);

  return (
    <CustomTable
      small
      columns={productsColumns}
      data={{
        results: products.data,
      }}
      isLoading={products.isLoading || products.isFetching}
      scroll={{ y: height, x: '100%' }}
      rowKey={'id'}
      components={vc1}
      style={{
        borderCollapse: 'collapse',
      }}
      tableLayout="auto"
      rowClassName={getRowClassName}
    />
  );
};
export default PricingOrdersProductsBySizeTable;
