import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce/lib';

import CustomTable from '../CustomTable';
import MapToEmptyAttributeValueModal from '../MapToEmptyAttributeValueModal';
import SearchAttributeValueModal from '../SearchAttributeValueModal';
import useTable from '@/hooks/useTable';
import { getAttributesMapQuery } from '@/queries/attributesMap.queries';
import { AttributeMap } from '@/types/Api';
import { AttributeMappingTableType } from '@/types/Mapping.types';

import useAttributesMapColumns from './useAttributesMapColumns';

type Props = {
  showActions?: boolean;
  tableType: AttributeMappingTableType;
  extraParams?: {
    is_mapped?: boolean;
    order_by?: string;
    is_deleted?: boolean;
  };
};

const tableTypeToParamsMap = {
  [AttributeMappingTableType.TO_MAP]: {
    is_mapped: false,
    is_blank: false,
  },
  [AttributeMappingTableType.MAPPED]: {
    is_mapped: true,
    order_by: '-updated_at',
  },
  [AttributeMappingTableType.SOFT_DELETED]: {
    order_by: '-deleted_at',
    is_deleted: true,
  },
  [AttributeMappingTableType.MAPPED_AS_EMPTY]: {
    is_blank: true,
  },
};

export type AttributeMapFilters = {
  source: string;
  name: string;
  attribute: string;
  attribute_value: string;
};

const AttributesMapTable: React.FC<Props> = ({ tableType }) => {
  const extraParams = tableTypeToParamsMap[tableType];

  const [mappedItem, setMappedItem] = useState<AttributeMap | null>(null);
  const [mappedToEmptyItem, setMappedToEmptyItem] = useState<AttributeMap | null>(null);
  const openMapModal = (item: AttributeMap) => setMappedItem(item);
  const openMapToEmptyModal = (item: AttributeMap) => setMappedToEmptyItem(item);

  const [filters, setFilters] = useState<AttributeMapFilters>({
    source: '',
    name: '',
    attribute: '',
    attribute_value: '',
  });
  const [debouncedFilters] = useDebounce(filters, 200);
  const { params, setQuery, pagination } = useTable(extraParams);

  const attributesMap = getAttributesMapQuery(params);

  const columns = useAttributesMapColumns({
    tableType,
    openMapModal,
    openMapToEmptyModal,
    filters,
    setFilters,
  });
  useEffect(() => {
    setQuery(debouncedFilters);
  }, [debouncedFilters, setQuery]);

  return (
    <>
      <CustomTable
        small
        pagination={pagination}
        data={attributesMap.data}
        columns={columns}
        isLoading={attributesMap.isLoading}
      />
      <SearchAttributeValueModal attribute={mappedItem} close={() => setMappedItem(null)} />
      <MapToEmptyAttributeValueModal
        attribute={mappedToEmptyItem}
        close={() => setMappedToEmptyItem(null)}
      />
    </>
  );
};

export default AttributesMapTable;
