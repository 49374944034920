import { useMutation, useQuery, useQueryClient } from 'react-query';

import { API } from '../utils/request.utils';
import { ApiInterface, Discount, DiscountLevel } from '@/types/Api';
import { QueryKeys } from '@/types/QueryKeys.types';

export function getDiscountLevels(params: ApiInterface.TermsDiscountLevelsList.RequestQuery = {}) {
  return useQuery(
    [QueryKeys.discountLevels, params],
    () => API.api.termsDiscountLevelsList(params),
    {
      keepPreviousData: true,
      select: (data) => data.data,
    },
  );
}

export function getDiscountLevelsDiscounts(discountLevelId: number) {
  return useQuery(
    [QueryKeys.discountLevelsDiscounts, discountLevelId],
    () => API.api.termsDiscountLevelsDiscountsList(discountLevelId),
    {
      keepPreviousData: true,
      select: (data) => data.data,
    },
  );
}

export const createDiscountLevelMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: DiscountLevel) => API.api.termsDiscountLevelsCreate(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.discountLevels]);
    },
  });
};

export const editDiscountLevelMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: DiscountLevel) => API.api.termsDiscountLevelsUpdate(data.id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.discountLevels]);
    },
  });
};

export const copyDiscountLevelMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => API.api.termsDiscountLevelsCloneCreate(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.discountLevels]);
    },
  });
};

export const createDiscountLevelDiscountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { discount: Discount; discountLevelId: number }) =>
      API.api.termsDiscountLevelsDiscountsCreate(data.discountLevelId, data.discount),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          QueryKeys.discountLevelsDiscounts,
          variables.discountLevelId,
        ]);
      },
    },
  );
};

export const deleteDiscountLevelDiscountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { discountId: number; discountLevelId: number }) =>
      API.api.termsDiscountLevelsDiscountsDestroy(data.discountLevelId, data.discountId),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          QueryKeys.discountLevelsDiscounts,
          variables.discountLevelId,
        ]);
      },
    },
  );
};

export const editDiscountLevelDiscountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { discount: Discount; discountLevelId: number; discountId: number }) =>
      API.api.termsDiscountLevelsDiscountsPartialUpdate(
        data.discountLevelId,
        data.discountId as number,
        data.discount,
      ),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          QueryKeys.discountLevelsDiscounts,
          variables.discountLevelId,
        ]);
      },
    },
  );
};
