import cx from 'classnames';
import React from 'react';

import styles from './Difference.style.module.less';

type Props = {
  value?: string;
};
const Difference: React.FC<Props> = ({ value = '0' }) => {
  const valueNumber = Number(value);
  return (
    <div
      className={cx({
        [styles.red]: valueNumber > 0,
        [styles.green]: valueNumber < 0,
      })}
    >
      {valueNumber}
    </div>
  );
};

export default Difference;
