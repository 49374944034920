import useT from '@/hooks/useT';

const useTyreSizesColumns = () => {
  const t = useT();
  return [
    {
      title: t('tyreSizesTable.sizeLabel'),
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: t('tyreSizesTable.widthLabel'),
      dataIndex: 'width',
      key: 'width',
    },
    {
      title: t('tyreSizesTable.profileLabel'),
      dataIndex: 'profile',
      key: 'profile',
    },
    {
      title: t('tyreSizesTable.rimLabel'),
      dataIndex: 'rim',
      key: 'rim',
    },
    {
      title: t('tyreSizesTable.loadIndexLabel'),
      dataIndex: 'load_index',
      key: 'load_index',
    },
    {
      title: t('tyreSizesTable.xlLabel'),
      dataIndex: 'xl',
      key: 'xl',
    },
  ];
};

export default useTyreSizesColumns;
