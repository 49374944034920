import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Redirect, Switch } from 'react-router-dom';

import AppLayout from '@/components/AppLayout';
import Analysis from '@/screens/Analysis';
import AnalysisSize from '@/screens/AnalysisSize';
import AttributeProducers from '@/screens/AttributeProducers';
import AttributeProducerTreads from '@/screens/AttributeProducerTreads';
import AttributeSizes from '@/screens/AttributeSizes';
import AttributesMapping from '@/screens/AttributesMapping';
import OrdersSales from '@/screens/OrdersSales';
import PricingOrders from '@/screens/PricingOrders';
import TermsDiscountLevels from '@/screens/TermsDiscountLevels';
import TermsInvoices from '@/screens/TermsInvoices';
import { Routes } from '@/types/Routes.types';

const AppNavigation: React.FC = () => {
  return (
    <AppLayout>
      <Switch>
        <Route exact path="/">
          <Helmet>
            <title>TODO</title>
          </Helmet>
          <h1>TODO</h1>
        </Route>
        {/* Attributes */}
        <Route exact path={Routes.attributesMapping} component={AttributesMapping}></Route>
        <Route exact path={Routes.attributesProducers} component={AttributeProducers}></Route>
        <Route
          exact
          path={Routes.attributesProducerTreads}
          component={AttributeProducerTreads}
        ></Route>
        <Route exact path={Routes.attributesSizes} component={AttributeSizes}></Route>
        {/* Terms */}
        <Route exact path={Routes.termsDiscountLevels} component={TermsDiscountLevels}></Route>
        <Route exact path={Routes.termsInvoices} component={TermsInvoices}></Route>
        {/* Pricing */}
        <Route exact path={Routes.pricingCurrentProfit} component={OrdersSales}></Route>
        <Route exact path={Routes.pricingAnalysis} component={Analysis}></Route>
        <Route exact path={Routes.pricingAnalysisSize} component={AnalysisSize}></Route>
        <Route exact path={Routes.pricingOrders} component={PricingOrders}></Route>
        <Redirect to="/" />
      </Switch>
    </AppLayout>
  );
};

export default AppNavigation;
