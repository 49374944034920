import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { API } from '../utils/request.utils';
import { Source } from '@/types/Api';
import { QueryKeys } from '@/types/QueryKeys.types';

export function getSourcesQuery() {
  const query = useQuery<AxiosResponse<Source[]>>(QueryKeys.sources, () =>
    API.api.coreSourcesList(),
  );
  return {
    ...query,
    sources: query.data?.data || [],
  };
}

export function getYearsQuery() {
  return useQuery(QueryKeys.years, () => API.api.coreYearsList(), {
    select: (data) => data.data,
  });
}

export function getChannelsQuery() {
  return useQuery(QueryKeys.channels, () => API.api.coreChannelsList(), {
    select: (data) => data.data,
  });
}
