import { Button, Input, Space } from 'antd';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { useDebounce } from 'use-debounce/lib';

import AddSizeModal from '@/components/AddSizeModal';
import SizesTable from '@/components/SizesTable';
import useT from '@/hooks/useT';

const AttributeSizes: React.FC = () => {
  const [addSizeModalVisible, setAddSizeModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 200);
  const t = useT();
  return (
    <>
      <Helmet>
        <title>{t('title.attributes.sizes')}</title>
      </Helmet>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Button type="primary" onClick={() => setAddSizeModalVisible(true)}>
          <FormattedMessage id="tyreSizes.addSizeButton" />
        </Button>
        <Input
          placeholder={t('tyreSizes.searchPlaceholder')}
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
        <SizesTable searchQuery={debouncedSearchQuery} />
      </Space>
      <AddSizeModal visible={addSizeModalVisible} setVisible={setAddSizeModalVisible} />
    </>
  );
};
export default AttributeSizes;
