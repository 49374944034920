import React, { useEffect } from 'react';

import CustomTable from '@/components/CustomTable';
import useTable from '@/hooks/useTable';
import { getOrdersSalesQuery } from '@/queries/ordersSales.queries';
import { OrderSales } from '@/types/Api';
import { getAvailabilityLevel } from '@/utils/ordersSales.util';

import style from './OrdersSales.style.module.less';
import useOrdersSalesColumns from './useOrdersSalesColumns';
type Props = {
  filters: {
    is_watched?: boolean;
    is_checked?: boolean;
  };
  name: string;
};

const getRowClassName = (record: OrderSales) => {
  const availabilityLevel = getAvailabilityLevel(record.availability);
  if (!availabilityLevel) {
    return '';
  }
  return style[`availabilityLevel_${availabilityLevel}`];
};

const OrdersSalesTable: React.FC<Props> = ({ name, filters }) => {
  const columns = useOrdersSalesColumns();
  const { params, setQuery, pagination } = useTable({
    ...filters,
    name,
    limit: 100,
  });
  const { data, isLoading } = getOrdersSalesQuery(params);

  useEffect(() => {
    setQuery(filters);
  }, [filters, setQuery]);

  useEffect(() => {
    setQuery({ name });
  }, [name, setQuery]);
  return (
    <CustomTable
      small
      columns={columns}
      data={data}
      isLoading={isLoading}
      pagination={pagination}
      rowClassName={getRowClassName}
    />
  );
};

export default OrdersSalesTable;
