import { LinkOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import fontColorContrast from 'font-color-contrast';

import CopyCell from '@/components/CopyCell';
import { ProductPricingSize } from '@/types/Api';
import { getAnalysisPageUrl } from '@/utils/navigation.utils';
interface Props {
  onClickName: (producer: string) => void;
}

const getStyles = (background: string | undefined) => {
  return {
    background,
    color: background ? fontColorContrast(background) : undefined,
  };
};

const usePricingProductsBySizeColumns = (props: Props) => {
  return [
    {
      title: <LinkOutlined />,
      dataIndex: 'link',
      key: 'link',
      render: (tread: string, row: ProductPricingSize) => {
        const { variant_attributes: vAttr, product_attributes: pAttr } = row;
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: (
            <a
              href={getAnalysisPageUrl({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                season: pAttr?.season || vAttr?.season,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                producer: pAttr?.producer || vAttr?.producer,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                size: pAttr?.size || vAttr?.size,
              })}
              target="_blank"
            >
              <LinkOutlined />
            </a>
          ),
        };
      },
    },
    {
      title: 'EAN',
      dataIndex: 'ean',
      key: 'ean',
      render: (ean: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: <CopyCell text={ean} maxLength={100} />,
        };
      },
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: (code: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: <CopyCell text={code} maxLength={13} />,
        };
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 180,
      render: (name: string, row: ProductPricingSize) => {
        const { tread, producer } = row.product_attributes || {};
        const text = tread && producer ? [producer, tread].join(' ') : name;
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: (
            <Tooltip title={text}>
              <span className="dots" style={{ width: 180 }}>
                {producer ? (
                  <a onClick={() => props.onClickName(producer)} style={{ color: 'inherit' }}>
                    {text}
                  </a>
                ) : (
                  text
                )}
              </span>
            </Tooltip>
          ),
        };
      },
    },
    {
      title: 'LI',
      dataIndex: ['product_attributes', 'load_index'],
      key: 'load_index',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'SI',
      dataIndex: ['product_attributes', 'speed_index'],
      key: 'speed_index',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'L1',
      dataIndex: ['product_attributes', 'label_fuel'],
      key: 'label_fuel',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'L2',
      dataIndex: ['product_attributes', 'label_break'],
      key: 'label_break',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'L3',
      dataIndex: ['product_attributes', 'label_sound_decibels'],
      key: 'label_sound_decibels',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value?.split(' ').join(''),
        };
      },
    },
    {
      title: 'L4',
      dataIndex: ['product_attributes', 'label_sound_level'],
      key: 'label_sound_level',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'XL',
      dataIndex: ['product_attributes', 'xl'],
      key: 'xl',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'FR',
      dataIndex: ['product_attributes', 'fr'],
      key: 'fr',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'RF',
      dataIndex: ['product_attributes', 'run_flat'],
      key: 'run_flat',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'H',
      dataIndex: ['product_attributes', 'homologation'],
      key: 'homologation',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'SS',
      dataIndex: ['product_attributes', 'selfseal_technology'],
      key: 'selfseal_technology',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'NCS',
      dataIndex: ['product_attributes', 'noice_cancelling_system'],
      key: 'noice_cancelling_system',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'Time',
      dataIndex: ['product_attributes', 'delivery_time'],
      key: 'delivery_time',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'Stan',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'Seller',
      dataIndex: 'seller',
      key: 'seller',
      width: 80,
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: (
            <Tooltip title={value}>
              <span className="dots" style={{ width: 80 }}>
                {value}
              </span>
            </Tooltip>
          ),
        };
      },
    },
    {
      title: 'DOT',
      dataIndex: ['variant_attributes', 'production_year'],
      key: 'production_year',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      sorter: (a: ProductPricingSize, b: ProductPricingSize) =>
        (a.price ? Number(a.price) : 0) - (b.price ? Number(b.price) : 0),
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value || '-------',
        };
      },
    },
    {
      title: 'Pre',
      dataIndex: 'preorder',
      key: 'preorder',
      sorter: (a: ProductPricingSize, b: ProductPricingSize) =>
        (a.price ? Number(a.price) : 0) - (b.price ? Number(b.price) : 0),
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value || '-------',
        };
      },
    },
    {
      title: 'Curr',
      dataIndex: 'current',
      key: 'current',
      sorter: (a: ProductPricingSize, b: ProductPricingSize) =>
        (a.current ? Number(a.current) : 0) - (b.current ? Number(b.current) : 0),
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value || '-------',
        };
      },
    },
    {
      title: 'all',
      dataIndex: 'sales_all',
      key: 'sales_all',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: '120',
      dataIndex: 'sales_90_120',
      key: 'sales_90_120',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: '90',
      dataIndex: 'sales_60_90',
      key: 'sales_60_90',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: '60',
      dataIndex: 'sales_30_60',
      key: 'sales_30_60',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: '30',
      dataIndex: 'sales_14_30',
      key: 'sales_14_30',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: '15',
      dataIndex: 'sales_7_14',
      key: 'sales_7_14',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: '7',
      dataIndex: 'sales_3_7',
      key: 'sales_3_7',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
    {
      title: '3',
      dataIndex: 'sales_0_3',
      key: 'sales_0_3',
      render: (value: string, row: ProductPricingSize) => {
        return {
          props: {
            style: getStyles(row.producer?.color),
          },
          children: value,
        };
      },
    },
  ];
};

export default usePricingProductsBySizeColumns;
