import { useQuery } from 'react-query';

import { QueryKeys } from '@/types/QueryKeys.types';
import { API } from '@/utils/request.utils';

export const getTyreClassesQuery = () => {
  return useQuery(QueryKeys.tyreClasses, () => API.api.attributesTyreTyreClassesList(), {
    select: (data) => data.data,
  });
};
