import { LinkOutlined } from '@ant-design/icons';

import CopyCell from '@/components/CopyCell';
import ProfitCell from '@/components/ProfitCell';
import { PricingProductVariant, ProductPricing } from '@/types/Api';
import { getAnalysisSizePageUrl } from '@/utils/navigation.utils';

import { ProductPricingRow } from './PricingProductsTable';
import PricingProductVariantPrice from './PricingProductVariantPrice';

interface Props {
  openProductVariantModal: (product: ProductPricing, variant: PricingProductVariant) => void;
}

const usePricingProductsColumns = (props: Props) => {
  return [
    {
      title: <LinkOutlined />,
      render: (value: ProductPricingRow) => {
        if (value.type === 'VARIANT') {
          return null;
        }

        return (
          <a
            href={getAnalysisSizePageUrl({
              season: value.raw.attributes.season,
              size: value.raw.attributes.size,
            })}
            target="_blank"
          >
            <LinkOutlined />
          </a>
        );
      },
    },
    {
      title: 'Bieżnik',
      dataIndex: 'treadAndVariantStrategyName',
      key: 'treadAndVariantStrategyName',
      render: (ean: string, record: ProductPricingRow) => {
        if (record.type === 'PARENT') {
          return record.tread;
        }
        return record.raw.channel_variant_strategy ? record.raw.channel_variant_strategy.name : '';
      },
    },
    {
      title: 'LI',
      dataIndex: 'loadIndex',
      key: 'loadIndex',
    },
    {
      title: 'SI',
      dataIndex: 'speedIndex',
      key: 'speedIndex',
    },
    {
      title: 'L1',
      dataIndex: 'labelFuel',
      key: 'labelFuel',
    },
    {
      title: 'L2',
      dataIndex: 'l2',
      key: 'l2',
    },
    {
      title: 'L3',
      dataIndex: 'l3',
      key: 'l3',
    },
    {
      title: 'ean/strategy',
      dataIndex: 'ean_and_strategy',
      key: 'ean_and_strategy',
      render: (ean: string, record: ProductPricingRow) => {
        if (record.type === 'PARENT') {
          return ean;
        } else if (record.type === 'VARIANT') {
          if (!ean) {
            return null;
          }
          return (
            <button
              onClick={() => {
                props.openProductVariantModal(record.productRaw, record.raw);
              }}
            >
              {ean}
            </button>
          );
        }
        return null;
      },
    },
    {
      title: 'channel',
      dataIndex: 'channel_name',
      key: 'channel_name',
      width: 70,
    },
    {
      title: 'Seller',
      dataIndex: 'seller',
      key: 'seller',
      render: (seller: string) => <CopyCell text={seller} maxLength={8} />,
    },
    {
      title: 'S',
      dataIndex: 'producer_qty_and_qty',
      key: 'producer_qty_and_qty',
    },
    {
      title: 'R',
      dataIndex: 'reservation',
      key: 'reservation',
    },
    {
      title: 'D',
      dataIndex: 'availability',
      key: 'availability',
    },
    {
      title: 'Pre/VP',
      dataIndex: 'preorder_and_net_price',
      key: 'preorder_and_net_price',
    },
    {
      title: 'Curr/SP',
      dataIndex: 'current_and_price',
      key: 'current_and_price',
      render: (currentAndPrice: string, record: ProductPricingRow) => {
        if (record.type === 'PARENT') {
          return currentAndPrice;
        } else if (record.type === 'VARIANT') {
          if (
            (record.raw.channel_variant_strategy &&
              record.raw.channel_variant_strategy.strategy === 'manual') ||
            record.raw.strategy === 'manual'
          ) {
            return <PricingProductVariantPrice id={record.raw.id} price={record.raw.price} />;
          } else {
            return record.raw.price;
          }
        } else {
          return null;
        }
      },
    },
    {
      title: 'Pro',
      dataIndex: 'unit_profit',
      key: 'unit_profit',
      render: (profit: string) => <ProfitCell profit={profit} />,
    },
    {
      title: 'DOT',
      dataIndex: 'productionYear',
      key: 'productionYear',
    },
    {
      title: 'All',
      dataIndex: 'salesAll',
      key: 'salesAll',
    },
    {
      title: '60',
      dataIndex: 'sales_30_60',
      key: 'sales_30_60',
    },
    {
      title: '30',
      dataIndex: 'sales_14_30',
      key: 'sales_14_30',
    },
    {
      title: '14',
      dataIndex: 'sales_7_14',
      key: 'sales_7_14',
    },
    {
      title: '7',
      dataIndex: 'sales_3_7',
      key: 'sales_3_7',
    },
    {
      title: '3',
      dataIndex: 'sales_0_3',
      key: 'sales_0_3',
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      render: (sku: string) => <CopyCell text={sku} maxLength={8} />,
    },
    {
      title: <LinkOutlined />,
      dataIndex: 'link',
      key: 'link',
      render: (link: string) =>
        link ? (
          <a href={link} target="_blank">
            <LinkOutlined />
          </a>
        ) : null,
    },
  ];
};

export default usePricingProductsColumns;
