import Cookie from 'js-cookie';

const COOKIE_TOKEN_KEY = 'token';

export const setTokenCookie = (token: string): void => {
  Cookie.set(COOKIE_TOKEN_KEY, token);
};

export const removeTokenCookie = (): void => {
  Cookie.remove(COOKIE_TOKEN_KEY);
};

export const getTokenCookie = (): string => {
  return Cookie.get(COOKIE_TOKEN_KEY) || '';
};
