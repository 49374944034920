import { MinusCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row, Select } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import React, { useState } from 'react';

import useT from '@/hooks/useT';
import { getAttributeValuesQuery } from '@/queries/attributes.queries';
import { Attribute } from '@/types/Api';

type Props = {
  field: FormListFieldData;
  remove: (index: number | number[]) => void;
  attributes: Attribute[];
  reset: (index: number) => void;
  initAttributeId?: number;
};

const TermsDiscountAddTermConditionRow: React.FC<Props> = ({
  field,
  attributes,
  remove,
  reset,
  initAttributeId = null,
}) => {
  const t = useT();
  const [attributeId, setAttributeId] = useState<number | null>(initAttributeId);

  const { data } = getAttributeValuesQuery(attributeId, { limit: 10000 });
  const attributeValues = data?.results || [];
  const { key, name, ...restField } = field;

  return (
    <Row key={key} gutter={16}>
      <Col span={12}>
        <Form.Item
          {...restField}
          style={{ marginBottom: 8 }}
          name={[name, 'attribute_id']}
          rules={[{ required: true }]}
        >
          <Select
            onChange={(v) => {
              setAttributeId(v as number);
              reset(name);
            }}
            showSearch
            placeholder={t('addEditDiscountTermModal.conditionsAttributePlaceholder')}
            optionFilterProp="name"
            filterOption={(input, option) =>
              option?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {attributes.map((attribute) => (
              <Select.Option
                key={attribute.id}
                value={attribute.id as number}
                name={attribute.name}
              >
                {attribute.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={10}>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
            return (
              prevValues.conditions[name]?.attributeId !==
              currentValues.conditions[name]?.attributeId
            );
          }}
        >
          {/* {attributeId ? ( */}
          <Form.Item
            style={{ marginBottom: 8 }}
            {...restField}
            name={[name, 'attribute_value_id']}
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder={t('addEditDiscountTermModal.conditionsAttributeValuePlaceholder')}
              optionFilterProp="name"
              filterOption={(input, option) =>
                option?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {attributeValues.map((attributeValue) => (
                <Select.Option
                  key={attributeValue.id}
                  value={attributeValue.id as number}
                  name={attributeValue.name}
                >
                  {attributeValue.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>
      </Col>
      <Col span={2}>
        <MinusCircleOutlined onClick={() => remove(name)} />
      </Col>
    </Row>
  );
};

export default TermsDiscountAddTermConditionRow;
