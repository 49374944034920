import { LinkOutlined } from '@ant-design/icons';
import { Button, message, Typography } from 'antd';
import { FormattedDate, FormattedTime } from 'react-intl';

import ProfitCell from '@/components/ProfitCell';
import useT from '@/hooks/useT';
import {
  markOrderSalesAsCheckedMutation,
  markOrderSalesIsWatchedMutation,
} from '@/queries/ordersSales.queries';
import { Channel, OrderSales, Source } from '@/types/Api';
import { formatError } from '@/utils/error.utils';
import { getAnalysisPageUrl } from '@/utils/navigation.utils';

const useOrdersSalesColumns = () => {
  const t = useT();

  const markOrderSalesAsCheckedMutator = markOrderSalesAsCheckedMutation();
  const markOrderSalesIsWatchedMutator = markOrderSalesIsWatchedMutation();

  const checkItem = async (orderSales: OrderSales) => {
    try {
      await markOrderSalesAsCheckedMutator.mutateAsync(orderSales.sku as string);
      message.success(t('message.actionSuccess'));
    } catch (err) {
      message.error(formatError(err));
    }
  };

  const watchItem = async (orderSales: OrderSales) => {
    try {
      await markOrderSalesIsWatchedMutator.mutateAsync({
        id: orderSales.id,
      });
      message.success(t('message.actionSuccess'));
    } catch (err) {
      message.error(formatError(err));
    }
  };

  return [
    {
      title: <LinkOutlined />,
      dataIndex: 'order_link',
      key: 'orderLink',

      render: (orderLink: string) => (
        <a href={orderLink} target="_blank">
          <LinkOutlined />
        </a>
      ),
    },
    {
      title: t('ordersSalesTable.channelLabel'),
      dataIndex: 'channel',
      key: 'channel',
      render: (channel: Channel) =>
        channel?.name ? (
          <div style={{ background: channel.color }}>
            <span>{channel.name}</span>
          </div>
        ) : null,
    },
    {
      title: t('ordersSalesTable.producerLabel'),
      dataIndex: ['product', 'attributes', 'producer'],
      key: 'producer',
    },
    {
      title: t('ordersSalesTable.sizeLabel'),
      dataIndex: ['product', 'attributes', 'size'],
      key: 'size',
    },
    {
      title: t('ordersSalesTable.treadLabel'),
      dataIndex: ['product', 'attributes', 'tread'],
      key: 'tread',
      render: (tread: string, row: OrderSales) => {
        const { season, producer, size } = row.product.attributes;
        return (
          <a href={getAnalysisPageUrl({ season, producer, size })} target="_blank">
            {tread}
          </a>
        );
      },
    },
    {
      title: t('ordersSalesTable.loadIndexLabel'),
      dataIndex: ['product', 'attributes', 'load_index'],
      key: 'loadIndex',
    },
    {
      title: t('ordersSalesTable.speedIndexLabel'),
      dataIndex: ['product', 'attributes', 'speed_index'],
      key: 'speedIndex',
    },
    {
      title: t('ordersSalesTable.labelFuelLabel'),
      dataIndex: ['product', 'attributes', 'label_fuel'],
      key: 'labelFuel',
    },
    {
      title: t('ordersSalesTable.labelBreak'),
      dataIndex: ['product', 'attributes', 'label_break'],
      key: 'labelBreak',
    },
    {
      title: t('ordersSalesTable.labelSoundDecibels'),
      dataIndex: ['product', 'attributes', 'label_sound_decibels'],
      key: 'labelSoundDecibels',
    },
    {
      title: t('ordersSalesTable.productionYear'),
      dataIndex: 'production_year',
      key: 'productionYear',
    },
    {
      title: t('ordersSalesTable.done'),
      key: 'doneButton',
      width: 1,
      render: (text: string, record: OrderSales) => (
        <Button
          size="small"
          type="primary"
          onClick={() => checkItem(record)}
          disabled={record.is_checked}
        >
          {t('button.done')}
        </Button>
      ),
    },
    {
      title: t('ordersSalesTable.stockQuantityLabel'),
      dataIndex: 'stock_quantity',
      key: 'stock_quantity',
    },
    {
      title: t('ordersSalesTable.reservationLabel'),
      dataIndex: 'reservation',
      key: 'reservation',
    },
    {
      title: t('ordersSalesTable.availabilityLabel'),
      dataIndex: 'availability',
      key: 'availability',
    },
    {
      title: t('ordersSalesTable.producerQuantityLabel'),
      dataIndex: 'producer_quantity',
      key: 'producer_quantity',
    },
    {
      title: t('ordersSalesTable.netPriceLabel'),
      dataIndex: 'net_price',
      key: 'net_price',
    },
    {
      title: t('ordersSalesTable.totalPriceLabel'),
      dataIndex: 'total_price',
      key: 'totalPrice',
    },
    {
      title: t('ordersSalesTable.quantityLabel'),
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: t('ordersSalesTable.profitLabel'),
      dataIndex: 'unit_profit',
      key: 'unitProfit',
      render: (unitProfit: string) => <ProfitCell profit={unitProfit} />,
    },
    {
      title: t('ordersSalesTable.salesAll'),
      dataIndex: 'sales_all',
      key: 'salesAll',
    },
    {
      title: t('ordersSalesTable.sales30-60Label'),
      dataIndex: 'sales_30_60',
      key: 'sales_30_60',
    },
    {
      title: t('ordersSalesTable.sales14-30Label'),
      dataIndex: 'sales_14_30',
      key: 'sales_14_3030',
    },
    {
      title: t('ordersSalesTable.sales7-14Label'),
      dataIndex: 'sales_7_14',
      key: 'sales_7_14',
    },
    {
      title: t('ordersSalesTable.sales3-7Label'),
      dataIndex: 'sales_3_7',
      key: 'sales_3_7',
    },
    {
      title: t('ordersSalesTable.sales0-3Label'),
      dataIndex: 'sales_0_3',
      key: 'sales_0_3',
    },
    {
      title: t('ordersSalesTable.date'),
      dataIndex: 'date',
      key: 'date',
      render: (text: string) => (
        <Typography.Text ellipsis={true}>
          <FormattedDate value={text} /> <FormattedTime value={text} />
        </Typography.Text>
      ),
    },
    {
      title: t('ordersSalesTable.sku'),
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: t('ordersSalesTable.ean'),
      dataIndex: 'ean',
      key: 'ean',
    },
    {
      title: t('ordersSalesTable.code'),
      dataIndex: ['product', 'code'],
      key: 'code',
    },
    {
      title: t('ordersSalesTable.isPromoted'),
      dataIndex: 'is_promoted',
      key: 'is_promoted',
      render: (isPromoted: boolean) => <span>{isPromoted ? '✅' : '🚫'}</span>,
    },
    {
      title: t('ordersSalesTable.source'),
      dataIndex: 'source',
      key: 'source',
      render: (source: Source) => (
        <div style={{ backgroundColor: source.color }}>{source.name}</div>
      ),
    },
    {
      title: t('ordersSalesTable.isOrdered'),
      dataIndex: 'is_ordered',
      key: 'is_ordered',
      render: (isOrdered: boolean) => <span>{isOrdered ? '✅' : '🚫'}</span>,
    },
    {
      title: t('ordersSalesTable.isWatched'),
      key: 'watchButton',
      width: 1,
      render: (text: string, record: OrderSales) => (
        <Button size="small" type="default" onClick={() => watchItem(record)}>
          {record.is_watched ? '➖' : '➕'}
        </Button>
      ),
    },
  ];
};

export default useOrdersSalesColumns;
