import useT from '@/hooks/useT';

const useTyreTreadsColumns = () => {
  const t = useT();
  return [
    {
      title: t('tyreProducerTreads.nameLabel'),
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: t('tyreProducerTreads.producerLabel'),
      dataIndex: 'producer',
      key: 'producer',
      width: '20%',
    },
    {
      title: t('tyreProducerTreads.seasonLabel'),
      dataIndex: 'season',
      key: 'season',
      width: '20%',
    },
    {
      title: t('tyreProducerTreads.tyreTypeLabel'),
      dataIndex: 'tyre_type',
      key: 'tyre_type',
      width: '20%',
    },
    {
      title: t('tyreProducerTreads.cLabel'),
      dataIndex: 'c',
      key: 'c',
      width: '10%',
    },
  ];
};

export default useTyreTreadsColumns;
