import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Layout } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Calculator from '@/components/Calculator';
import { logoutMutation } from '@/queries/auth.queries';

import styles from './AppLayout.style.module.less';
import AppLayoutMenu from './AppLayoutMenu';

const { Header, Content, Sider } = Layout;
const AppLayout: React.FC = ({ children }) => {
  const logoutMutator = logoutMutation();
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <div className={styles.layout}>
      <Layout>
        <Header>
          <div className={styles.headerLeft}>
            <div onClick={toggleCollapsed} className={styles.hamburger}>
              {collapsed ? (
                <RightOutlined style={{ color: 'white' }} />
              ) : (
                <LeftOutlined style={{ color: 'white' }} />
              )}
            </div>
            <Calculator />
          </div>
          <Button onClick={() => logoutMutator.mutate()}>
            <FormattedMessage id="header.logout" />
          </Button>
        </Header>
        <Layout>
          <Sider collapsed={collapsed} collapsedWidth={40}>
            <AppLayoutMenu collapsed={collapsed} />
          </Sider>
          <Layout>
            <Content>{children}</Content>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
};

export default AppLayout;
