export enum TyreType {
  car = 'osobowe',
  truck = 'dostawcze',
  fourWheel = '4X4',
}

export enum TyreSeason {
  winter = 'zimowe',
  summer = 'letnie',
  allSeason = 'całoroczne',
}

export enum TyreClass {
  premium = 'Premium',
  middle = 'Średnia',
  economic = 'Ekonomiczna',
}
