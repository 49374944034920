import React, { useEffect, useState } from 'react';

import styles from './OrderedQuantityCell.style.module.less';

const OrderedQuantityCell: React.FC<{
  value?: number;
  onChange: (v?: number) => void;
  error?: boolean;
}> = ({ value, onChange }) => {
  const [localValue, setLocalValue] = useState<number | undefined>(value);
  const onBlur = () => {
    onChange(localValue || 0);
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);
  return (
    <div className={styles.wrapper}>
      <input
        type="number"
        value={localValue}
        onChange={(e) => setLocalValue(e.target.value === '' ? undefined : Number(e.target.value))}
        onBlur={onBlur}
        min={0}
      />
    </div>
  );
};

export default OrderedQuantityCell;
