import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import CustomTable from '../CustomTable';
import useTable from '@/hooks/useTable';
import { getTyreProducerTreadsQuery } from '@/queries/attributesTyre.query';
import { Tread } from '@/types/Api';
import { TyreSeason } from '@/types/Tyre.types';

import useTyreTreadsColumns from './useTyreTreadsColumns';

type Props = {
  searchQuery?: string;
};

type Params = {
  producerId: string;
};

const TreadTable: React.FC<Props> = ({ searchQuery = '' }) => {
  const columns = useTyreTreadsColumns();
  const { producerId } = useParams<Params>();

  const { params, setQuery } = useTable({
    name: searchQuery,
    limit: undefined,
  });

  const producerTreads = getTyreProducerTreadsQuery(Number(producerId), {
    name: params.name,
  });
  const { data = [] } = producerTreads;

  useEffect(() => {
    setQuery({ name: searchQuery });
  }, [searchQuery, setQuery]);

  const filterBySeason = (season: TyreSeason) => data.filter((tread) => tread.season === season);
  const allSeason = filterBySeason(TyreSeason.allSeason);
  const winter = filterBySeason(TyreSeason.winter);
  const summer = filterBySeason(TyreSeason.summer);
  const sections = [
    {
      textId: 'tyreSeason.winter',
      sectionData: winter,
    },
    {
      textId: 'tyreSeason.summer',
      sectionData: summer,
    },
    {
      textId: 'tyreSeason.allSeason',
      sectionData: allSeason,
    },
  ];
  const renderSection = ({ textId, sectionData }: { textId: string; sectionData: Tread[] }) => (
    <div>
      <h2>
        <FormattedMessage id={textId} />
      </h2>
      <CustomTable
        isLoading={producerTreads.isLoading}
        columns={columns}
        data={{
          results: sectionData,
        }}
      />
    </div>
  );

  return <div>{sections.map((section) => renderSection(section))}</div>;
};

export default TreadTable;
