import { Typography } from 'antd';
import React from 'react';

import styles from './CopyCell.style.module.less';

const CopyCell: React.FC<{ maxLength: number; text: string | null }> = ({ text, maxLength }) => {
  const parsedText = text || '';
  const cutText = parsedText.slice(0, maxLength);
  const finalText = `${cutText}${cutText.length < parsedText.length ? '...' : ''}`;
  if (!finalText) {
    return null;
  }
  return (
    <span className={styles.copy}>
      <Typography.Text copyable={{ text: parsedText }}>{finalText}</Typography.Text>
    </span>
  );
};

export default CopyCell;
