import { Button, Input, Space } from 'antd';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { useDebounce } from 'use-debounce/lib';

import AddProducerModal from '@/components/AddProducerModal';
import ProducersTable from '@/components/ProducersTable';
import useT from '@/hooks/useT';
const AttributeProducers: React.FC = () => {
  const [addProducerModalVisible, setAddProducerModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 200);
  const t = useT();
  return (
    <>
      <Helmet>
        <title>{t('title.attributes.producers')}</title>
      </Helmet>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Button type="primary" onClick={() => setAddProducerModalVisible(true)}>
          <FormattedMessage id="tyreProducers.addProducerButton" />
        </Button>
        <Input
          placeholder={t('tyreProducers.searchPlaceholder')}
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
        <ProducersTable searchQuery={debouncedSearchQuery} />
      </Space>
      <AddProducerModal visible={addProducerModalVisible} setVisible={setAddProducerModalVisible} />
    </>
  );
};
export default AttributeProducers;
