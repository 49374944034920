import { Routes } from '@/types/Routes.types';

interface AnalysisPageParams {
  season?: string;
  producer?: string;
  size?: string;
}

const createAnalysisPageParams = (data: AnalysisPageParams) => {
  const { season, producer, size } = data;
  const searchParams = new URLSearchParams();
  if (season) {
    searchParams.append('season', season);
  }
  if (producer) {
    searchParams.append('producer', producer);
  }
  if (size) {
    searchParams.append('size', size);
  }
  return searchParams.toString();
};

export const getAnalysisPageUrl = (data: AnalysisPageParams) =>
  `${Routes.pricingAnalysis}?${createAnalysisPageParams(data)}`;

export const getAnalysisSizePageUrl = (data: AnalysisPageParams) =>
  `${Routes.pricingAnalysisSize}?${createAnalysisPageParams(data)}`;
