import { Button, message, Space } from 'antd';

import useT from '@/hooks/useT';
import { copyDiscountLevelMutation } from '@/queries/discountLevels.queries';
import { DiscountLevel } from '@/types/Api';
import { formatError } from '@/utils/error.utils';

type Props = {
  selectItem: (discountLevel: DiscountLevel) => void;
  editItem: (discountLevel: DiscountLevel) => void;
  selectedItemId?: number;
};

const useDiscountLevelColumns = ({ selectItem, selectedItemId, editItem }: Props) => {
  const t = useT();
  const copyDiscountLevelMutator = copyDiscountLevelMutation();

  const copyItem = async (id: number) => {
    try {
      await copyDiscountLevelMutator.mutateAsync(id);
      message.success(t('termsDiscountLevel.copySuccessMessage'));
      close();
    } catch (err) {
      message.error(formatError(err));
    }
  };

  return [
    {
      title: t('termsDiscountLevel.detailsButton'),
      key: 'detailsButton',
      width: 1,
      render: (text: string, record: DiscountLevel) => (
        <Button
          size="small"
          type="primary"
          onClick={() => selectItem(record)}
          danger={record.id === selectedItemId}
        >
          {t('button.details')}
        </Button>
      ),
    },
    {
      title: t('termsDiscountLevel.sourceLabel'),
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: t('termsDiscountLevel.nameLabel'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('termsDiscountLevel.descriptionLabel'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('termsDiscountLevel.yearLabel'),
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: t('termsDiscountLevel.copyButton'),
      key: 'copyButton',
      width: 1,
      render: (text: string, record: DiscountLevel) => (
        <Space>
          <Button type="primary" size="small" onClick={() => editItem(record)}>
            {t('button.edit')}
          </Button>
          <Button type="primary" size="small" onClick={() => copyItem(record.id)}>
            {t('button.copy')}
          </Button>
        </Space>
      ),
    },
  ];
};

export default useDiscountLevelColumns;
