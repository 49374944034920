import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import cx from 'classnames';
import React, { useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import CustomTable from '@/components/CustomTable';
import { PurchaseInvoice, PurchaseInvoiceLine } from '@/types/Api';

import styles from './TermsInvoicePurchase.style.module.less';
import useTermsInvoicePurchaseLinesColumns from './useTermsInvoicePurchaseLinesColumns';
type Props = PurchaseInvoice & {
  termId?: number;
  bulkAssign: (id: number) => void;
  bulkAssignZero: (id: number) => void;
  pause: (id: number) => void;
  assign: (invoiceId: number, lineId: number) => void;
  assignZero: (invoiceId: number, lineId: number) => void;
  openSaveConfirmModal: (id: number) => void;
  openCancelLineConfirmModal: (invoiceId: number, lineId: number) => void;
  setAvgCalculate: (invoiceId: number, lineId: number, avgCalculatePrice: boolean) => void;
  setUpdatedLine: (updatedLine: { invoiceId: number; line: PurchaseInvoiceLine } | null) => void;
  showButtons: boolean;
};

const PRICE_MAX_DIFF = 0.04;

const getRowClassName = (line: PurchaseInvoiceLine) => {
  const unitNettoPrice = Number(line.unit_netto_price);
  const invoicePrice = line.invoice_price ? Number(line.invoice_price) : 0;
  const priceDiff = Math.abs(unitNettoPrice * 100 - invoicePrice * 100) / 100;
  return cx({
    [styles.linePriceCorrect]: invoicePrice && priceDiff <= PRICE_MAX_DIFF,
    [styles.linePriceInvalid]: invoicePrice && priceDiff > PRICE_MAX_DIFF,
  });
};

const TermsInvoicePurchase: React.FC<Props> = ({
  termId,
  id,
  customer_name,
  title,
  date,
  quantity_sum,
  lines,
  bulkAssign,
  bulkAssignZero,
  pause,
  assign,
  assignZero,
  openSaveConfirmModal,
  openCancelLineConfirmModal,
  showButtons,
  setUpdatedLine,
  setAvgCalculate,
}) => {
  const columns = useTermsInvoicePurchaseLinesColumns({
    invoiceId: id,
    termId,
    assign,
    assignZero,
    setUpdatedLine,
    openCancelLineConfirmModal,
    setAvgCalculate,
    showButtons,
  });
  const [isListOpen, setIsListOpen] = useState(true);

  return (
    <div className={styles.item}>
      <div className={styles.header}>
        <Button onClick={() => setIsListOpen((v) => !v)}>
          <CaretRightOutlined
            className={cx({
              [styles.collapse]: true,
              [styles.collapse_open]: isListOpen,
            })}
          />
        </Button>
        {showButtons && (
          <>
            <Button onClick={() => bulkAssign(id)} disabled={!termId}>
              <FormattedMessage id="button.check" />
            </Button>
            <Button type="primary" onClick={() => openSaveConfirmModal(id)}>
              <FormattedMessage id="button.save" />
            </Button>
          </>
        )}
        <div className={styles.header__name}>
          {customer_name}: {title}
          {' - '}
          <FormattedDate value={date} />
          {' - '}
          <Typography.Text strong>{quantity_sum}</Typography.Text>
        </div>
        {showButtons && (
          <>
            <Button size="small" onClick={() => pause(id)}>
              <FormattedMessage id="button.pause" />
            </Button>
            <Button size="small" onClick={() => bulkAssignZero(id)}>
              <FormattedMessage id="button.zero" />
            </Button>
          </>
        )}
      </div>
      {isListOpen && (
        <CustomTable
          tableLayout="auto"
          columns={columns}
          data={{
            results: lines,
          }}
          rowClassName={getRowClassName}
        />
      )}
    </div>
  );
};

export default TermsInvoicePurchase;
