import { Input, List, message, Modal, Pagination, Space, Spin, Typography, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce/lib';

import { FONT_SIZE } from '@/constants/sizes.constatns';
import useT from '@/hooks/useT';
import useTable from '@/hooks/useTable';
import { getAttributeValuesQuery } from '@/queries/attributes.queries';
import { patchAttributeMapMutation } from '@/queries/attributesMap.queries';
import { AttributeMap, AttributeValue } from '@/types/Api';
import { formatError } from '@/utils/error.utils';
const { Text } = Typography;
type Props = {
  attribute: AttributeMap | null;
  close: () => void;
};

const SearchAttributeValueModal: React.FC<Props> = ({ attribute, close }) => {
  const t = useT();

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedAttributeValue, setSelectedAttributeValue] = useState<AttributeValue | null>(null);
  const [debouncedSearchQuery] = useDebounce(searchQuery, 200);
  const { params, setQuery, pagination } = useTable();
  const attributeValues = getAttributeValuesQuery(
    attribute?.attribute?.id ? attribute.attribute.id : null,
    params,
  );

  const updateAttributeMapMutator = patchAttributeMapMutation();

  const updateAttributeMap = async () => {
    try {
      if (!selectedAttributeValue) {
        return;
      }
      await updateAttributeMapMutator.mutateAsync({
        id: attribute?.id as number,
        data: {
          attribute_value_id: selectedAttributeValue.id,
        },
      });
      message.success(t('attributesMapping.successMessage', { value: attribute?.name || '' }));
      close();
      setSelectedAttributeValue(null);
    } catch (err) {
      message.error(formatError(err));
    }
  };

  const handleCancel = () => close();
  useEffect(() => setQuery({ name: debouncedSearchQuery }), [setQuery, debouncedSearchQuery]);

  const header = (
    <Space direction="horizontal">
      <Text type="secondary" style={{ fontSize: FONT_SIZE.l }}>
        {attribute?.attribute?.name}
      </Text>
      <Text type="warning" style={{ fontSize: FONT_SIZE.l }}>
        {attribute?.name}
      </Text>
    </Space>
  );
  return (
    <>
      <Modal visible={!!attribute} onCancel={handleCancel} footer={null}>
        {header}
        <Input
          placeholder={t('attributesMapping.valueSearchPlaceholder')}
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
        <Spin tip="..." spinning={updateAttributeMapMutator.isLoading}>
          <List
            dataSource={attributeValues.data?.results || []}
            renderItem={(item: AttributeValue) => (
              <List.Item
                style={{ cursor: 'pointer' }}
                onClick={() => setSelectedAttributeValue(item)}
              >
                {item.name}
              </List.Item>
            )}
          />
        </Spin>
        <Pagination
          size="small"
          pageSize={params.limit}
          current={pagination.current}
          showSizeChanger
          total={attributeValues.data?.count || params.limit}
          onChange={(current, pageSize) => pagination.onChangePagination({ current, pageSize })}
        />
      </Modal>
      <Modal
        visible={!!selectedAttributeValue}
        onCancel={() => setSelectedAttributeValue(null)}
        onOk={() => updateAttributeMap()}
        okText={t('button.accept')}
        cancelText={t('button.cancel')}
      >
        {header}
        <div>
          <Row>
            <Col span={24}>
              <div>
                <Text strong>{t('attributeMapping.acceptModal.currentValue')}</Text>
              </div>
              <div>
                <Text strong style={{ fontSize: FONT_SIZE.l }} type="warning">
                  {attribute?.attribute_value || '-'}
                </Text>
              </div>
            </Col>
            <Col span={24}>
              <div>
                <Text strong>{t('attributeMapping.acceptModal.newValue')}</Text>
              </div>
              <div>
                <Text strong style={{ fontSize: FONT_SIZE.l }} type="warning">
                  {selectedAttributeValue?.name}
                </Text>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default SearchAttributeValueModal;
