import { Space } from 'antd';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';

import SelectSource from '@/components/SelectSource';
import useT from '@/hooks/useT';
import { InvoicePurchaseStatus, invoicePurchaseStatuses } from '@/types/Invoice.types';

import TermsInvoicePurchases from './TermsInvoicePurchases';
import TermsInvoicesTable from './TermsInvoicesTable';

const TermsInvoices: React.FC = () => {
  const t = useT();
  const [source, setSource] = useState<string | undefined>(undefined);
  const [termId, setTermId] = useState<number | undefined>(undefined);
  const [invoicePurchaseStatus, setInvoicePurchaseStatus] =
    useState<InvoicePurchaseStatus>('toCheck');

  useEffect(() => {
    setTermId(undefined);
  }, [invoicePurchaseStatus, source]);
  return (
    <>
      <Helmet>
        <title>{t('title.terms.invoices')}</title>
      </Helmet>
      <Space direction="vertical" style={{ width: '100%' }} size={20}>
        <SelectSource value={source} onChange={setSource} />
        <TermsInvoicesTable source={source} termId={termId} setTermId={setTermId} />
        <div>
          {invoicePurchaseStatuses.map((status) => (
            <CheckableTag
              key={status}
              checked={status === invoicePurchaseStatus}
              onChange={(active) => (active ? setInvoicePurchaseStatus(status) : undefined)}
            >
              <FormattedMessage id={`invoicePurchaseStatus.${status}`} />
            </CheckableTag>
          ))}
        </div>
        <TermsInvoicePurchases status={invoicePurchaseStatus} termId={termId} />
      </Space>
    </>
  );
};
export default TermsInvoices;
