import React from 'react';
import { FormattedMessage } from 'react-intl';

const EmptyState: React.FC = () => {
  return (
    <div>
      <span>∅ </span>
      <FormattedMessage id="emptyState.message" />
    </div>
  );
};

export default EmptyState;
