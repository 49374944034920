import { useMutation, useQuery, useQueryClient } from 'react-query';

import { API } from '../utils/request.utils';
import { removeTokenCookie, setTokenCookie } from '@/services/cookie.service';
import { QueryKeys } from '@/types/QueryKeys.types';

export const loginMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(API.api.authLoginCreate, {
    onSuccess: (response) => {
      setTokenCookie(response.data.token || '');
      queryClient.invalidateQueries([QueryKeys.me]);
    },
  });
};

export const logoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(() => Promise.resolve(), {
    onSuccess: () => {
      removeTokenCookie();
      queryClient.invalidateQueries([QueryKeys.me]);
    },
  });
};

export const getMeQuery = () => {
  return useQuery(QueryKeys.me, () => API.api.usersMeRetrieve(), {
    retry: 0,
  });
};
