/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AllegroVariant {
  id: number;
  sku: string;
  seller: string;
  quantity: number;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  net_price: string | null;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  price: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  unit_profit: string | null;
  producer: string;
  size: string;
  season: string;
  tread: string;
  load_index: string;
  speed_index: string;
  label_fuel: string;
  label_break: string;
  label_sound_decibels: string;
  delivery_time: string;
  production_year: string;
  allegro_popularity: string;
  allegro_promoted: string;
  allegro_qty_on_auction: string;
  link: string;
}

export interface AnalyticVariant {
  id: number;
  sku: string;
  name?: string;
  description?: string;
  channel: Channel;
  seller?: string;

  /**
   * @min 0
   * @max 2147483647
   */
  quantity: number;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  net_price?: string | null;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  price: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  unit_profit?: string | null;
  attributes?: Record<string, any>;
  product: ProductOnlyAttributes;
  is_active?: boolean;
}

export interface AssignPrices {
  term_id: number;
}

export interface Attribute {
  id: number;
  name: AttributeEnum;
  input_type?: InputTypeEnum;
  attribute_type?: AttributeTypeEnum[];

  /** Import wont start if this attribute isn't set in ImportFile. */
  is_required?: boolean;
}

export enum AttributeEnum {
  Ean = 'ean',
  Code = 'code',
  Producer = 'producer',
  Season = 'season',
  TyreType = 'tyre_type',
  Tread = 'tread',
  Size = 'size',
  Width = 'width',
  Profile = 'profile',
  Rim = 'rim',
  LoadIndex = 'load_index',
  SpeedIndex = 'speed_index',
  LabelFuel = 'label_fuel',
  LabelBreak = 'label_break',
  LabelSoundDecibels = 'label_sound_decibels',
  LabelSoundLevel = 'label_sound_level',
  Xl = 'xl',
  Fr = 'fr',
  RunFlat = 'run_flat',
  Cargo = 'cargo',
  Pmsf = 'pmsf',
  Homologation = 'homologation',
  Ice = 'ice',
  Eprel = 'eprel',
  SelfsealTechnology = 'selfseal_technology',
  NoiseCancellingSystem = 'noise_cancelling_system',
  Demo = 'demo',
  ProductionYear = 'production_year',
  DeliveryTime = 'delivery_time',
  CountryOrigin = 'country_origin',
  Link = 'link',
  AllegroPopularity = 'allegro_popularity',
  AllegroPromoted = 'allegro_promoted',
  AllegroQtyOnAuction = 'allegro_qty_on_auction',
}

export interface AttributeMap {
  id: number;

  /** @format date-time */
  created_at: string;

  /** @format date-time */
  updated_at: string;

  /** @format date-time */
  deleted_at?: string | null;
  source?: string | null;
  name?: string;
  description?: string;
  attribute?: Attribute;
  attribute_value?: string;
  is_deleted?: boolean;
  is_blank?: boolean;
  is_mapped?: boolean;
  attribute_value_id?: number;
}

export enum AttributeTypeEnum {
  Product = 'product',
  ProductVariant = 'product_variant',
  AnalyticVariant = 'analytic_variant',
}

export interface AttributeValue {
  id: number;
  name: AttributeEnum;
}

export interface AuthToken {
  username: string;
  password: string;
  token: string;
}

export interface BulkAssignPrices {
  term_id: number;
}

export interface Channel {
  id: number;
  name: ChannelNameEnum;

  /** @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$ */
  color?: string;

  /**
   * @min -2147483648
   * @max 2147483647
   */
  sort_order?: number | null;
}

export enum ChannelNameEnum {
  AllegroIntyrePlTyres4X = 'allegro_intyre_pl_tyres_4x',
  AllegroIntyrePlTyres2X = 'allegro_intyre_pl_tyres_2x',
  AllegroIntyrePlTyres1X = 'allegro_intyre_pl_tyres_1x',
  AllegroNoweoponyPlTyres4X = 'allegro_noweopony_pl_tyres_4x',
  AllegroNoweoponyPlTyres2X = 'allegro_noweopony_pl_tyres_2x',
  AllegroNoweoponyPlTyres1X = 'allegro_noweopony_pl_tyres_1x',
  AllegroBarumPolskaTyres4X = 'allegro_barum_polska_tyres_4x',
  AllegroBarumPolskaTyres2X = 'allegro_barum_polska_tyres_2x',
  AllegroBarumPolskaTyres1X = 'allegro_barum_polska_tyres_1x',
  Platformaopon = 'platformaopon',
  ShopNoweopony = 'shop_noweopony',
  PersonalTyres = 'personal_tyres',
}

export interface ChannelVariantPricingStrategy {
  id: number;
  name?: string | null;
  description?: string | null;
  channel_product_variant: number;
  strategy?: StrategyEnum;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  allegro_price_allowance?: string;
  allegro_price_quantity_division?: number;
  price_fight_position?: number;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  price_fight_allowance?: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  price_fight_profit_difference?: string;
  price_fight_mode?: PriceFightModeEnum;
}

export interface DictProductAttribute {
  season?: string;
  producer?: string;
  size?: string;
  tread?: string;
  load_index?: string;
  speed_index?: string;
  label_fuel?: string;
  label_break?: string;
  label_sound_decibels?: string;
  label_sound_level?: string;
  fr?: string;
  tyre_type?: string;
  width?: string;
  profile?: string;
  rim?: string;
  xl?: string;
  eprel?: string;
  homologation?: string;
  selfseal_technology?: string;
  noise_cancelling_system?: string;
}

export interface DictProductVariantAttribute {
  production_year?: string;
  delivery_time?: string;
}

export interface Discount {
  id: number;
  name: string;
  description?: string;
  discount_type: DiscountTypeEnum;

  /**
   * @format decimal
   * @pattern ^\d{0,4}(?:\.\d{0,6})?$
   */
  value: string;

  /** Flag indicating whether the discount is after-sales. */
  is_aftersale?: boolean;
  conditions?: DiscountCondition[];
}

export interface DiscountCondition {
  id: number;
  attribute_id: number;
  attribute?: string;
  attribute_value_id: number;
  attribute_value?: string;
}

export interface DiscountLevel {
  id: number;
  name: string;
  description?: string;
  source: string;
  year: string;
}

export enum DiscountTypeEnum {
  Pln = 'pln',
  Percent = 'percent',
}

export enum InputTypeEnum {
  Dropdown = 'dropdown',
  Multiselect = 'multiselect',
  Numeric = 'numeric',
  Boolean = 'boolean',
  Date = 'date',
  DateTime = 'date-time',
}

export interface InvoiceProduct {
  id: number;
  name: string;

  /** @pattern ^[0-9]{13}$ */
  ean: string;
  code?: string | null;
  description?: string | null;
  attributes?: ProductAttribute[];
}

export interface OrderSales {
  id: number;
  name: string;
  source: Source;
  channel: Channel;

  /**
   * @min 0
   * @max 2147483647
   */
  quantity: number;
  product: ProductOnlyAttributes;
  stock_quantity?: number;
  producer_quantity?: number;
  reservation?: number;
  availability?: number;

  /**
   * Buy price.
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  net_price?: string | null;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  total_price: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  unit_profit?: string | null;
  production_year?: string | null;
  sales_0_3?: number;
  sales_3_7?: number;
  sales_7_14?: number;
  sales_14_30?: number;
  sales_30_60?: number;
  sales_all?: number;

  /** @format date-time */
  date: string;
  sku?: string | null;

  /** @pattern ^[0-9]{13}$ */
  ean?: string | null;
  is_promoted?: boolean;
  is_checked?: boolean;
  is_ordered?: boolean;
  is_watched?: boolean;
  order_link: string;
}

export interface OrderSummary {
  products?: number;
  producer?: string;
  season?: string;
  size?: string;
  ordered?: number;
  sales_previous_year?: number;
  sales_current_year?: number;
  quantity?: number;
  popularity?: number;
  popularity_intyre?: number;
}

export interface OutputProfitCalculator {
  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  unit_profit: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  unit_provision: string;
}

export interface PaginatedAllegroVariantList {
  /** @example 123 */
  count?: number;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=400&limit=100
   */
  next?: string | null;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=200&limit=100
   */
  previous?: string | null;
  results?: AllegroVariant[];
}

export interface PaginatedAnalyticVariantList {
  /** @example 123 */
  count?: number;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=400&limit=100
   */
  next?: string | null;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=200&limit=100
   */
  previous?: string | null;
  results?: AnalyticVariant[];
}

export interface PaginatedAttributeMapList {
  /** @example 123 */
  count?: number;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=400&limit=100
   */
  next?: string | null;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=200&limit=100
   */
  previous?: string | null;
  results?: AttributeMap[];
}

export interface PaginatedAttributeValueList {
  /** @example 123 */
  count?: number;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=400&limit=100
   */
  next?: string | null;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=200&limit=100
   */
  previous?: string | null;
  results?: AttributeValue[];
}

export interface PaginatedDiscountLevelList {
  /** @example 123 */
  count?: number;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=400&limit=100
   */
  next?: string | null;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=200&limit=100
   */
  previous?: string | null;
  results?: DiscountLevel[];
}

export interface PaginatedOrderSalesList {
  /** @example 123 */
  count?: number;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=400&limit=100
   */
  next?: string | null;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=200&limit=100
   */
  previous?: string | null;
  results?: OrderSales[];
}

export interface PaginatedProductPricingList {
  /** @example 123 */
  count?: number;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=400&limit=100
   */
  next?: string | null;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=200&limit=100
   */
  previous?: string | null;
  results?: ProductPricing[];
}

export interface PaginatedPurchaseInvoiceList {
  /** @example 123 */
  count?: number;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=400&limit=100
   */
  next?: string | null;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=200&limit=100
   */
  previous?: string | null;
  results?: PurchaseInvoice[];
}

export interface PaginatedSizeList {
  /** @example 123 */
  count?: number;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=400&limit=100
   */
  next?: string | null;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=200&limit=100
   */
  previous?: string | null;
  results?: Size[];
}

export interface PaginatedTermList {
  /** @example 123 */
  count?: number;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=400&limit=100
   */
  next?: string | null;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=200&limit=100
   */
  previous?: string | null;
  results?: Term[];
}

export interface PatchedAttribute {
  id?: number;
  name?: AttributeEnum;
  input_type?: InputTypeEnum;
  attribute_type?: AttributeTypeEnum[];

  /** Import wont start if this attribute isn't set in ImportFile. */
  is_required?: boolean;
}

export interface PatchedAttributeMap {
  id?: number;

  /** @format date-time */
  created_at?: string;

  /** @format date-time */
  updated_at?: string;

  /** @format date-time */
  deleted_at?: string | null;
  source?: string | null;
  name?: string;
  description?: string;
  attribute?: Attribute;
  attribute_value?: string;
  is_deleted?: boolean;
  is_blank?: boolean;
  is_mapped?: boolean;
  attribute_value_id?: number;
}

export interface PatchedChannelVariantPricingStrategy {
  id?: number;
  name?: string | null;
  description?: string | null;
  channel_product_variant?: number;
  strategy?: StrategyEnum;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  allegro_price_allowance?: string;
  allegro_price_quantity_division?: number;
  price_fight_position?: number;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  price_fight_allowance?: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  price_fight_profit_difference?: string;
  price_fight_mode?: PriceFightModeEnum;
}

export interface PatchedCreateSupplierOrdering {
  quantity?: number;
  comment?: string;
}

export interface PatchedDiscount {
  id?: number;
  name?: string;
  description?: string;
  discount_type?: DiscountTypeEnum;

  /**
   * @format decimal
   * @pattern ^\d{0,4}(?:\.\d{0,6})?$
   */
  value?: string;

  /** Flag indicating whether the discount is after-sales. */
  is_aftersale?: boolean;
  conditions?: DiscountCondition[];
}

export interface PatchedDiscountLevel {
  id?: number;
  name?: string;
  description?: string;
  source?: string;
  year?: string;
}

export interface PatchedPricingChannelVariant {
  id?: number;
  channel?: Channel;
  strategy_id?: number | null;
  strategy?: string;
  used_strategy?: string;
  channel_variant_strategy?: ChannelVariantPricingStrategy;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  unit_profit?: string | null;
  product_variant?: PricingProductVariant;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  price?: string;
}

export interface PatchedPurchaseInvoice {
  id?: number;
  external_id?: number;
  customer_name?: string;
  nip?: string | null;
  title?: string;

  /**
   * External system add date.
   * @format date-time
   */
  date?: string;
  quantity_sum?: number;
  is_checked?: boolean;
  lines?: PurchaseInvoiceLine[];
  is_waiting?: boolean;
}

export interface PatchedPurchaseInvoiceLine {
  id?: number;
  sku?: string | null;
  ean?: string | null;
  name?: string;
  product?: InvoiceProduct;
  term?: number | null;
  production_year?: string | null;
  quantity?: number;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  unit_netto_price?: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  unit_price?: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  total_netto_price?: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  total_price?: string;

  /**
   * Price List price.
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  list_price?: string | null;

  /**
   * Calculated Invoice price.
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  invoice_price?: string | null;

  /**
   * Calculated Buy price.
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  net_price?: string | null;
  is_checked?: boolean;
  calculate_avg_price?: boolean;
}

export interface PatchedUser {
  /**
   * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
   * @pattern ^[\w.@+-]+$
   */
  username?: string;

  /** Name of User */
  name?: string;

  /** @format uri */
  url?: string;
}

export enum PriceFightModeEnum {
  Ean = 'ean',
  Tread = 'tread',
  Producer = 'producer',
}

export interface PricingChannelVariant {
  id: number;
  channel: Channel;
  strategy_id: number | null;
  strategy: string;
  used_strategy: string;
  channel_variant_strategy?: ChannelVariantPricingStrategy;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  unit_profit: string | null;
  product_variant: PricingProductVariant;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  price: string;
}

export interface PricingProductVariant {
  id: number;
  sku: string;
  name?: string;
  source: Source;
  seller?: string;

  /**
   * @min 0
   * @max 2147483647
   */
  quantity: number;
  reservation?: number;
  availability?: number;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  list_price: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  invoice_price: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  net_price: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  unit_profit?: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  price?: string;
  strategy?: string;
  used_strategy?: string;
  channel?: Channel;
  sales_0_3?: number;
  sales_3_7?: number;
  sales_7_14?: number;
  sales_14_30?: number;
  sales_30_60?: number;
  sales_all?: number;
  attributes: DictProductVariantAttribute;
  channel_variant_strategy?: ChannelVariantPricingStrategy;
}

export interface Producer {
  id: number;
  name: string;

  /** @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$ */
  color?: string;
  product_type?: string;
  product_type_id?: number;

  /**
   * @min -2147483648
   * @max 2147483647
   */
  sort_order?: number | null;
}

export interface ProducerPricing {
  producer: string;
  size?: string;
  season?: string;
  sales_previous_year?: number;
  sales_current_year?: number;
  quantity?: number;
  popularity?: number;
  popularity_intyre?: number;
}

export interface ProducerReorder {
  producers: ProducerReorderDetail[];
}

export interface ProducerReorderDetail {
  id: number;

  /**
   * @min -2147483648
   * @max 2147483647
   */
  sort_order?: number | null;
}

export interface Product {
  name: string;

  /** @pattern ^[0-9]{13}$ */
  ean: string;
  code?: string | null;
  attributes: DictProductAttribute;
}

export interface ProductAttribute {
  attribute: string;
  value: string;
}

export interface ProductOnlyAttributes {
  code?: string | null;
  attributes: DictProductAttribute;
}

export interface ProductPricing {
  id: number;
  name: string;

  /** @pattern ^[0-9]{13}$ */
  ean: string;
  code?: string | null;
  producer_quantity?: number;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  current?: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  preorder?: string;
  attributes: DictProductAttribute;
  link?: string;
  sales_0_3?: number;
  sales_3_7?: number;
  sales_7_14?: number;
  sales_14_30?: number;
  sales_30_60?: number;
  sales_all?: number;
  variants: PricingProductVariant[];
}

export interface ProductPricingSize {
  id: number;
  sku?: string;

  /** @pattern ^[0-9]{13}$ */
  ean: string;
  code?: string | null;
  name: string;
  product_attributes?: DictProductAttribute;
  variant_attributes?: DictProductVariantAttribute;
  seller?: string;
  quantity?: number;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  price?: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  preorder?: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  current?: string;
  sales_0_3?: number;
  sales_3_7?: number;
  sales_7_14?: number;
  sales_14_30?: number;
  sales_30_60?: number;
  sales_60_90?: number;
  sales_90_120?: number;
  sales_all?: number;
  producer?: Producer;
}

export interface ProfitCalculator {
  channel_id: number;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  net_price?: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  price?: string;
  promoted?: boolean;
}

export interface PurchaseInvoice {
  id: number;
  external_id: number;
  customer_name: string;
  nip: string | null;
  title: string;

  /**
   * External system add date.
   * @format date-time
   */
  date: string;
  quantity_sum: number;
  is_checked: boolean;
  lines: PurchaseInvoiceLine[];
  is_waiting?: boolean;
}

export interface PurchaseInvoiceLine {
  id: number;
  sku: string | null;
  ean: string | null;
  name: string;
  product: InvoiceProduct;
  term: number | null;
  production_year: string | null;
  quantity: number;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  unit_netto_price: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  unit_price: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  total_netto_price: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  total_price: string;

  /**
   * Price List price.
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  list_price?: string | null;

  /**
   * Calculated Invoice price.
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  invoice_price?: string | null;

  /**
   * Calculated Buy price.
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  net_price?: string | null;
  is_checked?: boolean;
  calculate_avg_price?: boolean;
}

export interface Size {
  id: number;
  size: string;
  width: string;
  profile: string;
  rim: string;
  load_index: string;
  xl?: boolean;
  is_active?: boolean;
}

export interface SizePricing {
  products?: number;
  producer?: string;
  season?: string;
  size: string;
  sales_previous_year?: number;
  sales_current_year?: number;
  quantity?: number;
  popularity?: number;
  popularity_intyre?: number;
}

export interface Source {
  id: number;
  name: SourceNameEnum;
  type: TypeEnum;

  /** @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$ */
  color?: string;
}

export enum SourceNameEnum {
  Noweopony = 'noweopony',
  Bridgestone = 'bridgestone',
  Continental = 'continental',
  Goodyear = 'goodyear',
  Falken = 'falken',
  Hankook = 'hankook',
  Michelin = 'michelin',
  Kormoran = 'kormoran',
  Kleber = 'kleber',
  Nexen = 'nexen',
  Nokian = 'nokian',
  Pirelli = 'pirelli',
  Vredestein = 'vredestein',
  Yokohama = 'yokohama',
  Handlopex = 'handlopex',
  Latex = 'latex',
  Intercars = 'intercars',
  Colmec = 'colmec',
  Enis = 'enis',
  Nowegumy = 'nowegumy',
  Wim = 'wim',
  Gundlach = 'gundlach',
  EasyTire = 'easy_tire',
  Boma = 'boma',
  Interpneu = 'interpneu',
}

export enum StrategyEnum {
  Profits = 'profits',
  Manual = 'manual',
  PriceFight = 'price_fight',
  AllegroPrice = 'allegro_price',
  ProfitsAllegro = 'profits_allegro',
}

export interface SubiektEanResponse {
  ean_list: any[];
}

export interface SubiektOrderEanResponse {
  ean_list: any[];
}

export interface SubiektProductRequest {
  ean_list: any[];
}

export interface SupplierOrder {
  id: number;
  name: string;
}

export interface SupplierOrdering {
  id: number;
  sku?: string;

  /** @pattern ^[0-9]{13}$ */
  ean: string;
  code?: string | null;
  name: string;
  product_attributes?: DictProductAttribute;
  variant_attributes?: DictProductVariantAttribute;
  seller?: string;
  ordered_quantity?: number;
  noweopony_quantity?: number;
  producer_quantity?: number;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  preorder?: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  history?: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  current?: string;

  /**
   * @format decimal
   * @pattern ^\d{0,6}(?:\.\d{0,2})?$
   */
  difference?: string;
  comment?: string;
  sales_sty_lip?: number;
  sales_lut_sie?: number;
  sales_mar_wrz?: number;
  sales_kwi_paz?: number;
  sales_maj_lis?: number;
  sales_cze_gru?: number;
  sales_all?: number;
  producer?: Producer;
  production_from?: string;
}

export interface Term {
  id: number;
  name: string;
  description?: string;
  is_preorder?: boolean;
  is_current?: boolean;
  source: Source;
  year: Year;

  /** @format date-time */
  updated_at: string;

  /** @format date-time */
  created_at: string;
}

export interface Tread {
  id: number;
  name: string;
  producer: string;
  season: string;
  tyre_type: string;
  c?: boolean;
  pmsf?: boolean;
}

export enum TypeEnum {
  Us = 'us',
  Producer = 'producer',
  Wholesaler = 'wholesaler',
}

export interface TyreClass {
  id: number;
  name: string;
}

export interface UploadInvoiceToOrder {
  invoice_name: string;
  b64_invoice: string;
}

export interface User {
  /**
   * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
   * @pattern ^[\w.@+-]+$
   */
  username: string;

  /** Name of User */
  name?: string;

  /** @format uri */
  url: string;
}

export interface Year {
  id: number;
  name: string;
  is_current?: boolean;
  is_previous?: boolean;
}

export namespace ApiInterface {
  /**
   * No description
   * @tags attributes
   * @name AttributesList
   * @request GET:/api/v1/attributes/
   * @secure
   */
  export namespace AttributesList {
    export type RequestParams = {};
    export type RequestQuery = { name?: string; ordering?: string; values?: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Attribute[];
  }
  /**
   * No description
   * @tags attributes
   * @name AttributesCreate
   * @request POST:/api/v1/attributes/
   * @secure
   */
  export namespace AttributesCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Attribute;
    export type RequestHeaders = {};
    export type ResponseBody = Attribute;
  }
  /**
   * No description
   * @tags attributes
   * @name AttributesRetrieve
   * @request GET:/api/v1/attributes/{id}/
   * @secure
   */
  export namespace AttributesRetrieve {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Attribute;
  }
  /**
   * No description
   * @tags attributes
   * @name AttributesUpdate
   * @request PUT:/api/v1/attributes/{id}/
   * @secure
   */
  export namespace AttributesUpdate {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = Attribute;
    export type RequestHeaders = {};
    export type ResponseBody = Attribute;
  }
  /**
   * No description
   * @tags attributes
   * @name AttributesPartialUpdate
   * @request PATCH:/api/v1/attributes/{id}/
   * @secure
   */
  export namespace AttributesPartialUpdate {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = PatchedAttribute;
    export type RequestHeaders = {};
    export type ResponseBody = Attribute;
  }
  /**
   * No description
   * @tags attributes
   * @name AttributesDestroy
   * @request DELETE:/api/v1/attributes/{id}/
   * @secure
   */
  export namespace AttributesDestroy {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags attributes
   * @name AttributesValuesList
   * @request GET:/api/v1/attributes/{id}/values/
   * @secure
   */
  export namespace AttributesValuesList {
    export type RequestParams = { id: number };
    export type RequestQuery = {
      limit?: number;
      name?: string;
      offset?: number;
      ordering?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedAttributeValueList;
  }
  /**
   * No description
   * @tags attributes
   * @name AttributesProducersList
   * @request GET:/api/v1/attributes/producers/
   * @secure
   */
  export namespace AttributesProducersList {
    export type RequestParams = {};
    export type RequestQuery = { name?: string; ordering?: string; product_type?: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Producer[];
  }
  /**
   * No description
   * @tags attributes
   * @name AttributesProducersCreate
   * @request POST:/api/v1/attributes/producers/
   * @secure
   */
  export namespace AttributesProducersCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Producer;
    export type RequestHeaders = {};
    export type ResponseBody = Producer;
  }
  /**
   * No description
   * @tags attributes
   * @name AttributesProducersDestroy
   * @request DELETE:/api/v1/attributes/producers/{id}/
   * @secure
   */
  export namespace AttributesProducersDestroy {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags attributes
   * @name AttributesProducersReorderCreate
   * @request POST:/api/v1/attributes/producers/reorder
   * @secure
   */
  export namespace AttributesProducersReorderCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ProducerReorder;
    export type RequestHeaders = {};
    export type ResponseBody = ProducerReorder;
  }
  /**
   * No description
   * @tags attributes_map
   * @name AttributesMapList
   * @request GET:/api/v1/attributes_map/
   * @secure
   */
  export namespace AttributesMapList {
    export type RequestParams = {};
    export type RequestQuery = {
      attribute?: string;
      attribute_value?: string;
      description?: string;
      is_blank?: boolean;
      is_deleted?: boolean;
      is_mapped?: boolean;
      limit?: number;
      name?: string;
      offset?: number;
      ordering?: string;
      source?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedAttributeMapList;
  }
  /**
   * No description
   * @tags attributes_map
   * @name AttributesMapUpdate
   * @request PUT:/api/v1/attributes_map/{id}/
   * @secure
   */
  export namespace AttributesMapUpdate {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = AttributeMap;
    export type RequestHeaders = {};
    export type ResponseBody = AttributeMap;
  }
  /**
   * No description
   * @tags attributes_map
   * @name AttributesMapPartialUpdate
   * @request PATCH:/api/v1/attributes_map/{id}/
   * @secure
   */
  export namespace AttributesMapPartialUpdate {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = PatchedAttributeMap;
    export type RequestHeaders = {};
    export type ResponseBody = AttributeMap;
  }
  /**
   * No description
   * @tags attributes_map
   * @name AttributesMapDestroy
   * @request DELETE:/api/v1/attributes_map/{id}/
   * @secure
   */
  export namespace AttributesMapDestroy {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags attributes_map
   * @name AttributesMapHardDeleteDestroy
   * @request DELETE:/api/v1/attributes_map/{id}/hard_delete/
   * @secure
   */
  export namespace AttributesMapHardDeleteDestroy {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags attributes_tyre
   * @name AttributesTyreProducersTreadsList
   * @request GET:/api/v1/attributes_tyre/producers/{id}/treads/
   * @secure
   */
  export namespace AttributesTyreProducersTreadsList {
    export type RequestParams = { id: number };
    export type RequestQuery = { name?: string; ordering?: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Tread[];
  }
  /**
   * No description
   * @tags attributes_tyre
   * @name AttributesTyreProducersTreadsCreate
   * @request POST:/api/v1/attributes_tyre/producers/{id}/treads/
   * @secure
   */
  export namespace AttributesTyreProducersTreadsCreate {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = Tread;
    export type RequestHeaders = {};
    export type ResponseBody = Tread;
  }
  /**
   * No description
   * @tags attributes_tyre
   * @name AttributesTyreSizesList
   * @request GET:/api/v1/attributes_tyre/sizes/
   * @secure
   */
  export namespace AttributesTyreSizesList {
    export type RequestParams = {};
    export type RequestQuery = {
      limit?: number;
      load_index?: string;
      offset?: number;
      ordering?: string;
      size?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedSizeList;
  }
  /**
   * No description
   * @tags attributes_tyre
   * @name AttributesTyreSizesCreate
   * @request POST:/api/v1/attributes_tyre/sizes/
   * @secure
   */
  export namespace AttributesTyreSizesCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Size;
    export type RequestHeaders = {};
    export type ResponseBody = Size;
  }
  /**
   * No description
   * @tags attributes_tyre
   * @name AttributesTyreSizesDestroy
   * @request DELETE:/api/v1/attributes_tyre/sizes/{id}/
   * @secure
   */
  export namespace AttributesTyreSizesDestroy {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags attributes_tyre
   * @name AttributesTyreTyreClassesList
   * @request GET:/api/v1/attributes_tyre/tyre_classes/
   * @secure
   */
  export namespace AttributesTyreTyreClassesList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TyreClass[];
  }
  /**
   * No description
   * @tags auth
   * @name AuthLoginCreate
   * @request POST:/api/v1/auth/login
   * @secure
   */
  export namespace AuthLoginCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AuthToken;
    export type RequestHeaders = {};
    export type ResponseBody = AuthToken;
  }
  /**
   * No description
   * @tags core
   * @name CoreChannelsList
   * @request GET:/api/v1/core/channels/
   * @secure
   */
  export namespace CoreChannelsList {
    export type RequestParams = {};
    export type RequestQuery = {
      name?:
        | 'allegro_barum_polska_tyres_1x'
        | 'allegro_barum_polska_tyres_2x'
        | 'allegro_barum_polska_tyres_4x'
        | 'allegro_intyre_pl_tyres_1x'
        | 'allegro_intyre_pl_tyres_2x'
        | 'allegro_intyre_pl_tyres_4x'
        | 'allegro_noweopony_pl_tyres_1x'
        | 'allegro_noweopony_pl_tyres_2x'
        | 'allegro_noweopony_pl_tyres_4x'
        | 'personal_tyres'
        | 'platformaopon'
        | 'shop_noweopony';
      ordering?: string;
      type?: 'allegro' | 'personal' | 'shop' | 'wholesale';
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Channel[];
  }
  /**
   * No description
   * @tags core
   * @name CoreSourcesList
   * @request GET:/api/v1/core/sources/
   * @secure
   */
  export namespace CoreSourcesList {
    export type RequestParams = {};
    export type RequestQuery = {
      name?:
        | 'boma'
        | 'bridgestone'
        | 'colmec'
        | 'continental'
        | 'easy_tire'
        | 'enis'
        | 'falken'
        | 'goodyear'
        | 'gundlach'
        | 'handlopex'
        | 'hankook'
        | 'intercars'
        | 'interpneu'
        | 'kleber'
        | 'kormoran'
        | 'latex'
        | 'michelin'
        | 'nexen'
        | 'nokian'
        | 'nowegumy'
        | 'noweopony'
        | 'pirelli'
        | 'vredestein'
        | 'wim'
        | 'yokohama';
      ordering?: string;
      type?: 'producer' | 'us' | 'wholesaler';
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Source[];
  }
  /**
   * No description
   * @tags core
   * @name CoreYearsList
   * @request GET:/api/v1/core/years/
   * @secure
   */
  export namespace CoreYearsList {
    export type RequestParams = {};
    export type RequestQuery = {
      is_current?: boolean;
      is_previous?: boolean;
      name?: string;
      ordering?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Year[];
  }
  /**
   * No description
   * @tags invoices
   * @name InvoicesPurchaseList
   * @request GET:/api/v1/invoices/purchase/
   * @secure
   */
  export namespace InvoicesPurchaseList {
    export type RequestParams = {};
    export type RequestQuery = {
      is_checked?: boolean;
      is_waiting?: boolean;
      limit?: number;
      offset?: number;
      ordering?: string;
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedPurchaseInvoiceList;
  }
  /**
   * No description
   * @tags invoices
   * @name InvoicesPurchaseLinesPartialUpdate
   * @request PATCH:/api/v1/invoices/purchase/{invoice_id}/lines/{id}/
   * @secure
   */
  export namespace InvoicesPurchaseLinesPartialUpdate {
    export type RequestParams = { id: number; invoiceId: number };
    export type RequestQuery = {};
    export type RequestBody = PatchedPurchaseInvoiceLine;
    export type RequestHeaders = {};
    export type ResponseBody = PurchaseInvoiceLine;
  }
  /**
   * No description
   * @tags invoices
   * @name InvoicesPurchaseLinesAssignPricesCreate
   * @request POST:/api/v1/invoices/purchase/{invoice_id}/lines/{id}/assign_prices/
   * @secure
   */
  export namespace InvoicesPurchaseLinesAssignPricesCreate {
    export type RequestParams = { id: number; invoiceId: number };
    export type RequestQuery = {};
    export type RequestBody = AssignPrices;
    export type RequestHeaders = {};
    export type ResponseBody = PurchaseInvoice;
  }
  /**
   * No description
   * @tags invoices
   * @name InvoicesPurchaseLinesAssignTermZeroPricesCreate
   * @request POST:/api/v1/invoices/purchase/{invoice_id}/lines/{id}/assign_term_zero_prices/
   * @secure
   */
  export namespace InvoicesPurchaseLinesAssignTermZeroPricesCreate {
    export type RequestParams = { id: number; invoiceId: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PurchaseInvoice;
  }
  /**
   * @description Funkcja anuluje pozycję faktury i przelicza średnią cene dla SKU.
   * @tags invoices
   * @name InvoicesPurchaseLinesCancelLineCreate
   * @request POST:/api/v1/invoices/purchase/{invoice_id}/lines/{id}/cancel_line/
   * @secure
   */
  export namespace InvoicesPurchaseLinesCancelLineCreate {
    export type RequestParams = { id: number; invoiceId: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PurchaseInvoice;
  }
  /**
   * No description
   * @tags invoices
   * @name InvoicesPurchaseUpdate
   * @request PUT:/api/v1/invoices/purchase/{id}/
   * @secure
   */
  export namespace InvoicesPurchaseUpdate {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = PurchaseInvoice;
    export type RequestHeaders = {};
    export type ResponseBody = PurchaseInvoice;
  }
  /**
   * No description
   * @tags invoices
   * @name InvoicesPurchasePartialUpdate
   * @request PATCH:/api/v1/invoices/purchase/{id}/
   * @secure
   */
  export namespace InvoicesPurchasePartialUpdate {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = PatchedPurchaseInvoice;
    export type RequestHeaders = {};
    export type ResponseBody = PurchaseInvoice;
  }
  /**
   * No description
   * @tags invoices
   * @name InvoicesPurchaseBulkAssignPricesCreate
   * @request POST:/api/v1/invoices/purchase/{id}/bulk_assign_prices/
   * @secure
   */
  export namespace InvoicesPurchaseBulkAssignPricesCreate {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = BulkAssignPrices;
    export type RequestHeaders = {};
    export type ResponseBody = PurchaseInvoice;
  }
  /**
   * No description
   * @tags invoices
   * @name InvoicesPurchaseBulkAssignTermZeroPricesCreate
   * @request POST:/api/v1/invoices/purchase/{id}/bulk_assign_term_zero_prices/
   * @secure
   */
  export namespace InvoicesPurchaseBulkAssignTermZeroPricesCreate {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PurchaseInvoice;
  }
  /**
   * @description Funkcja przelicza średnie cene dla SKU i oznacza fakturę jako is_checked.
   * @tags invoices
   * @name InvoicesPurchaseCalculateAveragePricesCreate
   * @request POST:/api/v1/invoices/purchase/{id}/calculate_average_prices/
   * @secure
   */
  export namespace InvoicesPurchaseCalculateAveragePricesCreate {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PurchaseInvoice;
  }
  /**
   * No description
   * @tags orders
   * @name OrdersSalesList
   * @request GET:/api/v1/orders/sales/
   * @secure
   */
  export namespace OrdersSalesList {
    export type RequestParams = {};
    export type RequestQuery = {
      is_checked?: boolean;
      is_watched?: boolean;
      limit?: number;
      name?: string;
      offset?: number;
      ordering?: string;
      source?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedOrderSalesList;
  }
  /**
   * No description
   * @tags orders
   * @name OrdersSalesIsWatchedPartialUpdate
   * @request PATCH:/api/v1/orders/sales/{id}/is_watched/
   * @secure
   */
  export namespace OrdersSalesIsWatchedPartialUpdate {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * @description Funkcja oznacza wszystkie rekordy o danym sku jako is_checked=True.
   * @tags orders
   * @name OrdersSalesMarkAsCheckedPartialUpdate
   * @request PATCH:/api/v1/orders/sales/{sku}/mark_as_checked/
   * @secure
   */
  export namespace OrdersSalesMarkAsCheckedPartialUpdate {
    export type RequestParams = { sku: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags orders
   * @name OrdersUploadInvoiceCreate
   * @request POST:/api/v1/orders/upload_invoice/{external_id}/
   * @secure
   */
  export namespace OrdersUploadInvoiceCreate {
    export type RequestParams = { externalId: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UploadInvoiceToOrder;
  }
  /**
   * No description
   * @tags pricing
   * @name PricingAllegroVariantsList
   * @request GET:/api/v1/pricing/allegro_variants/
   * @secure
   */
  export namespace PricingAllegroVariantsList {
    export type RequestParams = {};
    export type RequestQuery = {
      limit?: number;
      offset?: number;
      producer?: string;
      season?: string;
      size?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedAllegroVariantList;
  }
  /**
   * No description
   * @tags pricing
   * @name PricingCalculateProfitCreate
   * @request POST:/api/v1/pricing/calculate_profit/
   * @secure
   */
  export namespace PricingCalculateProfitCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ProfitCalculator;
    export type RequestHeaders = {};
    export type ResponseBody = OutputProfitCalculator;
  }
  /**
   * No description
   * @tags pricing
   * @name PricingChannelVariantsStrategiesCreate
   * @request POST:/api/v1/pricing/channel_variants/{channel_variant_id}/strategies/
   * @secure
   */
  export namespace PricingChannelVariantsStrategiesCreate {
    export type RequestParams = { channelVariantId: number };
    export type RequestQuery = {};
    export type RequestBody = ChannelVariantPricingStrategy;
    export type RequestHeaders = {};
    export type ResponseBody = ChannelVariantPricingStrategy;
  }
  /**
   * No description
   * @tags pricing
   * @name PricingChannelVariantsStrategiesRetrieve
   * @request GET:/api/v1/pricing/channel_variants/{channel_variant_id}/strategies/{id}/
   * @secure
   */
  export namespace PricingChannelVariantsStrategiesRetrieve {
    export type RequestParams = { channelVariantId: number; id: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ChannelVariantPricingStrategy;
  }
  /**
   * No description
   * @tags pricing
   * @name PricingChannelVariantsStrategiesPartialUpdate
   * @request PATCH:/api/v1/pricing/channel_variants/{channel_variant_id}/strategies/{id}/
   * @secure
   */
  export namespace PricingChannelVariantsStrategiesPartialUpdate {
    export type RequestParams = { channelVariantId: number; id: number };
    export type RequestQuery = {};
    export type RequestBody = PatchedChannelVariantPricingStrategy;
    export type RequestHeaders = {};
    export type ResponseBody = ChannelVariantPricingStrategy;
  }
  /**
   * No description
   * @tags pricing
   * @name PricingChannelVariantsStrategiesDestroy
   * @request DELETE:/api/v1/pricing/channel_variants/{channel_variant_id}/strategies/{id}/
   * @secure
   */
  export namespace PricingChannelVariantsStrategiesDestroy {
    export type RequestParams = { channelVariantId: number; id: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags pricing
   * @name PricingChannelVariantsUpdate
   * @request PUT:/api/v1/pricing/channel_variants/{id}/
   * @secure
   */
  export namespace PricingChannelVariantsUpdate {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = PricingChannelVariant;
    export type RequestHeaders = {};
    export type ResponseBody = PricingChannelVariant;
  }
  /**
   * No description
   * @tags pricing
   * @name PricingChannelVariantsPartialUpdate
   * @request PATCH:/api/v1/pricing/channel_variants/{id}/
   * @secure
   */
  export namespace PricingChannelVariantsPartialUpdate {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = PatchedPricingChannelVariant;
    export type RequestHeaders = {};
    export type ResponseBody = PricingChannelVariant;
  }
  /**
   * No description
   * @tags pricing
   * @name PricingProducersSummaryList
   * @request GET:/api/v1/pricing/producers_summary/
   * @secure
   */
  export namespace PricingProducersSummaryList {
    export type RequestParams = {};
    export type RequestQuery = { ordering?: string; season?: string; size?: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ProducerPricing[];
  }
  /**
   * No description
   * @tags pricing
   * @name PricingProductsList
   * @request GET:/api/v1/pricing/products/
   * @secure
   */
  export namespace PricingProductsList {
    export type RequestParams = {};
    export type RequestQuery = {
      limit?: number;
      offset?: number;
      producer?: string;
      season?: string;
      size?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedProductPricingList;
  }
  /**
   * No description
   * @tags pricing
   * @name PricingProductsBySizeRetrieve
   * @request GET:/api/v1/pricing/products_by_size/
   * @secure
   */
  export namespace PricingProductsBySizeRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {
      only_ours?: boolean;
      producer?: string;
      production_year?: string[];
      quantity?: string;
      season: string;
      size: string;
      tyre_class?: string[];
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ProductPricingSize;
  }
  /**
   * No description
   * @tags pricing
   * @name PricingShopVariantsList
   * @request GET:/api/v1/pricing/shop_variants/
   * @secure
   */
  export namespace PricingShopVariantsList {
    export type RequestParams = {};
    export type RequestQuery = {
      limit?: number;
      offset?: number;
      ordering?: string;
      producer?: string;
      season?: string;
      size?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedAnalyticVariantList;
  }
  /**
   * No description
   * @tags pricing
   * @name PricingSizesSummaryList
   * @request GET:/api/v1/pricing/sizes_summary/
   * @secure
   */
  export namespace PricingSizesSummaryList {
    export type RequestParams = {};
    export type RequestQuery = { producer?: string; season?: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SizePricing[];
  }
  /**
   * No description
   * @tags subiekt
   * @name SubiektGetAllegroLinksRetrieve
   * @request GET:/api/v1/subiekt/get_allegro_links/
   * @secure
   */
  export namespace SubiektGetAllegroLinksRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SubiektEanResponse;
  }
  /**
   * No description
   * @tags subiekt
   * @name SubiektGetNewProductsCreate
   * @request POST:/api/v1/subiekt/get_new_products/
   * @secure
   */
  export namespace SubiektGetNewProductsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = SubiektProductRequest;
    export type RequestHeaders = {};
    export type ResponseBody = Product;
  }
  /**
   * No description
   * @tags subiekt
   * @name SubiektGetOrderMissingEanCreate
   * @request POST:/api/v1/subiekt/get_order_missing_ean/
   * @secure
   */
  export namespace SubiektGetOrderMissingEanCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SubiektOrderEanResponse;
  }
  /**
   * No description
   * @tags subiekt
   * @name SubiektGetPurchaseInvoicesRetrieve
   * @request GET:/api/v1/subiekt/get_purchase_invoices/
   * @secure
   */
  export namespace SubiektGetPurchaseInvoicesRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags subiekt
   * @name SubiektImportInvoicesCreate
   * @request POST:/api/v1/subiekt/import_invoices/
   * @secure
   */
  export namespace SubiektImportInvoicesCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags subiekt
   * @name SubiektImportPurchaseInvoicesCreate
   * @request POST:/api/v1/subiekt/import_purchase_invoices/
   * @secure
   */
  export namespace SubiektImportPurchaseInvoicesCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags terms
   * @name TermsList
   * @request GET:/api/v1/terms/
   * @secure
   */
  export namespace TermsList {
    export type RequestParams = {};
    export type RequestQuery = {
      limit?: number;
      name?: string;
      offset?: number;
      ordering?: string;
      source?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedTermList;
  }
  /**
   * No description
   * @tags terms
   * @name TermsDiscountLevelsList
   * @request GET:/api/v1/terms/discount_levels/
   * @secure
   */
  export namespace TermsDiscountLevelsList {
    export type RequestParams = {};
    export type RequestQuery = {
      limit?: number;
      name?: string;
      offset?: number;
      ordering?: string;
      source?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedDiscountLevelList;
  }
  /**
   * No description
   * @tags terms
   * @name TermsDiscountLevelsCreate
   * @request POST:/api/v1/terms/discount_levels/
   * @secure
   */
  export namespace TermsDiscountLevelsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DiscountLevel;
    export type RequestHeaders = {};
    export type ResponseBody = DiscountLevel;
  }
  /**
   * No description
   * @tags terms
   * @name TermsDiscountLevelsDiscountsList
   * @request GET:/api/v1/terms/discount_levels/{discount_level_id}/discounts/
   * @secure
   */
  export namespace TermsDiscountLevelsDiscountsList {
    export type RequestParams = { discountLevelId: number };
    export type RequestQuery = { discount_level?: number; name?: string; ordering?: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Discount[];
  }
  /**
   * No description
   * @tags terms
   * @name TermsDiscountLevelsDiscountsCreate
   * @request POST:/api/v1/terms/discount_levels/{discount_level_id}/discounts/
   * @secure
   */
  export namespace TermsDiscountLevelsDiscountsCreate {
    export type RequestParams = { discountLevelId: number };
    export type RequestQuery = {};
    export type RequestBody = Discount;
    export type RequestHeaders = {};
    export type ResponseBody = Discount;
  }
  /**
   * No description
   * @tags terms
   * @name TermsDiscountLevelsDiscountsRetrieve
   * @request GET:/api/v1/terms/discount_levels/{discount_level_id}/discounts/{id}/
   * @secure
   */
  export namespace TermsDiscountLevelsDiscountsRetrieve {
    export type RequestParams = { discountLevelId: number; id: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Discount;
  }
  /**
   * No description
   * @tags terms
   * @name TermsDiscountLevelsDiscountsPartialUpdate
   * @request PATCH:/api/v1/terms/discount_levels/{discount_level_id}/discounts/{id}/
   * @secure
   */
  export namespace TermsDiscountLevelsDiscountsPartialUpdate {
    export type RequestParams = { discountLevelId: number; id: number };
    export type RequestQuery = {};
    export type RequestBody = PatchedDiscount;
    export type RequestHeaders = {};
    export type ResponseBody = Discount;
  }
  /**
   * No description
   * @tags terms
   * @name TermsDiscountLevelsDiscountsDestroy
   * @request DELETE:/api/v1/terms/discount_levels/{discount_level_id}/discounts/{id}/
   * @secure
   */
  export namespace TermsDiscountLevelsDiscountsDestroy {
    export type RequestParams = { discountLevelId: number; id: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags terms
   * @name TermsDiscountLevelsUpdate
   * @request PUT:/api/v1/terms/discount_levels/{id}/
   * @secure
   */
  export namespace TermsDiscountLevelsUpdate {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = DiscountLevel;
    export type RequestHeaders = {};
    export type ResponseBody = DiscountLevel;
  }
  /**
   * No description
   * @tags terms
   * @name TermsDiscountLevelsPartialUpdate
   * @request PATCH:/api/v1/terms/discount_levels/{id}/
   * @secure
   */
  export namespace TermsDiscountLevelsPartialUpdate {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = PatchedDiscountLevel;
    export type RequestHeaders = {};
    export type ResponseBody = DiscountLevel;
  }
  /**
   * No description
   * @tags terms
   * @name TermsDiscountLevelsCloneCreate
   * @request POST:/api/v1/terms/discount_levels/{id}/clone
   * @secure
   */
  export namespace TermsDiscountLevelsCloneCreate {
    export type RequestParams = { id: number };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags terms
   * @name TermsTestRetrieve
   * @request GET:/api/v1/terms/test/
   * @secure
   */
  export namespace TermsTestRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags users
   * @name UsersList
   * @request GET:/api/v1/users/
   * @secure
   */
  export namespace UsersList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = User[];
  }
  /**
   * No description
   * @tags users
   * @name UsersRetrieve
   * @request GET:/api/v1/users/{username}/
   * @secure
   */
  export namespace UsersRetrieve {
    export type RequestParams = { username: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = User;
  }
  /**
   * No description
   * @tags users
   * @name UsersUpdate
   * @request PUT:/api/v1/users/{username}/
   * @secure
   */
  export namespace UsersUpdate {
    export type RequestParams = { username: string };
    export type RequestQuery = {};
    export type RequestBody = User;
    export type RequestHeaders = {};
    export type ResponseBody = User;
  }
  /**
   * No description
   * @tags users
   * @name UsersPartialUpdate
   * @request PATCH:/api/v1/users/{username}/
   * @secure
   */
  export namespace UsersPartialUpdate {
    export type RequestParams = { username: string };
    export type RequestQuery = {};
    export type RequestBody = PatchedUser;
    export type RequestHeaders = {};
    export type ResponseBody = User;
  }
  /**
   * No description
   * @tags users
   * @name UsersMeRetrieve
   * @request GET:/api/v1/users/me/
   * @secure
   */
  export namespace UsersMeRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = User;
  }
  /**
   * No description
   * @tags warehouse
   * @name WarehouseOrdersList
   * @request GET:/api/v1/warehouse/orders/
   * @secure
   */
  export namespace WarehouseOrdersList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierOrder[];
  }
  /**
   * No description
   * @tags warehouse
   * @name WarehouseOrdersProducerSummaryList
   * @request GET:/api/v1/warehouse/orders/{order_id}/producer_summary
   * @secure
   */
  export namespace WarehouseOrdersProducerSummaryList {
    export type RequestParams = { orderId: number };
    export type RequestQuery = { season?: string; size?: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OrderSummary[];
  }
  /**
   * No description
   * @tags warehouse
   * @name WarehouseOrdersProductsList
   * @request GET:/api/v1/warehouse/orders/{order_id}/products/
   * @secure
   */
  export namespace WarehouseOrdersProductsList {
    export type RequestParams = { orderId: number };
    export type RequestQuery = {
      producer?: string;
      producers?: string[];
      season: string;
      size: string;
      tyre_class?: string[];
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierOrdering[];
  }
  /**
   * No description
   * @tags warehouse
   * @name WarehouseOrdersProductsPartialUpdate
   * @request PATCH:/api/v1/warehouse/orders/{order_id}/products/{ean}/
   * @secure
   */
  export namespace WarehouseOrdersProductsPartialUpdate {
    export type RequestParams = { ean: string; orderId: number };
    export type RequestQuery = {};
    export type RequestBody = PatchedCreateSupplierOrdering;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags warehouse
   * @name WarehouseOrdersSizeSummaryList
   * @request GET:/api/v1/warehouse/orders/{order_id}/size_summary
   * @secure
   */
  export namespace WarehouseOrdersSizeSummaryList {
    export type RequestParams = { orderId: number };
    export type RequestQuery = { producer?: string; producers?: string[]; season: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OrderSummary[];
  }
}

export namespace Files {
  /**
   * @description Plik do generowania oferty na platformaopon.pl.
   * @tags files
   * @name FilesDfsgunl345Ofdgsu34Retrieve
   * @request GET:/files/dfsgunl345ofdgsu34/
   * @secure
   */
  export namespace FilesDfsgunl345Ofdgsu34Retrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace Schema {
  /**
   * @description OpenApi3 schema for this API. Format can be selected via content negotiation. - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
   * @tags schema
   * @name SchemaRetrieve
   * @request GET:/schema/
   * @secure
   */
  export namespace SchemaRetrieve {
    export type RequestParams = {};
    export type RequestQuery = { format?: 'json' | 'yaml' };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
          ? JSON.stringify(property)
          : `${property}`,
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      requestParams.headers.common = { Accept: '*/*' };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Your Project API
 * @version 1.0.0
 *
 * Your project description
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags attributes
     * @name AttributesList
     * @request GET:/api/v1/attributes/
     * @secure
     */
    attributesList: (
      query?: { name?: string; ordering?: string; values?: string },
      params: RequestParams = {},
    ) =>
      this.request<Attribute[], any>({
        path: `/api/v1/attributes/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes
     * @name AttributesCreate
     * @request POST:/api/v1/attributes/
     * @secure
     */
    attributesCreate: (data: Attribute, params: RequestParams = {}) =>
      this.request<Attribute, any>({
        path: `/api/v1/attributes/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes
     * @name AttributesRetrieve
     * @request GET:/api/v1/attributes/{id}/
     * @secure
     */
    attributesRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<Attribute, any>({
        path: `/api/v1/attributes/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes
     * @name AttributesUpdate
     * @request PUT:/api/v1/attributes/{id}/
     * @secure
     */
    attributesUpdate: (id: number, data: Attribute, params: RequestParams = {}) =>
      this.request<Attribute, any>({
        path: `/api/v1/attributes/${id}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes
     * @name AttributesPartialUpdate
     * @request PATCH:/api/v1/attributes/{id}/
     * @secure
     */
    attributesPartialUpdate: (id: number, data: PatchedAttribute, params: RequestParams = {}) =>
      this.request<Attribute, any>({
        path: `/api/v1/attributes/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes
     * @name AttributesDestroy
     * @request DELETE:/api/v1/attributes/{id}/
     * @secure
     */
    attributesDestroy: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/attributes/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes
     * @name AttributesValuesList
     * @request GET:/api/v1/attributes/{id}/values/
     * @secure
     */
    attributesValuesList: (
      id: number,
      query?: { limit?: number; name?: string; offset?: number; ordering?: string },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedAttributeValueList, any>({
        path: `/api/v1/attributes/${id}/values/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes
     * @name AttributesProducersList
     * @request GET:/api/v1/attributes/producers/
     * @secure
     */
    attributesProducersList: (
      query?: { name?: string; ordering?: string; product_type?: string },
      params: RequestParams = {},
    ) =>
      this.request<Producer[], any>({
        path: `/api/v1/attributes/producers/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes
     * @name AttributesProducersCreate
     * @request POST:/api/v1/attributes/producers/
     * @secure
     */
    attributesProducersCreate: (data: Producer, params: RequestParams = {}) =>
      this.request<Producer, any>({
        path: `/api/v1/attributes/producers/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes
     * @name AttributesProducersDestroy
     * @request DELETE:/api/v1/attributes/producers/{id}/
     * @secure
     */
    attributesProducersDestroy: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/attributes/producers/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes
     * @name AttributesProducersReorderCreate
     * @request POST:/api/v1/attributes/producers/reorder
     * @secure
     */
    attributesProducersReorderCreate: (data: ProducerReorder, params: RequestParams = {}) =>
      this.request<ProducerReorder, any>({
        path: `/api/v1/attributes/producers/reorder`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes_map
     * @name AttributesMapList
     * @request GET:/api/v1/attributes_map/
     * @secure
     */
    attributesMapList: (
      query?: {
        attribute?: string;
        attribute_value?: string;
        description?: string;
        is_blank?: boolean;
        is_deleted?: boolean;
        is_mapped?: boolean;
        limit?: number;
        name?: string;
        offset?: number;
        ordering?: string;
        source?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedAttributeMapList, any>({
        path: `/api/v1/attributes_map/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes_map
     * @name AttributesMapUpdate
     * @request PUT:/api/v1/attributes_map/{id}/
     * @secure
     */
    attributesMapUpdate: (id: number, data: AttributeMap, params: RequestParams = {}) =>
      this.request<AttributeMap, any>({
        path: `/api/v1/attributes_map/${id}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes_map
     * @name AttributesMapPartialUpdate
     * @request PATCH:/api/v1/attributes_map/{id}/
     * @secure
     */
    attributesMapPartialUpdate: (
      id: number,
      data: PatchedAttributeMap,
      params: RequestParams = {},
    ) =>
      this.request<AttributeMap, any>({
        path: `/api/v1/attributes_map/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes_map
     * @name AttributesMapDestroy
     * @request DELETE:/api/v1/attributes_map/{id}/
     * @secure
     */
    attributesMapDestroy: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/attributes_map/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes_map
     * @name AttributesMapHardDeleteDestroy
     * @request DELETE:/api/v1/attributes_map/{id}/hard_delete/
     * @secure
     */
    attributesMapHardDeleteDestroy: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/attributes_map/${id}/hard_delete/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes_tyre
     * @name AttributesTyreProducersTreadsList
     * @request GET:/api/v1/attributes_tyre/producers/{id}/treads/
     * @secure
     */
    attributesTyreProducersTreadsList: (
      id: number,
      query?: { name?: string; ordering?: string },
      params: RequestParams = {},
    ) =>
      this.request<Tread[], any>({
        path: `/api/v1/attributes_tyre/producers/${id}/treads/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes_tyre
     * @name AttributesTyreProducersTreadsCreate
     * @request POST:/api/v1/attributes_tyre/producers/{id}/treads/
     * @secure
     */
    attributesTyreProducersTreadsCreate: (id: number, data: Tread, params: RequestParams = {}) =>
      this.request<Tread, any>({
        path: `/api/v1/attributes_tyre/producers/${id}/treads/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes_tyre
     * @name AttributesTyreSizesList
     * @request GET:/api/v1/attributes_tyre/sizes/
     * @secure
     */
    attributesTyreSizesList: (
      query?: {
        limit?: number;
        load_index?: string;
        offset?: number;
        ordering?: string;
        size?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedSizeList, any>({
        path: `/api/v1/attributes_tyre/sizes/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes_tyre
     * @name AttributesTyreSizesCreate
     * @request POST:/api/v1/attributes_tyre/sizes/
     * @secure
     */
    attributesTyreSizesCreate: (data: Size, params: RequestParams = {}) =>
      this.request<Size, any>({
        path: `/api/v1/attributes_tyre/sizes/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes_tyre
     * @name AttributesTyreSizesDestroy
     * @request DELETE:/api/v1/attributes_tyre/sizes/{id}/
     * @secure
     */
    attributesTyreSizesDestroy: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/attributes_tyre/sizes/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags attributes_tyre
     * @name AttributesTyreTyreClassesList
     * @request GET:/api/v1/attributes_tyre/tyre_classes/
     * @secure
     */
    attributesTyreTyreClassesList: (params: RequestParams = {}) =>
      this.request<TyreClass[], any>({
        path: `/api/v1/attributes_tyre/tyre_classes/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthLoginCreate
     * @request POST:/api/v1/auth/login
     * @secure
     */
    authLoginCreate: (data: AuthToken, params: RequestParams = {}) =>
      this.request<AuthToken, any>({
        path: `/api/v1/auth/login`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreChannelsList
     * @request GET:/api/v1/core/channels/
     * @secure
     */
    coreChannelsList: (
      query?: {
        name?:
          | 'allegro_barum_polska_tyres_1x'
          | 'allegro_barum_polska_tyres_2x'
          | 'allegro_barum_polska_tyres_4x'
          | 'allegro_intyre_pl_tyres_1x'
          | 'allegro_intyre_pl_tyres_2x'
          | 'allegro_intyre_pl_tyres_4x'
          | 'allegro_noweopony_pl_tyres_1x'
          | 'allegro_noweopony_pl_tyres_2x'
          | 'allegro_noweopony_pl_tyres_4x'
          | 'personal_tyres'
          | 'platformaopon'
          | 'shop_noweopony';
        ordering?: string;
        type?: 'allegro' | 'personal' | 'shop' | 'wholesale';
      },
      params: RequestParams = {},
    ) =>
      this.request<Channel[], any>({
        path: `/api/v1/core/channels/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreSourcesList
     * @request GET:/api/v1/core/sources/
     * @secure
     */
    coreSourcesList: (
      query?: {
        name?:
          | 'boma'
          | 'bridgestone'
          | 'colmec'
          | 'continental'
          | 'easy_tire'
          | 'enis'
          | 'falken'
          | 'goodyear'
          | 'gundlach'
          | 'handlopex'
          | 'hankook'
          | 'intercars'
          | 'interpneu'
          | 'kleber'
          | 'kormoran'
          | 'latex'
          | 'michelin'
          | 'nexen'
          | 'nokian'
          | 'nowegumy'
          | 'noweopony'
          | 'pirelli'
          | 'vredestein'
          | 'wim'
          | 'yokohama';
        ordering?: string;
        type?: 'producer' | 'us' | 'wholesaler';
      },
      params: RequestParams = {},
    ) =>
      this.request<Source[], any>({
        path: `/api/v1/core/sources/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreYearsList
     * @request GET:/api/v1/core/years/
     * @secure
     */
    coreYearsList: (
      query?: { is_current?: boolean; is_previous?: boolean; name?: string; ordering?: string },
      params: RequestParams = {},
    ) =>
      this.request<Year[], any>({
        path: `/api/v1/core/years/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoices
     * @name InvoicesPurchaseList
     * @request GET:/api/v1/invoices/purchase/
     * @secure
     */
    invoicesPurchaseList: (
      query?: {
        is_checked?: boolean;
        is_waiting?: boolean;
        limit?: number;
        offset?: number;
        ordering?: string;
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedPurchaseInvoiceList, any>({
        path: `/api/v1/invoices/purchase/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoices
     * @name InvoicesPurchaseLinesPartialUpdate
     * @request PATCH:/api/v1/invoices/purchase/{invoice_id}/lines/{id}/
     * @secure
     */
    invoicesPurchaseLinesPartialUpdate: (
      id: number,
      invoiceId: number,
      data: PatchedPurchaseInvoiceLine,
      params: RequestParams = {},
    ) =>
      this.request<PurchaseInvoiceLine, any>({
        path: `/api/v1/invoices/purchase/${invoiceId}/lines/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoices
     * @name InvoicesPurchaseLinesAssignPricesCreate
     * @request POST:/api/v1/invoices/purchase/{invoice_id}/lines/{id}/assign_prices/
     * @secure
     */
    invoicesPurchaseLinesAssignPricesCreate: (
      id: number,
      invoiceId: number,
      data: AssignPrices,
      params: RequestParams = {},
    ) =>
      this.request<PurchaseInvoice, any>({
        path: `/api/v1/invoices/purchase/${invoiceId}/lines/${id}/assign_prices/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoices
     * @name InvoicesPurchaseLinesAssignTermZeroPricesCreate
     * @request POST:/api/v1/invoices/purchase/{invoice_id}/lines/{id}/assign_term_zero_prices/
     * @secure
     */
    invoicesPurchaseLinesAssignTermZeroPricesCreate: (
      id: number,
      invoiceId: number,
      params: RequestParams = {},
    ) =>
      this.request<PurchaseInvoice, any>({
        path: `/api/v1/invoices/purchase/${invoiceId}/lines/${id}/assign_term_zero_prices/`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Funkcja anuluje pozycję faktury i przelicza średnią cene dla SKU.
     *
     * @tags invoices
     * @name InvoicesPurchaseLinesCancelLineCreate
     * @request POST:/api/v1/invoices/purchase/{invoice_id}/lines/{id}/cancel_line/
     * @secure
     */
    invoicesPurchaseLinesCancelLineCreate: (
      id: number,
      invoiceId: number,
      params: RequestParams = {},
    ) =>
      this.request<PurchaseInvoice, any>({
        path: `/api/v1/invoices/purchase/${invoiceId}/lines/${id}/cancel_line/`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoices
     * @name InvoicesPurchaseUpdate
     * @request PUT:/api/v1/invoices/purchase/{id}/
     * @secure
     */
    invoicesPurchaseUpdate: (id: number, data: PurchaseInvoice, params: RequestParams = {}) =>
      this.request<PurchaseInvoice, any>({
        path: `/api/v1/invoices/purchase/${id}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoices
     * @name InvoicesPurchasePartialUpdate
     * @request PATCH:/api/v1/invoices/purchase/{id}/
     * @secure
     */
    invoicesPurchasePartialUpdate: (
      id: number,
      data: PatchedPurchaseInvoice,
      params: RequestParams = {},
    ) =>
      this.request<PurchaseInvoice, any>({
        path: `/api/v1/invoices/purchase/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoices
     * @name InvoicesPurchaseBulkAssignPricesCreate
     * @request POST:/api/v1/invoices/purchase/{id}/bulk_assign_prices/
     * @secure
     */
    invoicesPurchaseBulkAssignPricesCreate: (
      id: number,
      data: BulkAssignPrices,
      params: RequestParams = {},
    ) =>
      this.request<PurchaseInvoice, any>({
        path: `/api/v1/invoices/purchase/${id}/bulk_assign_prices/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoices
     * @name InvoicesPurchaseBulkAssignTermZeroPricesCreate
     * @request POST:/api/v1/invoices/purchase/{id}/bulk_assign_term_zero_prices/
     * @secure
     */
    invoicesPurchaseBulkAssignTermZeroPricesCreate: (id: number, params: RequestParams = {}) =>
      this.request<PurchaseInvoice, any>({
        path: `/api/v1/invoices/purchase/${id}/bulk_assign_term_zero_prices/`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Funkcja przelicza średnie cene dla SKU i oznacza fakturę jako is_checked.
     *
     * @tags invoices
     * @name InvoicesPurchaseCalculateAveragePricesCreate
     * @request POST:/api/v1/invoices/purchase/{id}/calculate_average_prices/
     * @secure
     */
    invoicesPurchaseCalculateAveragePricesCreate: (id: number, params: RequestParams = {}) =>
      this.request<PurchaseInvoice, any>({
        path: `/api/v1/invoices/purchase/${id}/calculate_average_prices/`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags orders
     * @name OrdersSalesList
     * @request GET:/api/v1/orders/sales/
     * @secure
     */
    ordersSalesList: (
      query?: {
        is_checked?: boolean;
        is_watched?: boolean;
        limit?: number;
        name?: string;
        offset?: number;
        ordering?: string;
        source?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedOrderSalesList, any>({
        path: `/api/v1/orders/sales/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags orders
     * @name OrdersSalesIsWatchedPartialUpdate
     * @request PATCH:/api/v1/orders/sales/{id}/is_watched/
     * @secure
     */
    ordersSalesIsWatchedPartialUpdate: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/orders/sales/${id}/is_watched/`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * @description Funkcja oznacza wszystkie rekordy o danym sku jako is_checked=True.
     *
     * @tags orders
     * @name OrdersSalesMarkAsCheckedPartialUpdate
     * @request PATCH:/api/v1/orders/sales/{sku}/mark_as_checked/
     * @secure
     */
    ordersSalesMarkAsCheckedPartialUpdate: (sku: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/orders/sales/${sku}/mark_as_checked/`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags orders
     * @name OrdersUploadInvoiceCreate
     * @request POST:/api/v1/orders/upload_invoice/{external_id}/
     * @secure
     */
    ordersUploadInvoiceCreate: (externalId: number, params: RequestParams = {}) =>
      this.request<UploadInvoiceToOrder, any>({
        path: `/api/v1/orders/upload_invoice/${externalId}/`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pricing
     * @name PricingAllegroVariantsList
     * @request GET:/api/v1/pricing/allegro_variants/
     * @secure
     */
    pricingAllegroVariantsList: (
      query?: {
        limit?: number;
        offset?: number;
        producer?: string;
        season?: string;
        size?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedAllegroVariantList, any>({
        path: `/api/v1/pricing/allegro_variants/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pricing
     * @name PricingCalculateProfitCreate
     * @request POST:/api/v1/pricing/calculate_profit/
     * @secure
     */
    pricingCalculateProfitCreate: (data: ProfitCalculator, params: RequestParams = {}) =>
      this.request<OutputProfitCalculator, any>({
        path: `/api/v1/pricing/calculate_profit/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pricing
     * @name PricingChannelVariantsStrategiesCreate
     * @request POST:/api/v1/pricing/channel_variants/{channel_variant_id}/strategies/
     * @secure
     */
    pricingChannelVariantsStrategiesCreate: (
      channelVariantId: number,
      data: ChannelVariantPricingStrategy,
      params: RequestParams = {},
    ) =>
      this.request<ChannelVariantPricingStrategy, any>({
        path: `/api/v1/pricing/channel_variants/${channelVariantId}/strategies/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pricing
     * @name PricingChannelVariantsStrategiesRetrieve
     * @request GET:/api/v1/pricing/channel_variants/{channel_variant_id}/strategies/{id}/
     * @secure
     */
    pricingChannelVariantsStrategiesRetrieve: (
      channelVariantId: number,
      id: number,
      params: RequestParams = {},
    ) =>
      this.request<ChannelVariantPricingStrategy, any>({
        path: `/api/v1/pricing/channel_variants/${channelVariantId}/strategies/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pricing
     * @name PricingChannelVariantsStrategiesPartialUpdate
     * @request PATCH:/api/v1/pricing/channel_variants/{channel_variant_id}/strategies/{id}/
     * @secure
     */
    pricingChannelVariantsStrategiesPartialUpdate: (
      channelVariantId: number,
      id: number,
      data: PatchedChannelVariantPricingStrategy,
      params: RequestParams = {},
    ) =>
      this.request<ChannelVariantPricingStrategy, any>({
        path: `/api/v1/pricing/channel_variants/${channelVariantId}/strategies/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pricing
     * @name PricingChannelVariantsStrategiesDestroy
     * @request DELETE:/api/v1/pricing/channel_variants/{channel_variant_id}/strategies/{id}/
     * @secure
     */
    pricingChannelVariantsStrategiesDestroy: (
      channelVariantId: number,
      id: number,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/pricing/channel_variants/${channelVariantId}/strategies/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags pricing
     * @name PricingChannelVariantsUpdate
     * @request PUT:/api/v1/pricing/channel_variants/{id}/
     * @secure
     */
    pricingChannelVariantsUpdate: (
      id: number,
      data: PricingChannelVariant,
      params: RequestParams = {},
    ) =>
      this.request<PricingChannelVariant, any>({
        path: `/api/v1/pricing/channel_variants/${id}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pricing
     * @name PricingChannelVariantsPartialUpdate
     * @request PATCH:/api/v1/pricing/channel_variants/{id}/
     * @secure
     */
    pricingChannelVariantsPartialUpdate: (
      id: number,
      data: PatchedPricingChannelVariant,
      params: RequestParams = {},
    ) =>
      this.request<PricingChannelVariant, any>({
        path: `/api/v1/pricing/channel_variants/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pricing
     * @name PricingProducersSummaryList
     * @request GET:/api/v1/pricing/producers_summary/
     * @secure
     */
    pricingProducersSummaryList: (
      query?: { ordering?: string; season?: string; size?: string },
      params: RequestParams = {},
    ) =>
      this.request<ProducerPricing[], any>({
        path: `/api/v1/pricing/producers_summary/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pricing
     * @name PricingProductsList
     * @request GET:/api/v1/pricing/products/
     * @secure
     */
    pricingProductsList: (
      query?: {
        limit?: number;
        offset?: number;
        producer?: string;
        season?: string;
        size?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedProductPricingList, any>({
        path: `/api/v1/pricing/products/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pricing
     * @name PricingProductsBySizeRetrieve
     * @request GET:/api/v1/pricing/products_by_size/
     * @secure
     */
    pricingProductsBySizeRetrieve: (
      query: {
        only_ours?: boolean;
        producer?: string;
        production_year?: string[];
        quantity?: string;
        season: string;
        size: string;
        tyre_class?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductPricingSize[], any>({
        path: `/api/v1/pricing/products_by_size/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pricing
     * @name PricingShopVariantsList
     * @request GET:/api/v1/pricing/shop_variants/
     * @secure
     */
    pricingShopVariantsList: (
      query?: {
        limit?: number;
        offset?: number;
        ordering?: string;
        producer?: string;
        season?: string;
        size?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedAnalyticVariantList, any>({
        path: `/api/v1/pricing/shop_variants/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags pricing
     * @name PricingSizesSummaryList
     * @request GET:/api/v1/pricing/sizes_summary/
     * @secure
     */
    pricingSizesSummaryList: (
      query?: { producer?: string; season?: string },
      params: RequestParams = {},
    ) =>
      this.request<SizePricing[], any>({
        path: `/api/v1/pricing/sizes_summary/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags subiekt
     * @name SubiektGetAllegroLinksRetrieve
     * @request GET:/api/v1/subiekt/get_allegro_links/
     * @secure
     */
    subiektGetAllegroLinksRetrieve: (params: RequestParams = {}) =>
      this.request<SubiektEanResponse, any>({
        path: `/api/v1/subiekt/get_allegro_links/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags subiekt
     * @name SubiektGetNewProductsCreate
     * @request POST:/api/v1/subiekt/get_new_products/
     * @secure
     */
    subiektGetNewProductsCreate: (data: SubiektProductRequest, params: RequestParams = {}) =>
      this.request<Product, any>({
        path: `/api/v1/subiekt/get_new_products/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags subiekt
     * @name SubiektGetOrderMissingEanCreate
     * @request POST:/api/v1/subiekt/get_order_missing_ean/
     * @secure
     */
    subiektGetOrderMissingEanCreate: (params: RequestParams = {}) =>
      this.request<SubiektOrderEanResponse, any>({
        path: `/api/v1/subiekt/get_order_missing_ean/`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags subiekt
     * @name SubiektGetPurchaseInvoicesRetrieve
     * @request GET:/api/v1/subiekt/get_purchase_invoices/
     * @secure
     */
    subiektGetPurchaseInvoicesRetrieve: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/subiekt/get_purchase_invoices/`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags subiekt
     * @name SubiektImportInvoicesCreate
     * @request POST:/api/v1/subiekt/import_invoices/
     * @secure
     */
    subiektImportInvoicesCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/subiekt/import_invoices/`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags subiekt
     * @name SubiektImportPurchaseInvoicesCreate
     * @request POST:/api/v1/subiekt/import_purchase_invoices/
     * @secure
     */
    subiektImportPurchaseInvoicesCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/subiekt/import_purchase_invoices/`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags terms
     * @name TermsList
     * @request GET:/api/v1/terms/
     * @secure
     */
    termsList: (
      query?: {
        limit?: number;
        name?: string;
        offset?: number;
        ordering?: string;
        source?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedTermList, any>({
        path: `/api/v1/terms/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags terms
     * @name TermsDiscountLevelsList
     * @request GET:/api/v1/terms/discount_levels/
     * @secure
     */
    termsDiscountLevelsList: (
      query?: {
        limit?: number;
        name?: string;
        offset?: number;
        ordering?: string;
        source?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedDiscountLevelList, any>({
        path: `/api/v1/terms/discount_levels/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags terms
     * @name TermsDiscountLevelsCreate
     * @request POST:/api/v1/terms/discount_levels/
     * @secure
     */
    termsDiscountLevelsCreate: (data: DiscountLevel, params: RequestParams = {}) =>
      this.request<DiscountLevel, any>({
        path: `/api/v1/terms/discount_levels/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags terms
     * @name TermsDiscountLevelsDiscountsList
     * @request GET:/api/v1/terms/discount_levels/{discount_level_id}/discounts/
     * @secure
     */
    termsDiscountLevelsDiscountsList: (
      discountLevelId: number,
      query?: { discount_level?: number; name?: string; ordering?: string },
      params: RequestParams = {},
    ) =>
      this.request<Discount[], any>({
        path: `/api/v1/terms/discount_levels/${discountLevelId}/discounts/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags terms
     * @name TermsDiscountLevelsDiscountsCreate
     * @request POST:/api/v1/terms/discount_levels/{discount_level_id}/discounts/
     * @secure
     */
    termsDiscountLevelsDiscountsCreate: (
      discountLevelId: number,
      data: Discount,
      params: RequestParams = {},
    ) =>
      this.request<Discount, any>({
        path: `/api/v1/terms/discount_levels/${discountLevelId}/discounts/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags terms
     * @name TermsDiscountLevelsDiscountsRetrieve
     * @request GET:/api/v1/terms/discount_levels/{discount_level_id}/discounts/{id}/
     * @secure
     */
    termsDiscountLevelsDiscountsRetrieve: (
      discountLevelId: number,
      id: number,
      params: RequestParams = {},
    ) =>
      this.request<Discount, any>({
        path: `/api/v1/terms/discount_levels/${discountLevelId}/discounts/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags terms
     * @name TermsDiscountLevelsDiscountsPartialUpdate
     * @request PATCH:/api/v1/terms/discount_levels/{discount_level_id}/discounts/{id}/
     * @secure
     */
    termsDiscountLevelsDiscountsPartialUpdate: (
      discountLevelId: number,
      id: number,
      data: PatchedDiscount,
      params: RequestParams = {},
    ) =>
      this.request<Discount, any>({
        path: `/api/v1/terms/discount_levels/${discountLevelId}/discounts/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags terms
     * @name TermsDiscountLevelsDiscountsDestroy
     * @request DELETE:/api/v1/terms/discount_levels/{discount_level_id}/discounts/{id}/
     * @secure
     */
    termsDiscountLevelsDiscountsDestroy: (
      discountLevelId: number,
      id: number,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/terms/discount_levels/${discountLevelId}/discounts/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags terms
     * @name TermsDiscountLevelsUpdate
     * @request PUT:/api/v1/terms/discount_levels/{id}/
     * @secure
     */
    termsDiscountLevelsUpdate: (id: number, data: DiscountLevel, params: RequestParams = {}) =>
      this.request<DiscountLevel, any>({
        path: `/api/v1/terms/discount_levels/${id}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags terms
     * @name TermsDiscountLevelsPartialUpdate
     * @request PATCH:/api/v1/terms/discount_levels/{id}/
     * @secure
     */
    termsDiscountLevelsPartialUpdate: (
      id: number,
      data: PatchedDiscountLevel,
      params: RequestParams = {},
    ) =>
      this.request<DiscountLevel, any>({
        path: `/api/v1/terms/discount_levels/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags terms
     * @name TermsDiscountLevelsCloneCreate
     * @request POST:/api/v1/terms/discount_levels/{id}/clone
     * @secure
     */
    termsDiscountLevelsCloneCreate: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/terms/discount_levels/${id}/clone`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags terms
     * @name TermsTestRetrieve
     * @request GET:/api/v1/terms/test/
     * @secure
     */
    termsTestRetrieve: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/terms/test/`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersList
     * @request GET:/api/v1/users/
     * @secure
     */
    usersList: (params: RequestParams = {}) =>
      this.request<User[], any>({
        path: `/api/v1/users/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersRetrieve
     * @request GET:/api/v1/users/{username}/
     * @secure
     */
    usersRetrieve: (username: string, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/v1/users/${username}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersUpdate
     * @request PUT:/api/v1/users/{username}/
     * @secure
     */
    usersUpdate: (username: string, data: User, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/v1/users/${username}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersPartialUpdate
     * @request PATCH:/api/v1/users/{username}/
     * @secure
     */
    usersPartialUpdate: (username: string, data: PatchedUser, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/v1/users/${username}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersMeRetrieve
     * @request GET:/api/v1/users/me/
     * @secure
     */
    usersMeRetrieve: (params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/v1/users/me/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags warehouse
     * @name WarehouseOrdersList
     * @request GET:/api/v1/warehouse/orders/
     * @secure
     */
    warehouseOrdersList: (params: RequestParams = {}) =>
      this.request<SupplierOrder[], any>({
        path: `/api/v1/warehouse/orders/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags warehouse
     * @name WarehouseOrdersProducerSummaryList
     * @request GET:/api/v1/warehouse/orders/{order_id}/producer_summary
     * @secure
     */
    warehouseOrdersProducerSummaryList: (
      orderId: number,
      query?: { season?: string; size?: string },
      params: RequestParams = {},
    ) =>
      this.request<OrderSummary[], any>({
        path: `/api/v1/warehouse/orders/${orderId}/producer_summary`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags warehouse
     * @name WarehouseOrdersProductsList
     * @request GET:/api/v1/warehouse/orders/{order_id}/products/
     * @secure
     */
    warehouseOrdersProductsList: (
      orderId: number,
      query: {
        producer?: string;
        producers?: string[];
        season: string;
        size: string;
        tyre_class?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<SupplierOrdering[], any>({
        path: `/api/v1/warehouse/orders/${orderId}/products/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags warehouse
     * @name WarehouseOrdersProductsPartialUpdate
     * @request PATCH:/api/v1/warehouse/orders/{order_id}/products/{ean}/
     * @secure
     */
    warehouseOrdersProductsPartialUpdate: (
      ean: string,
      orderId: number,
      data: PatchedCreateSupplierOrdering,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/warehouse/orders/${orderId}/products/${ean}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags warehouse
     * @name WarehouseOrdersSizeSummaryList
     * @request GET:/api/v1/warehouse/orders/{order_id}/size_summary
     * @secure
     */
    warehouseOrdersSizeSummaryList: (
      orderId: number,
      query: { producer?: string; producers?: string[]; season: string },
      params: RequestParams = {},
    ) =>
      this.request<OrderSummary[], any>({
        path: `/api/v1/warehouse/orders/${orderId}/size_summary`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  files = {
    /**
     * @description Plik do generowania oferty na platformaopon.pl.
     *
     * @tags files
     * @name FilesDfsgunl345Ofdgsu34Retrieve
     * @request GET:/files/dfsgunl345ofdgsu34/
     * @secure
     */
    filesDfsgunl345Ofdgsu34Retrieve: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/files/dfsgunl345ofdgsu34/`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  schema = {
    /**
     * @description OpenApi3 schema for this API. Format can be selected via content negotiation. - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     *
     * @tags schema
     * @name SchemaRetrieve
     * @request GET:/schema/
     * @secure
     */
    schemaRetrieve: (query?: { format?: 'json' | 'yaml' }, params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/schema/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
