import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ApiInterface, Producer } from '@/types/Api';
import { QueryKeys } from '@/types/QueryKeys.types';
import { API } from '@/utils/request.utils';

export const getProducersQuery = () => {
  return useQuery(QueryKeys.producers, () => API.api.attributesProducersList(), {
    select: (data) => data.data,
  });
};

export const getAttributeQuery = (query: ApiInterface.AttributesList.RequestQuery = {}) => {
  return useQuery([QueryKeys.attributes, query], () => API.api.attributesList(query), {
    select: (data) => data.data,
    keepPreviousData: true,
  });
};

export const getAttributeValuesQuery = (
  id: number | null,
  query: ApiInterface.AttributesValuesList.RequestQuery = {},
) => {
  return useQuery(
    [
      QueryKeys.attributesValues,
      {
        id,
        ...query,
      },
    ],
    () => API.api.attributesValuesList(id as number, query),
    {
      select: (data) => data.data,
      enabled: !!id,
      keepPreviousData: true,
    },
  );
};

export const createAttributeProducerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(API.api.attributesProducersCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.producers]);
    },
  });
};

export const postReorderAttributeProducerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (producers: Producer[]) =>
      API.api.attributesProducersReorderCreate({
        producers: producers.map((producer) => ({
          id: producer.id as number,
          sort_order: producer.sort_order as number,
        })),
      }),
    {
      onMutate: (producers) => {
        const queryData = queryClient.getQueriesData([QueryKeys.producers]);
        queryClient.setQueryData(QueryKeys.producers, () => ({
          ...queryData,
          data: producers,
        }));
      },
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.producers]);
      },
    },
  );
};
