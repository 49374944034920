import { Button, message, Space, Tag } from 'antd';

import useT from '@/hooks/useT';
import { deleteDiscountLevelDiscountMutation } from '@/queries/discountLevels.queries';
import { Discount, DiscountTypeEnum } from '@/types/Api';
import { formatError } from '@/utils/error.utils';

type OpenAddEditCopyDiscountModal = (d: Discount, shouldEdit?: boolean) => void;

const useDiscountLevelDiscountsColumns = (
  openAddEditCopyDiscountModal: OpenAddEditCopyDiscountModal,
  discountLevelId: number,
) => {
  const t = useT();

  const deleteDiscountMutator = deleteDiscountLevelDiscountMutation();
  const deleteDiscount = async (discountId: number) => {
    try {
      await deleteDiscountMutator.mutateAsync({
        discountId,
        discountLevelId,
      });
      message.success(t('termsDiscountLevelDiscount.removeSuccessMessage'));
      close();
    } catch (err) {
      message.error(formatError(err));
    }
  };

  return [
    {
      title: t('termsDiscountLevelDiscount.conditionsLabel'),
      key: 'conditions',
      dataIndex: 'conditions',
      render: (conditions: string, record: Discount) => (
        <div>
          {record.conditions?.map((condition) => (
            <Tag color="blue" key={condition.attribute_id}>
              {condition.attribute_value}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: t('termsDiscountLevelDiscount.valueLabel'),
      dataIndex: 'value',
      key: 'value',
      render: (value: string, record: Discount) => (
        <>
          <strong>
            {record.discount_type === DiscountTypeEnum.Percent
              ? Math.round(Number(value) * 100 * 100) / 100
              : Number(value).toFixed(2)}
          </strong>
          {`${record.discount_type === DiscountTypeEnum.Percent ? '%' : 'PLN'}`}
        </>
      ),
    },
    {
      title: t('termsDiscountLevelDiscount.isAfterSaleLabel'),
      dataIndex: 'is_aftersale',
      key: 'is_aftersale',
      render: (value: string) => <>{value ? <span>✅ </span> : <span>🚫</span>}</>,
    },
    {
      title: t('termsDiscountLevelDiscount.nameLabel'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('termsDiscountLevelDiscount.descriptionLabel'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('termsDiscountLevelDiscount.actions'),
      key: 'copyButton',
      width: 1,
      render: (text: string, record: Discount) => (
        <Space>
          <Button
            size="small"
            type="default"
            onClick={() => openAddEditCopyDiscountModal(record, true)}
          >
            {t('button.edit')}
          </Button>
          <Button size="small" type="default" onClick={() => openAddEditCopyDiscountModal(record)}>
            {t('button.copy')}
          </Button>
          <Button size="small" type="primary" danger onClick={() => deleteDiscount(record.id)}>
            {t('button.delete')}
          </Button>
        </Space>
      ),
    },
  ];
};

export default useDiscountLevelDiscountsColumns;
