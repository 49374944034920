import { useState } from 'react';

type ReturnData = {
  visible: boolean;
  open: () => void;
  close: () => void;
  setVisible: (v: boolean) => void;
};

const useModal = (): ReturnData => {
  const [visible, setVisible] = useState(false);
  const open = () => setVisible(true);
  const close = () => setVisible(false);
  return {
    visible,
    open,
    close,
    setVisible: (v) => setVisible(v),
  };
};

export default useModal;
