import { useMutation, useQuery, useQueryClient } from 'react-query';

import { API } from '../utils/request.utils';
import { ApiInterface } from '@/types/Api';
import { QueryKeys } from '@/types/QueryKeys.types';

export function getOrdersSalesQuery(params: ApiInterface.OrdersSalesList.RequestQuery = {}) {
  return useQuery([QueryKeys.ordersSales, params], () => API.api.ordersSalesList(params), {
    keepPreviousData: true,
    select: (data) => data.data,
    refetchInterval: 1000 * 60,
    refetchOnWindowFocus: true,
  });
}
export function markOrderSalesAsCheckedMutation() {
  const queryClient = useQueryClient();
  return useMutation((sku: string) => API.api.ordersSalesMarkAsCheckedPartialUpdate(sku), {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.ordersSales]);
    },
  });
}

export function markOrderSalesIsWatchedMutation() {
  const queryClient = useQueryClient();
  return useMutation(({ id }: { id: number }) => API.api.ordersSalesIsWatchedPartialUpdate(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.ordersSales]);
    },
  });
}
